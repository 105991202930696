import { GET_DEVICE, GET_DEVICE_FAIL, GET_DEVICE_SUCCESS } from "./actionTypes"

export const getDevice = uid => ({
  type: GET_DEVICE,
  payload: { uid },
})

export const getDeviceSuccess = devices => ({
  type: GET_DEVICE_SUCCESS,
  payload: devices,
})

export const getDeviceFail = error => ({
  type: GET_DEVICE_FAIL,
  payload: error,
})
