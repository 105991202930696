import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { NEW_USERS } from "./actionTypes"
import { newUsersSuccess, newUsersFailed } from "./actions"

import { getNewUsers } from "helpers/auth_helper"

// Is user getusers successfull then direct plot user in redux.
function* newUsers() {
  try {
    const response = yield call(
      getNewUsers,
      `${process.env.REACT_APP_USER}/api/v1/users?status=false`
    )
    yield put(newUsersSuccess(response))
  } catch (error) {
    yield put(newUsersFailed(error))
  }
}

export function* watchNewUsers() {
  yield takeEvery(NEW_USERS, newUsers)
}

function* accountSaga() {
  yield all([fork(watchNewUsers)])
}

export default accountSaga
