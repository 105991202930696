import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { DELETE_USER } from "./actionTypes"
import { deleteUserSuccess, deleteUserFailed } from "./actions"

import { deleteUserF } from "helpers/admin_helpers"
import { toast } from "react-toastify"

function* deleteUser({ payload: { user } }) {
  try {
    const response = yield call(
      deleteUserF,
      `${process.env.REACT_APP_USER}/api/v1/users/${user}`
    )
    toast.success("Utilisateur supprimé", {
      position: toast.POSITION.BOTTOM_RIGHT,
    })
    yield put(deleteUserSuccess(response))
  } catch (error) {
    toast.error("Echec de la suppréssion", {
      position: toast.POSITION.BOTTOM_RIGHT,
    })
    yield put(deleteUserFailed(error))
  }
}

export function* watchDeleteUser() {
  yield takeEvery(DELETE_USER, deleteUser)
}

function* accountSaga() {
  yield all([fork(watchDeleteUser)])
}

export default accountSaga
