import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { CREATE_COMPANY_USERS } from "./actionTypes"
import { createCompanySuccess, createCompanyFailed } from "./actions"

import { createCompany } from "helpers/admin_helpers"
import jwt from "jsonwebtoken"

function* createCompanyUsers({ payload: { company } }) {
  try {
    const companyObject = company.company
    const companyValue = companyObject.split(",")

    const token = localStorage.getItem("auth")
    const decoded = jwt.decode(token)

    const response = yield call(
      createCompany,
      `${process.env.REACT_APP_USER}/api/v1/companies`,
      {
        name: company.name,
        autotaskId: company.autotaskId,
        gauge1: company.gauge1,
        gauge2: company.gauge2,
        gauge3: company.gauge3,
        gauge4: company.gauge4,
      }
    )
    yield put(createCompanySuccess(response))
  } catch (error) {
    yield put(createCompanyFailed(error))
  }
}

export function* watchCreateCompanyUsers() {
  yield takeEvery(CREATE_COMPANY_USERS, createCompanyUsers)
}

function* accountSaga() {
  yield all([fork(watchCreateCompanyUsers)])
}

export default accountSaga
