import {
  GET_COMPANIES,
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES_FAILED,
} from "./actionTypes"

export const getCompanies = companies => {
  return {
    type: GET_COMPANIES,
    payload: { companies },
  }
}

export const getCompaniesSuccess = companies => {
  return {
    type: GET_COMPANIES_SUCCESS,
    payload: companies,
  }
}

export const getCompaniesFailed = companies => {
  return {
    type: GET_COMPANIES_FAILED,
    payload: companies,
  }
}
