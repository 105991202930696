import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormFeedback,
  Alert,
  Form,
  Input,
  Label,
} from "reactstrap"

import { withTranslation } from "react-i18next"
import FormActivateUsers from "../../Form/NewUser"
import FormUpdateYouzer from "../../Form/Youzer/Update"
import FormReplaceYouzer from "../../Form/Youzer/Replace"

import { deleteUser } from "../../../store/actions"
import { useDispatch } from "react-redux"

const DataTableModal = props => {
  const {
    isOpen,
    toggle,
    data,
    children,
    styleType,
    companies,
    users,
    history,
  } = props

  const dispatch = useDispatch()

  const removeUser = values => {
    if (confirm("Êtes vous sur de vouloir supprimer l'utilisateur ?")) {
      dispatch(deleteUser(values))
    }
  }

  const selectedModal = children => {
    switch (styleType) {
      case "primary":
        return (
          <Modal
            isOpen={isOpen}
            role="dialog"
            autoFocus={true}
            centered={true}
            className="exampleModal"
            tabIndex="-1"
            toggle={toggle}
          >
            <div className="modal-content">
              <ModalHeader toggle={toggle}>{children}</ModalHeader>
              <ModalBody>
                <p className="mb-2">
                  {props.t("Id")}{" "}
                  <span className="text-primary">{data._id}</span>
                </p>
                <p className="mb-2">
                  {props.t("Nom")}{" "}
                  <span className="text-primary">{data.name}</span>
                </p>
                <p className="mb-2">
                  {props.t("Email")}{" "}
                  <span className="text-primary">{data.email}</span>
                </p>
                <p className="mb-2">
                  {props.t("Role")}{" "}
                  <span className="text-primary">{data.role}</span>
                </p>
                <p className="mb-2">
                  {props.t("Date")}{" "}
                  <span className="text-primary">{data.createdAt}</span>
                </p>

                <div className="mb-2">
                  <FormActivateUsers data={data} companies={companies} />
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  type="button"
                  color="danger"
                  onClick={e => removeUser(data._id)}
                >
                  {props.t("Supprimer")}
                </Button>
                <Button type="button" color="secondary" onClick={toggle}>
                  {props.t("Close")}
                </Button>
              </ModalFooter>
            </div>
          </Modal>
        )
      case "secondary":
        return (
          <Modal
            isOpen={isOpen}
            role="dialog"
            autoFocus={true}
            centered={true}
            className="exampleModal"
            tabIndex="-1"
            toggle={toggle}
          >
            <div className="modal-content">
              <ModalHeader toggle={toggle}>{children}</ModalHeader>
              <ModalBody>
                <p className="mb-2">
                  {data.status === 1 ? (
                    <span className="badge bg-info font-size-10">
                      {props.t("New")}
                    </span>
                  ) : data.status === 29 || data.status === 8 ? (
                    <span className="badge bg-warning font-size-10">
                      {props.t("In progress")}
                    </span>
                  ) : data.status === 5 ? (
                    <span className="badge bg-success font-size-10">
                      {props.t("Done")}
                    </span>
                  ) : data.status === 7 || data.status === 22 ? (
                    <span className="badge bg-danger font-size-10">
                      {props.t("Awaiting Customer Feedback")}
                    </span>
                  ) : data.status === 42 || data.status === 43 ? (
                    <span className="badge bg-danger font-size-10">
                      {props.t("No return")}
                    </span>
                  ) : data.status === 19 || data.status === 31 ? (
                    <span className="badge bg-danger font-size-10">
                      {props.t("Customer Response")}
                    </span>
                  ) : data.status === 10 || data.status === 24 ? (
                    <span className="badge bg-danger font-size-10">
                      {props.t("Assigned")}
                    </span>
                  ) : data.status === 11 || data.status === 25 ? (
                    <span className="badge bg-danger font-size-10">
                      {props.t("Escaladed")}
                    </span>
                  ) : data.status === 26 || data.status === 9 ? (
                    <span className="badge bg-danger font-size-10">
                      {props.t("Wainting Material")}
                    </span>
                  ) : data.status === 13 || data.status === 27 ? (
                    <span className="badge bg-danger font-size-10">
                      {props.t("Waiting Approval")}
                    </span>
                  ) : data.status === 12 || data.status === 28 ? (
                    <span className="badge bg-danger font-size-10">
                      {props.t("Waiting Other Provider")}
                    </span>
                  ) : data.status === 30 || data.status === 20 ? (
                    <span className="badge bg-danger font-size-10">
                      {props.t("Plannified")}
                    </span>
                  ) : (
                    <span></span>
                  )}
                </p>
                <p className="mb-2">
                  {data.status === 1 ? (
                    <span className="badge bg-danger font-size-10">
                      {props.t("Priority 1")}
                    </span>
                  ) : data.status === 2 ? (
                    <span className="badge bg-warning font-size-10">
                      {props.t("Priority 2")}
                    </span>
                  ) : data.status === 3 ? (
                    <span className="badge bg-info font-size-10">
                      {props.t("Priority 3")}
                    </span>
                  ) : data.status === 4 ? (
                    <span className="badge bg-secondary font-size-10">
                      {props.t("Information")}
                    </span>
                  ) : data.status === 5 ? (
                    <span className="badge bg-success font-size-10">
                      {props.t("Plannable")}
                    </span>
                  ) : data.status === 6 ? (
                    <span className="badge bg-danger font-size-10">
                      {props.t("To qualify")}
                    </span>
                  ) : data.status === 7 ? (
                    <span className="badge bg-secondary font-size-10">
                      {props.t("Out of bounds")}
                    </span>
                  ) : data.status === 8 ? (
                    <span className="badge bg-success font-size-10">
                      {props.t("Installation")}
                    </span>
                  ) : (
                    <span></span>
                  )}
                </p>
                <p className="mb-2">
                  {props.t("Title")}{" "}
                  <span className="text-primary">{data.title}</span>
                </p>
                <p className="mb-4">
                  {props.t("Creation date")}{" "}
                  <span className="text-primary">{data.createDate}</span>
                </p>
                <p className="mb-4">
                  {props.t("Description")}{" "}
                  <span
                    className="text-primary"
                    dangerouslySetInnerHTML={{ __html: data.description }}
                  ></span>
                </p>
                {data.resolution ? (
                  <p className="mb-4">
                    {props.t("Resolution")}{" "}
                    <span
                      className="text-primary"
                      dangerouslySetInnerHTML={{ __html: data.resolution }}
                    ></span>
                  </p>
                ) : (
                  <span></span>
                )}
              </ModalBody>
              <ModalFooter>
                <Button type="button" color="secondary" onClick={toggle}>
                  {props.t("Close")}
                </Button>
              </ModalFooter>
            </div>
          </Modal>
        )

      case "update":
        return (
          <Modal
            isOpen={isOpen}
            role="dialog"
            autoFocus={true}
            centered={true}
            className="modal-lg"
            tabIndex="-1"
            toggle={toggle}
          >
            <div className="modal-content">
              <ModalHeader toggle={toggle}>{children}</ModalHeader>
              <ModalBody>
                <div className="mb-2">
                  <FormUpdateYouzer
                    user={data}
                    users={users}
                    history={history}
                  />
                </div>
              </ModalBody>
              <ModalFooter>
                <Button type="button" color="secondary" onClick={toggle}>
                  {props.t("Close")}
                </Button>
              </ModalFooter>
            </div>
          </Modal>
        )
        case "replace":
          return (
            <Modal
              isOpen={isOpen}
              role="dialog"
              autoFocus={true}
              centered={true}
              className="modal-lg"
              tabIndex="-1"
              toggle={toggle}
            >
              <div className="modal-content">
                <ModalHeader toggle={toggle}>{children}</ModalHeader>
                <ModalBody>
                  <div className="mb-2">
                    <FormReplaceYouzer
                      user={data}
                      users={users}
                      history={history}
                    />
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button type="button" color="secondary" onClick={toggle}>
                    {props.t("Close")}
                  </Button>
                </ModalFooter>
              </div>
            </Modal>
          )
      case "allusers":
        return (
          <Modal
            isOpen={isOpen}
            role="dialog"
            autoFocus={true}
            centered={true}
            className="exampleModal"
            tabIndex="-1"
            toggle={toggle}
          >
            <div className="modal-content">
              <ModalHeader toggle={toggle}>{children}</ModalHeader>
              <ModalBody>
                <p className="mb-2">
                  {props.t("Id")}{" "}
                  <span className="text-primary">{data._id}</span>
                </p>
                <p className="mb-2">
                  {props.t("Nom")}{" "}
                  <span className="text-primary">{data.name}</span>
                </p>
                <p className="mb-2">
                  {props.t("Email")}{" "}
                  <span className="text-primary">{data.email}</span>
                </p>
                <p className="mb-2">
                  {props.t("Role")}{" "}
                  <span className="text-primary">{data.role}</span>
                </p>
                <p className="mb-2">
                  {props.t("Date")}{" "}
                  <span className="text-primary">{data.createdAt}</span>
                </p>
              </ModalBody>
              <ModalFooter>
                <Button
                  type="button"
                  color="danger"
                  onClick={e => removeUser(data._id)}
                >
                  {props.t("Supprimer")}
                </Button>
                <Button type="button" color="secondary" onClick={toggle}>
                  {props.t("Close")}
                </Button>
              </ModalFooter>
            </div>
          </Modal>
        )

      case "allusersUpdate":
        return (
          <Modal
            isOpen={isOpen}
            role="dialog"
            autoFocus={true}
            centered={true}
            className="modal-lg"
            tabIndex="-1"
            toggle={toggle}
          >
            <div className="modal-content">
              <ModalHeader toggle={toggle}>{children}</ModalHeader>
              <ModalBody>
              <div className="mb-2">
                  <FormActivateUsers data={data} companies={companies} />
                </div>
              </ModalBody>
              <ModalFooter>
                <Button type="button" color="secondary" onClick={toggle}>
                  {props.t("Close")}
                </Button>
              </ModalFooter>
            </div>
          </Modal>
        )

      default:
        return (
          <Modal
            isOpen={isOpen}
            role="dialog"
            autoFocus={true}
            centered={true}
            className="exampleModal"
            tabIndex="-1"
            toggle={toggle}
          >
            <div className="modal-content">
              <ModalHeader toggle={toggle}>{children}</ModalHeader>
              <ModalBody>
                <p className="mb-2">
                  {props.t("Id")}{" "}
                  <span className="text-primary">{data._id}</span>
                </p>
                <p className="mb-2">
                  {props.t("Nom")}{" "}
                  <span className="text-primary">{data.name}</span>
                </p>
                <p className="mb-2">
                  {props.t("Email")}{" "}
                  <span className="text-primary">{data.email}</span>
                </p>
                <p className="mb-2">
                  {props.t("Role")}{" "}
                  <span className="text-primary">{data.role}</span>
                </p>
                <p className="mb-2">
                  {props.t("Date")}{" "}
                  <span className="text-primary">{data.createdAt}</span>
                </p>
              </ModalBody>
              <ModalFooter>
                <Button type="button" color="secondary" onClick={toggle}>
                  {props.t("Close")}
                </Button>
              </ModalFooter>
            </div>
          </Modal>
        )
    }
  }

  return selectedModal(children)
}

DataTableModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  t: PropTypes.any,
  data: PropTypes.any,
  styleType: PropTypes.any,
  children: PropTypes.any,
  companies: PropTypes.any,
  users: PropTypes.any,
  history: PropTypes.any,
}

export default withTranslation()(DataTableModal)
