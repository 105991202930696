import {
  UPDATE_COMPANY,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAILED,

} from "./actionTypes"

export const updateCompany = (company) => {
  return {
    type: UPDATE_COMPANY,
    payload: { company },
  }
}

export const updateCompanySuccess = company => {
  return {
    type: UPDATE_COMPANY_SUCCESS,
    payload: company,
  }
}

export const updateCompanyFailed = company => {
  return {
    type: UPDATE_COMPANY_FAILED,
    payload: company,
  }
}
