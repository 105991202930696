import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_CONTRACT } from "./actionTypes"
import { getContractSuccess, getContractFail } from "./actions"

//Include Both Helper File with needed methods
import { postDocument } from "helpers/documents_helper"

import jwt from "jsonwebtoken"

function* fetchContract() {
  try {
    const token = localStorage.getItem("auth")
    const decoded = jwt.decode(token)

    const response = yield call(
      postDocument,
      `${process.env.REACT_APP_DOCUMENT}/documents/contract`,
      {
        clientName: "Exemple",
      }
    )
    yield put(getContractSuccess(response))
  } catch (error) {
    yield put(getContractFail(error))
  }
}

function* contractSaga() {
  yield takeEvery(GET_CONTRACT, fetchContract)
}

export default contractSaga
