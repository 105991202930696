import {
  GET_ACRONIS_TENANT,
  GET_ACRONIS_TENANT_FAIL,
  GET_ACRONIS_TENANT_SUCCESS,
} from "./actionTypes"

export const getAcronisTenant = companyName => ({
  type: GET_ACRONIS_TENANT,
  payload: { companyName },
})

export const getAcronisTenantSuccess = acronisTenant => ({
  type: GET_ACRONIS_TENANT_SUCCESS,
  payload: acronisTenant,
})

export const getAcronisTenantFail = error => ({
  type: GET_ACRONIS_TENANT_FAIL,
  payload: error,
})
