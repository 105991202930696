import PropTypes from "prop-types"
import React from "react"

import jwt from "jsonwebtoken"
import { useSelector } from "react-redux"


//redux
//i18n
import { withTranslation } from "react-i18next"

const Stats = props => {
  const { label, inventory } = props

  const token = useSelector(state => state?.Login?.user?.token)
  const decoded = jwt.decode(token)

  let filteredData = inventory?.filter(
    obj => obj["autotaskCompanyName"] === decoded.company
  )

  return (
    <React.Fragment>
      <h4 className="mb-0">
        {eval(`filteredData[0]?.devicesStatus?.${label}`)}
      </h4>
    </React.Fragment>
  )
}

export default withTranslation()(Stats)

Stats.propTypes = {
  t: PropTypes.any,
  label: PropTypes.any,
  inventory: PropTypes.any,
}
