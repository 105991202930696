import { call, put, takeEvery } from "redux-saga/effects"


// Crypto Redux States
import { GET_SITE } from "./actionTypes"
import { getSiteSuccess, getSiteFail } from "./actions"

//Include Both Helper File with needed methods
import { getSite } from "helpers/rmm_helper"

import jwt from "jsonwebtoken"

function* fetchSite() {
  try {
    const nowYear = new Date().getFullYear()

    const token = localStorage.getItem("auth")
    const decoded = jwt.decode(token)

    const response = yield call(
      getSite,
      `${process.env.REACT_APP_OPERATION}/site`
    )
    yield put(getSiteSuccess(response))
  } catch (error) {
    yield put(getSiteFail(error))
  }
}

function* siteSaga() {
  yield takeEvery(GET_SITE, fetchSite)
}

export default siteSaga
