import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"

import {
  Container,
  Spinner,
  CardBody,
  Card,
  CardTitle,
  Row,
  Col,
  NavLink,
  NavItem,
  TabContent,
  TabPane,
} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import DataTable from "components/DataTable"

import classnames from "classnames"

//redux

import { getProject, getProjectInProgress, getProjectDone } from "store/actions"
import { useSelector, useDispatch } from "react-redux"

import DataTableModal from "../../components/DataTable/Modal"

//i18n
import { withTranslation } from "react-i18next"

const Project = props => {
  const [activeTab, setActiveTab] = useState("1")
  const [modal1, setModal1] = useState(false)
  const [data, setData] = useState("")
  const dispatch = useDispatch()

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const { project } = useSelector(state => ({
    project: state.getProject?.project?.projects,
  }))

  const { projectInProgress } = useSelector(state => ({
    projectInProgress: state.getProject?.projectInProgress?.projects,
  }))

  const { projectDone } = useSelector(state => ({
    projectDone: state.getProject?.projectDone?.projects,
  }))

  useEffect(() => {
    dispatch(getProject())
  }, [dispatch])

  useEffect(() => {
    dispatch(getProjectInProgress())
  }, [dispatch])

  useEffect(() => {
    dispatch(getProjectDone())
  }, [dispatch])

  const toggleViewModal = cell => {
    setModal1(!modal1), setData(cell)
  }

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
    },
    {
      dataField: "projectName",
      text: props.t("Name"),
      sort: true,
    },
    {
      dataField: "df1",
      isDummyField: true,
      text: props.t("Status"),
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => {
        if (row.status === 0) {
          return <span className="badge bg-dark font-size-10">Inactive</span>
        } else if (row.status === 1) {
          return <span className="badge bg-info font-size-10">New</span>
        } else if (row.status === 2) {
          return (
            <span className="badge bg-warning font-size-10">In progress</span>
          )
        } else if (row.status === 3) {
          return <span className="badge bg-warning font-size-10">Waiting</span>
        } else if (row.status === 5) {
          return <span className="badge bg-success font-size-10">Done</span>
        } else if (row.status === 7) {
          return (
            <span className="badge bg-danger font-size-10">
              Awaiting Customer Feedback
            </span>
          )
        }

        return (
          <h5>
            <i className="mdi mdi-file font-size-16 align-middle text-secondary me-2"></i>
          </h5>
        )
      },
    },
    {
      dataField: "createDateTime",
      text: props.t("Creation date"),
      sort: true,
    },
  ]

  return (
    <React.Fragment>
      <DataTableModal
        isOpen={modal1}
        data={data}
        styleType="primary"
        toggle={toggleViewModal}
      >
        {props.t("Project")}
      </DataTableModal>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("Project")} | izencia - Espace Client</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title={props.t("Activity")}
            breadcrumbItem={props.t("Project")}
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">{props.t("Project")}</CardTitle>
                  <p className="card-title-desc">
                    {props.t("Project Description")}
                  </p>

                  <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggleTab("1")
                        }}
                      >
                        {props.t("All project")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggleTab("2")
                        }}
                      >
                        {props.t("In progress")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "3",
                        })}
                        onClick={() => {
                          toggleTab("3")
                        }}
                      >
                        {props.t("Done")}
                      </NavLink>
                    </NavItem>
                  </ul>

                  <TabContent activeTab={activeTab} className="p-3">
                    <TabPane tabId="1" id="all">
                      {project ? (
                        <DataTable
                          sizePerPage={10}
                          order="asc"
                          defaultSorted="id"
                          columns={columns}
                          data={project}
                          keyField={"id"}
                        />
                      ) : (
                        <Spinner className="ms-2" color="primary" />
                      )}
                    </TabPane>
                    <TabPane tabId="2" id="progress">
                      {projectInProgress ? (
                        <DataTable
                          sizePerPage={10}
                          order="asc"
                          defaultSorted="id"
                          columns={columns}
                          data={projectInProgress}
                          keyField={"id"}
                        />
                      ) : (
                        <Spinner className="ms-2" color="primary" />
                      )}
                    </TabPane>
                    <TabPane tabId="3" id="done">
                      {projectDone ? (
                        <DataTable
                          sizePerPage={10}
                          order="asc"
                          defaultSorted="id"
                          columns={columns}
                          data={projectDone}
                          keyField={"id"}
                        />
                      ) : (
                        <Spinner className="ms-2" color="primary" />
                      )}
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(Project)

Project.propTypes = {
  history: PropTypes.object,
  t: PropTypes.any,
}
