import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect } from "react"
import { withRouter } from "react-router-dom"

import {
  Container,
  Spinner,
  CardBody,
  Card,
  CardTitle,
  Row,
  Col,
} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import FormAddYouzer from "../../components/Form/Youzer/Add"

//redux
import { getInstances } from "store/actions"
import { useSelector, useDispatch } from "react-redux"

//i18n
import { withTranslation } from "react-i18next"

const In = props => {
  const dispatch = useDispatch()

  const { users } = useSelector(state => ({
    users: state.Instances?.instances?.data,
  }))

  useEffect(() => {
    dispatch(getInstances())
  }, [dispatch])

  /*   const filteredUsers = users?.map(element => {
    return {
      ...element,
      Email: element.CustomAttributes.filter(
        subElement => subElement.CodeAttribut === "Email"
      )[0].Value,
    }
  }) */

  const filteredUsers = users?.filter(instance => {
    var supportAccessField = instance.userDefinedFields.find(
      x => x.name == "Support Access"
    )
    return supportAccessField && supportAccessField.value === "Oui"
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("Add User")} | izencia - Espace Client</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title={props.t("User")}
            breadcrumbItem={props.t("Add User")}
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">{props.t("Add User")}</CardTitle>
                  <p className="card-title-desc">
                    {props.t("Add User Description")}
                  </p>
                  <FormAddYouzer
                    users={filteredUsers}
                    history={props.history}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(withRouter(In))

In.propTypes = {
  history: PropTypes.object,
  users: PropTypes.object,
  t: PropTypes.any,
}
