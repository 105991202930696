import {
  GET_PROJECT_FAIL,
  GET_PROJECT_SUCCESS,
  GET_PROJECT_IN_PROGRESS_SUCCESS,
  GET_PROJECT_IN_PROGRESS_FAIL,
  GET_PROJECT_DONE_SUCCESS,
  GET_PROJECT_DONE_FAIL
} from "./actionTypes"

const INIT_STATE = {
  project: [],
  error: {},
}

const Project = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROJECT_SUCCESS:
      return {
        ...state,
        project: action.payload,
      }

    case GET_PROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

      case GET_PROJECT_IN_PROGRESS_SUCCESS:
        return {
          ...state,
          projectInProgress: action.payload,
        }
  
      case GET_PROJECT_IN_PROGRESS_FAIL:
        return {
          ...state,
          error: action.payload,
        }

        case GET_PROJECT_DONE_SUCCESS:
        return {
          ...state,
          projectDone: action.payload,
        }
  
      case GET_PROJECT_DONE_FAIL:
        return {
          ...state,
          error: action.payload,
        }


    default:
      return state
  }
}

export default Project
