import {
  GET_COMMITTEE,
  GET_COMMITTEE_FAIL,
  GET_COMMITTEE_SUCCESS,
} from "./actionTypes"

export const getCommittee = () => ({
  type: GET_COMMITTEE,
})

export const getCommitteeSuccess = committees => ({
  type: GET_COMMITTEE_SUCCESS,
  payload: committees,
})

export const getCommitteeFail = error => ({
  type: GET_COMMITTEE_FAIL,
  payload: error,
})