/* TICKET */
export const GET_TICKET = "GET_TICKET"
export const GET_TICKET_SUCCESS = "GET_TICKET_SUCCESS"
export const GET_TICKET_FAIL = "GET_TICKET_FAIL"

export const GET_TICKET_IN_PROGRESS = "GET_TICKET_IN_PROGRESS"
export const GET_TICKET_IN_PROGRESS_SUCCESS = "GET_TICKET_IN_PROGRESS_SUCCESS"
export const GET_TICKET_IN_PROGRESS_FAIL = "GET_TICKET_IN_PROGRESS_FAIL"

export const GET_TICKET_DONE = "GET_TICKET_DONE"
export const GET_TICKET_DONE_SUCCESS = "GET_TICKET_DONE_SUCCESS"
export const GET_TICKET_DONE_FAIL = "GET_TICKET_DONE_FAIL"

export const GET_TICKET_DONE_TODAY = "GET_TICKET_DONE_TODAY"
export const GET_TICKET_DONE_TODAY_SUCCESS = "GET_TICKET_DONE_TODAY_SUCCESS"
export const GET_TICKET_DONE_TODAY_FAIL = "GET_TICKET_DONE_TODAY_FAIL"
