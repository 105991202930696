import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

//acronis
import getAcronisInfo from "./acronis/info/reducer"
import getAcronisTenant from "./acronis/tenant/reducer"

//activity
import getProject from "./activity/project/reducer"
import getTicket from "./activity/ticket/reducer"
import getDevice from "./activity/rmm/device/reducer"
import getSite from "./activity/rmm/site/reducer"
import getContact from "./activity/contact/reducer"
import getOneCompany from "./activity/company/reducer"
import getPrimary from "./activity/primary/reducer"

//documents
import getCommittee from "./documents/committee/reducer"
import getContract from "./documents/contract/reducer"
import getInvoice from "./documents/invoice/reducer"
import getOther from "./documents/other/reducer"
import getQuoting from "./documents/quoting/reducer"
import getTraining from "./documents/training/reducer"

// Admin
import newUsers from "./admin/newUsers/reducer"
import updateCompany from "./admin/updateCompany/reducer"
import deleteUser from "./admin/deleteUser/reducer"
import getCompanies from "./admin/getCompany/reducer"

import getCompany from "./admin/company/getCompany/reducer"
import updateCompanyUser from "./admin/company/updateCompanyUser/reducer"
import createCompanyUsers from "./admin/company/createCompany/reducer"
import allUsers from "./admin/allUsers/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import ResetPassword from "./auth/resetpwd/reducer"
import Profile from "./auth/profile/reducer"

// youzer
import Instances from "./youzer/users/reducer"
import addYouzer from "./youzer/add/reducer"
import removeYouzer from "./youzer/remove/reducer"
import updateYouzer from "./youzer/update/reducer"
import replaceYouzer from "./youzer/replace/reducer"

//chat
import chat from "./chat/reducer"

//invoices
import invoices from "./invoices/reducer"

//projects
import projects from "./projects/reducer"

//tasks
import tasks from "./tasks/reducer"

//contacts
import contacts from "./contacts/reducer"

//mails
import mails from "./mails/reducer"

//Dashboard
import Dashboard from "./dashboard/reducer"

//Dasboard saas
import DashboardSaas from "./dashboard-saas/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  newUsers,
  ForgetPassword,
  Profile,
  updateCompany,
  deleteUser,
  chat,
  mails,
  invoices,
  projects,
  tasks,
  contacts,
  Dashboard,
  DashboardSaas,
  getCompanies,
  getProject,
  getTicket,
  getCommittee,
  getContract,
  getInvoice,
  getOther,
  getQuoting,
  getTraining,
  Instances,
  addYouzer,
  removeYouzer,
  updateYouzer,
  getDevice,
  getSite,
  updateCompanyUser,
  getCompany,
  createCompanyUsers,
  getContact,
  ResetPassword,
  allUsers,
  getOneCompany,
  getPrimary,
  replaceYouzer,
  getAcronisInfo,
  getAcronisTenant
})

export default rootReducer
