import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import ResetPwd from "../pages/Authentication/ResetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import Verify from "pages/Authentication/Verify"
import Validate from "pages/Authentication/Validate"
import NewUsers from "pages/Admin/NewUsers"
import Committee from "pages/Administration/Committee"
import Contract from "pages/Administration/Contract"
import Invoice from "pages/Administration/Invoice"
import Quoting from "pages/Administration/Quoting"
import Training from "pages/Administration/Training"
import Other from "pages/Administration/Other"
import Subscribed from "pages/Services/Subscribed"
import Inventory from "pages/Usages/Inventory_grid"
import AllUsers from "pages/User/AllUsers"
import AllUsersAdmin from "pages/Admin/AllUsers"
import In from "pages/User/In"
import Youzer from "pages/User/In_Youzer"

import Out from "pages/User/Out"
import Update from "pages/User/Update"
import Project from "pages/Activity/Project"
import Ticket from "pages/Activity/Ticket"
import Inventory_details from "pages/Usages/Inventory_details"
import Statistics from "pages/Activity/Statistics"
import Replace from "pages/User/Replace"
import Save from "pages/Save"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  { path: "/administration/committee", component: Committee },
  { path: "/administration/contract", component: Contract },
  { path: "/administration/invoice", component: Invoice },
  { path: "/administration/quoting", component: Quoting },
  { path: "/administration/training", component: Training },
  { path: "/administration/others", component: Other },

  { path: "/activity/project", component: Project },
  { path: "/activity/ticket", component: Ticket },
  { path: "/activity/statistics", component: Statistics },

  { path: "/services/subscribed", component: Subscribed },

  { path: "/usages/inventory", component: Inventory },
  {
    path: "/usages/inventory-details/:uid/:type",
    component: Inventory_details,
  },

  { path: "/user/all-users", component: AllUsers },
  { path: "/user/in", component: In },
  { path: "/user/youzer", component: Youzer },
  { path: "/user/replace", component: Replace },
  { path: "/user/out", component: Out },
  { path: "/user/update", component: Update },

  { path: "/profile", component: UserProfile },
  { path: "/save", component: Save },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const adminProtectedRoutes = [
  {
    path: "/new-users",
    component: NewUsers,
  },
  { path: "/all-users", component: AllUsersAdmin },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/verify", component: Verify },
  { path: "/validate", component: Validate },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/reset-password/:resetToken", component: ResetPwd },
  { path: "/register", component: Register },
]

export { publicRoutes, authProtectedRoutes, adminProtectedRoutes }
