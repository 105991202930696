import {
  GET_INVOICE,
  GET_INVOICE_FAIL,
  GET_INVOICE_SUCCESS,
} from "./actionTypes"

export const getInvoice = () => ({
  type: GET_INVOICE,
})

export const getInvoiceSuccess = invoices => ({
  type: GET_INVOICE_SUCCESS,
  payload: invoices,
})

export const getInvoiceFail = error => ({
  type: GET_INVOICE_FAIL,
  payload: error,
})