import PropTypes from "prop-types"
import ReactApexChart from "react-apexcharts"
import React, { useEffect } from "react"
import { Spinner, Card, CardBody, CardTitle } from "reactstrap"

//redux
import { getDevice } from "store/actions"
import { useSelector, useDispatch } from "react-redux"

//i18n
import { withTranslation } from "react-i18next"

import jwt from "jsonwebtoken"

const PieChart = props => {
  const { inventory } = props

  const dispatch = useDispatch()

  const token = useSelector(state => state?.Login?.user?.token)
  const decoded = jwt.decode(token)

  const filteredData = inventory?.filter(
    obj => obj["autotaskCompanyName"] === decoded.company
  )

  useEffect(() => {
    dispatch(getDevice(filteredData[0]?.uid))
  }, [dispatch])

  const devices = useSelector(state => ({
    value: state?.getDevice?.device?.data?.devices,
  }))

  let options
  let resultStats = []

  if (devices?.value && devices?.value !== "undefined") {
    let result = devices?.value?.map(a => a.deviceType?.category)
    result = result.map(v => (v === undefined ? "Autres" : v))

    let unique = [...new Set(result.map(item => item))]
    unique = unique.map(v => (v === undefined ? "Autres" : v))

    const totalItems = result.length

    unique.forEach(currItem => {
      const numItems = result.filter(item => item === currItem)

      resultStats.push((numItems.length * 100) / totalItems)
      //resultStats = $numItems

    })

    options = {
      labels: unique,
      colors: ["#34c38f", "#556ee6", "#f46a6a", "#50a5f1", "#f1b44c"],
      legend: {
        show: true,
        position: "bottom",
        horizontalAlign: "center",
        verticalAlign: "middle",
        floating: false,
        fontSize: "14px",
        offsetX: 0,
        offsetY: -10,
      },
      responsive: [
        {
          breakpoint: 600,
          options: {
            chart: {
              height: 240,
            },
            legend: {
              show: false,
            },
          },
        },
      ],
    }
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">Type de matériel</CardTitle>

          {devices?.value ? (
            <ReactApexChart
              options={options}
              series={resultStats}
              type="pie"
              height="520"
              className="apex-charts"
            />
          ) : (
            <Spinner className="ms-2" color="primary" />
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default withTranslation()(PieChart)

PieChart.propTypes = {
  t: PropTypes.any,
  inventory: PropTypes.any,
}
