import { GET_CONTACT_FAIL, GET_CONTACT_SUCCESS } from "./actionTypes"

const INIT_STATE = {
  contact: [],
  error: {},
}

const Contact = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CONTACT_SUCCESS:
      return {
        ...state,
        contact: action.payload,
      }

    case GET_CONTACT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default Contact
