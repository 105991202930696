import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_ACRONIS_INFO } from "./actionTypes"
import { getAcronisInfoSuccess, getAcronisInfoFail } from "./actions"

//Include Both Helper File with needed methods
import { getTenantInfo } from "helpers/acronis_helper"

import jwt from "jsonwebtoken"

function* fetchAcronisInfo({ payload: { tenantId } }) {
  try {
    const token = localStorage.getItem("auth")
    const decoded = jwt.decode(token)

    const response = yield call(
      getTenantInfo,
      `${process.env.REACT_APP_OPERATION}/acronis/tenant/info/${tenantId}`
    )
    yield put(getAcronisInfoSuccess(response))
  } catch (error) {
    yield put(getAcronisInfoFail(error))
  }
}

function* acronisInfoSaga() {
  yield takeEvery(GET_ACRONIS_INFO, fetchAcronisInfo)
}

export default acronisInfoSaga
