import {
  REPLACE_YOUZER,
  REPLACE_YOUZER_SUCCESS,
  REPLACE_YOUZER_FAILED,
} from "./actionTypes"

export const replaceYouzer = (youzer, history) => {
  return {
    type: REPLACE_YOUZER,
    payload: { youzer, history },
  }
}

export const replaceYouzerSuccess = youzer => {
  return {
    type: REPLACE_YOUZER_SUCCESS,
    payload: youzer,
  }
}

export const replaceYouzerFailed = youzer => {
  return {
    type: REPLACE_YOUZER_FAILED,
    payload: youzer,
  }
}
