import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { REGISTER_MFA_USER, REGISTER_USER } from "./actionTypes"
import {
  registerUserSuccessful,
  registerUserFailed,
  registerMfaUserSuccessful,
  registerMfaUserFailed,
} from "./actions"

import { postRegister, postMfa } from "helpers/auth_helper"
import jwt from "jsonwebtoken"

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user, history } }) {
  try {
    const response = yield call(
      postRegister,
      `${process.env.REACT_APP_USER}/api/v1/auth/register`,
      {
        name: user.username,
        email: user.email,
        password: user.password,
      }
    )
    localStorage.setItem("auth", response.token)
    yield put(registerUserSuccessful(response))
    history.push("/verify")
  } catch (error) {
    yield put(registerUserFailed(error))
  }
}

function* registerMfaUser({ payload: { mfa, history } }) {
  const token = localStorage.getItem("auth")
  const decoded = jwt.decode(token)
  try {
    const response = yield call(
      postMfa,
      `${process.env.REACT_APP_USER}/api/v1/auth/verify`,
      {
        mfaToken: mfa.mfaToken,
        userId: decoded.id,
      }
    )
    yield put(registerMfaUserSuccessful(response))
    history.push("/validate")
  } catch (error) {
    yield put(registerMfaUserFailed(error))
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser)
  yield takeEvery(REGISTER_MFA_USER, registerMfaUser)
}

function* accountSaga() {
  yield all([fork(watchUserRegister)])
}

export default accountSaga
