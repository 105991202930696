import {
  REPLACE_YOUZER,
  REPLACE_YOUZER_SUCCESS,
  REPLACE_YOUZER_FAILED,
} from "./actionTypes"

const initialState = {
  replaceYouzerError: null,
  message: null,
  loading: false,
  youzer: null,
}

const replaceYouzer = (state = initialState, action) => {
  switch (action.type) {
    case REPLACE_YOUZER:
      state = {
        ...state,
        loading: true,
        replaceYouzerError: null,
      }
      break
    case REPLACE_YOUZER_SUCCESS:
      state = {
        ...state,
        loading: false,
        youzer: action.payload,
        replaceYouzerError: null,
      }
      break
    case REPLACE_YOUZER_FAILED:
      state = {
        ...state,
        youzer: null,
        loading: false,
        replaceYouzerError: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default replaceYouzer
