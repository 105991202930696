import { GET_COMPANY_FAIL, GET_COMPANY_SUCCESS } from "./actionTypes"

const INIT_STATE = {
  company: [],
  error: {},
}

const Company = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COMPANY_SUCCESS:
      return {
        ...state,
        company: action.payload,
        error: null,
      }

    case GET_COMPANY_FAIL:
      return {
        ...state,
        company: null,
        error: action.payload,
      }

    default:
      return state
  }
}

export default Company
