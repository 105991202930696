import {
  UPDATE_COMPANY,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAILED,
} from "./actionTypes"

const initialState = {
  updateCompanyError: null,
  message: null,
  loading: false,
  users: null,
}

const updateCompany = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_COMPANY:
      state = {
        ...state,
        loading: true,
        updateCompanyError: null,
      }
      break
    case UPDATE_COMPANY_SUCCESS:
      state = {
        ...state,
        loading: false,
        company: action.payload,
        updateCompanyError: null,
      }
      break
    case UPDATE_COMPANY_FAILED:
      state = {
        ...state,
        company: null,
        loading: false,
        updateCompanyError: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default updateCompany
