import axios from "axios"

const getProject = async url => {
  try {
    const token = localStorage.getItem("auth")

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }

    const response = await axios.get(url, config)
    return response.data
  } catch (err) {
    let message
    if (err.response && err.response.status) {
      switch (err.response.status) {
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team"
          break
        default:
          message = err.response.data.error
          break
      }
    }
    throw message
  }
}

const getTicket = async url => {
  try {
    const token = localStorage.getItem("auth")

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }

    const response = await axios.get(url, config)
    return response.data
  } catch (err) {
    let message
    if (err.response && err.response.status) {
      switch (err.response.status) {
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team"
          break
        default:
          message = err.response.data.error
          break
      }
    }
    throw message
  }
}

const getContact = async url => {
  try {
    const token = localStorage.getItem("auth")

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }

    const response = await axios.get(url, config)
    return response.data
  } catch (err) {
    let message
    if (err.response && err.response.status) {
      switch (err.response.status) {
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team"
          break
        default:
          message = err.response.data.error
          break
      }
    }
    throw message
  }
}

const getOneCompany = async (url, data) => {
  try {
    const token = localStorage.getItem("auth")
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }

    const response = await axios.post(url, data, config)

    return response.data
  } catch (err) {
    let message
    if (err.response && err.response.status) {
      switch (err.response.status) {
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team"
          break
        default:
          message = err.response.data.error
          break
      }
    }
    throw message
  }
}

const getPrimary = async (url, data) => {
  try {
    const token = localStorage.getItem("auth")
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }

    const response = await axios.post(url, data, config)

    return response.data
  } catch (err) {
    let message
    if (err.response && err.response.status) {
      switch (err.response.status) {
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team"
          break
        default:
          message = err.response.data.error
          break
      }
    }
    throw message
  }
}

export { getProject, getTicket, getContact, getOneCompany, getPrimary }
