import {
  ADD_YOUZER,
  ADD_YOUZER_SUCCESS,
  ADD_YOUZER_FAILED,
} from "./actionTypes"

export const addYouzer = (youzer, history) => {
  return {
    type: ADD_YOUZER,
    payload: { youzer, history },
  }
}

export const addYouzerSuccess = youzer => {
  return {
    type: ADD_YOUZER_SUCCESS,
    payload: youzer,
  }
}

export const addYouzerFailed = youzer => {
  return {
    type: ADD_YOUZER_FAILED,
    payload: youzer,
  }
}
