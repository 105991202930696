import React from "react"
import { Container, Row, Col } from "reactstrap"

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={4}>{new Date().getFullYear()} © izencia.</Col>
            <Col md={4}>
              <div className="text-sm-center d-none d-sm-block">
                <a href="mailto:support@izencia.com">support@izencia.com</a> | <a href="tel:+33185330122">01 85 33 01 22</a>
              </div>
            </Col>
            <Col md={4}>
              <div className="text-sm-end d-none d-sm-block">
                Design & Develop by izencia
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
