import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { UPDATE_YOUZER } from "./actionTypes"
import { updateYouzerSuccess, updateYouzerFailed } from "./actions"

import { Youzer } from "helpers/youzer_helper"

import jwt from "jsonwebtoken"

import { toast } from "react-toastify"

function* updateYouzer({ payload: { youzer, history } }) {
  try {
    const token = localStorage.getItem("auth")
    const decoded = jwt.decode(token)

    const response = yield call(
      Youzer,
      `${process.env.REACT_APP_OPERATION}/ticket/update-user`,
      {
        companyName: decoded.company,
        companyID: decoded.companyId,
        description: youzer.description,
        lastName: youzer.lastName,
        firstName: youzer.firstName,
        title: youzer.title,
        phone: youzer.phone,
        cellphone: youzer.cellphone,
        employeeType: youzer.employeeType,
        manager: youzer.manager,
        site: youzer.site,
        computerAssignation: youzer.computerAssignation,
        message: youzer.message,
        support: youzer.support,
        vip: youzer.vip,
        allocations: youzer.allocations,
        creator: `${decoded.username} | ${decoded?.email}`,
      }
    )
    toast.success("Demande enregistré", {
      position: toast.POSITION.BOTTOM_RIGHT,
    })
    yield put(updateYouzerSuccess(response))

  } catch (error) {
    toast.error("Erreur d'enregistrement", {
      position: toast.POSITION.BOTTOM_RIGHT,
    })
    yield put(updateYouzerFailed(error))
  }
}

export function* watchUpdateYouzer() {
  yield takeEvery(UPDATE_YOUZER, updateYouzer)
}

function* accountSaga() {
  yield all([fork(watchUpdateYouzer)])
}

export default accountSaga
