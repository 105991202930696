import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"

import {
  Container,
  Spinner,
  CardBody,
  Card,
  CardTitle,
  Row,
  Col,
  TabContent,
} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import DataTable from "components/DataTable"

//redux
import { getInstances } from "store/actions"
import { useSelector, useDispatch } from "react-redux"

import DataTableModal from "../../components/DataTable/Modal"

//i18n
import { withTranslation } from "react-i18next"

const Update = props => {
  const [modal1, setModal1] = useState(false)
  const [data, setData] = useState("")
  const dispatch = useDispatch()

  const { instances } = useSelector(state => ({
    instances: state.Instances?.instances?.data,
  }))

  useEffect(() => {
    dispatch(getInstances())
  }, [dispatch])


  const filteredUsers = instances?.filter(instance => {
    var supportAccessField = instance.userDefinedFields.find(
      x => x.name == "Support Access"
    )
    return supportAccessField && supportAccessField.value === "Oui"
  })


  /* const filteredUsers = instances?.map(element => {
    return {
      ...element,
      Email: element.CustomAttributes.filter(
        subElement => subElement.CodeAttribut === "Email"
      )[0].Value,
    }
  }) */

  const toggleViewModal = cell => {
    setModal1(!modal1), setData(cell)
  }

  function detailValue(row, cell, rowindex) {
    return (
      <button
        type="button"
        onClick={() => toggleViewModal(cell)}
        target="blank_"
        className="btn btn-primary "
      >
        {props.t("View Details")}
      </button>
    )
  }

  const columns = [
    {
      dataField: "id",
      text: props.t("id"),
      sort: true,
    },
    {
      dataField: "lastName",
      text: props.t("Name"),
      sort: true,
    },
    {
      dataField: "firstName",
      text: props.t("Firstname"),
      sort: true,
    },
    {
      dataField: "emailAddress",
      text: props.t("Email"),
      sort: true,
    },
    {
      isDummyField: true,
      text: props.t("View Details"),
      dataField: "view",
      formatter: detailValue,
    },
  ]

  return (
    <React.Fragment>
      <DataTableModal
        isOpen={modal1}
        data={data}
        users={filteredUsers}
        styleType="update"
        history={props.history}
        toggle={toggleViewModal}
      >
        {props.t("Update User")}
      </DataTableModal>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("Update User")} | izencia - Espace Client</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title={props.t("User")}
            breadcrumbItem={props.t("Update User")}
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">{props.t("Update User")}</CardTitle>
                  <p className="card-title-desc">
                    {props.t("Update User Description")}
                  </p>

                  {filteredUsers ? (
                    <DataTable
                      sizePerPage={10}
                      order="asc"
                      defaultSorted="id"
                      columns={columns}
                      data={filteredUsers}
                      keyField={"IdUtilisateur"}
                    />
                  ) : (
                    <Spinner className="ms-2" color="primary" />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(withRouter(Update))

Update.propTypes = {
  t: PropTypes.any,
  history: PropTypes.any,
}
