import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { REMOVE_YOUZER } from "./actionTypes"
import { removeYouzerSuccess, removeYouzerFailed } from "./actions"

import { Youzer } from "helpers/youzer_helper"

import jwt from "jsonwebtoken"

import { toast } from "react-toastify"

function* removeYouzer({ payload: { youzer, history } }) {
  try {
    const token = localStorage.getItem("auth")
    const decoded = jwt.decode(token)

    const response = yield call(
      Youzer,
      `${process.env.REACT_APP_OPERATION}/ticket/remove-user`,
      {
        companyName: decoded.company,
        companyID: decoded.companyId,
        email: youzer.email,
        save: youzer.save,
        computer: youzer.computer,
        emailaccess: youzer.emailaccess,
        redirectemail: youzer.redirectemail,
        message: youzer.message,
        endDate: youzer.endDate,
        creator: `${decoded.username} | ${decoded?.email}`,
        dataAccess: youzer.dataAccess,
      }
    )
    toast.success("Demande enregistré", {
      position: toast.POSITION.BOTTOM_RIGHT,
    })
    yield put(removeYouzerSuccess(response))
  } catch (error) {
    toast.error("Erreur d'enregistrement", {
      position: toast.POSITION.BOTTOM_RIGHT,
    })
    yield put(removeYouzerFailed(error))
  }
}

export function* watchRemoveYouzer() {
  yield takeEvery(REMOVE_YOUZER, removeYouzer)
}

function* accountSaga() {
  yield all([fork(watchRemoveYouzer)])
}

export default accountSaga
