import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"

import { Row, Col, CardBody, Card, Container, Spinner } from "reactstrap"
import CardStats from "components/Common/CardStats"
import { withRouter, Link } from "react-router-dom"

import { withTranslation } from "react-i18next"
import jwt from "jsonwebtoken"

//redux
import { getAcronisTenant, getAcronisInfo } from "store/actions"
import { useSelector, useDispatch } from "react-redux"

const Save = props => {
  const dispatch = useDispatch()

  const token = localStorage.getItem("auth")
  const decoded = jwt.decode(token)

  const { AcronisTenant } = useSelector(state => ({
    AcronisTenant: state?.getAcronisTenant?.acronisTenant?.data?.items[0],
  }))

  const { AcronisInfo } = useSelector(state => ({
    AcronisInfo: state?.getAcronisInfo?.acronisInfo?.data?.items[0]?.usages,
  }))

  useEffect(() => {
    async function func1() {
      dispatch(getAcronisTenant(decoded?.company))
    }

    func1()
  }, []) // only executes on component mounting phase

  useEffect(() => {
    async function func2() {
      dispatch(getAcronisInfo(AcronisTenant?.id))
    }

    if (AcronisTenant) {
      func2() // only call func2 if the state is not undefined or null
    }
  }, [AcronisTenant]) // this gets executed after updating the state


  let filteredDataM365Seats = AcronisInfo?.filter(
    obj => obj["name"] === "pw_base_m365_seats"
  )

  let filteredDataSharepointSites = AcronisInfo?.filter(
    obj => obj["name"] === "pw_base_m365_sharepoint_sites"
  )

  let filteredDataM365Teams = AcronisInfo?.filter(
    obj => obj["name"] === "pw_base_m365_teams"
  )

  let filteredDataM365SeatsShared = AcronisInfo?.filter(
    obj => obj["name"] === "pw_base_secondary_m365_seats_shared"
  )


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("Save")} | izencia - Espace Client</title>
        </MetaTags>
        <Container fluid>
          <h4>{props.t("Save M365")}</h4>

          <Row>
            <Col md="4">
              {filteredDataM365Seats ? (
                <CardStats
                  data={filteredDataM365Seats[0]}
                  title={props.t("Seats M365")}
                  version="fifth"
                />
              ) : (
                <Spinner className="ms-2" color="primary" />
              )}
            </Col>
            <Col md="4">
              {filteredDataSharepointSites ? (
                <CardStats
                  data={filteredDataSharepointSites[0]}
                  title={props.t("SharePoint Site")}
                  version="fifth"
                />
              ) : (
                <Spinner className="ms-2" color="primary" />
              )}
            </Col>
            <Col md="4">
              {filteredDataM365Teams ? (
                <CardStats
                  data={filteredDataM365Teams[0]}
                  title={props.t("Teams team")}
                  version="fifth"
                />
              ) : (
                <Spinner className="ms-2" color="primary" />
              )}
            </Col>
            <Col md="4">
              {filteredDataM365SeatsShared ? (
                <CardStats
                  data={filteredDataM365SeatsShared[0]}
                  title={props.t("M365 Shared")}
                  version="fifth"
                />
              ) : (
                <Spinner className="ms-2" color="primary" />
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(Save)

Save.propTypes = {
  history: PropTypes.object,
  t: PropTypes.any,
}
