import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

import { withTranslation } from "react-i18next"

import { getContact } from "../../../store/actions"
import { useDispatch, useSelector } from "react-redux"

const DataTableModalTicket = props => {
  const { isOpen, toggle, data, children } = props

  const dispatch = useDispatch()

  /*   useEffect(() => {
    dispatch(getContact(data.contactID))
  }, [dispatch])



/*   const { contact } = useSelector(state => ({
    contact: state.getContact?.contact?.data[0]?.emailAddress,
  }))

 */

  const selectedModal = children => {
    return (
      <Modal
        isOpen={isOpen}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={toggle}
      >
        <div className="modal-content">
          <ModalHeader toggle={toggle}>{children}</ModalHeader>
          <ModalBody>
            <p className="mb-2">
              {data.status === 1 ? (
                <span className="badge bg-info font-size-10">
                  {props.t("New")}
                </span>
              ) : data.status === 29 || data.status === 8 ? (
                <span className="badge bg-warning font-size-10">
                  {props.t("In progress")}
                </span>
              ) : data.status === 5 ? (
                <span className="badge bg-success font-size-10">
                  {props.t("Done")}
                </span>
              ) : data.status === 7 || data.status === 22 ? (
                <span className="badge bg-danger font-size-10">
                  {props.t("Awaiting Customer Feedback")}
                </span>
              ) : data.status === 42 || data.status === 43 ? (
                <span className="badge bg-danger font-size-10">
                  {props.t("No return")}
                </span>
              ) : data.status === 19 || data.status === 31 ? (
                <span className="badge bg-danger font-size-10">
                  {props.t("Customer Response")}
                </span>
              ) : data.status === 10 || data.status === 24 ? (
                <span className="badge bg-danger font-size-10">
                  {props.t("Assigned")}
                </span>
              ) : data.status === 11 || data.status === 25 ? (
                <span className="badge bg-danger font-size-10">
                  {props.t("Escaladed")}
                </span>
              ) : data.status === 26 || data.status === 9 ? (
                <span className="badge bg-danger font-size-10">
                  {props.t("Wainting Material")}
                </span>
              ) : data.status === 13 || data.status === 27 ? (
                <span className="badge bg-danger font-size-10">
                  {props.t("Waiting Approval")}
                </span>
              ) : data.status === 12 || data.status === 28 ? (
                <span className="badge bg-danger font-size-10">
                  {props.t("Waiting Other Provider")}
                </span>
              ) : data.status === 30 || data.status === 20 ? (
                <span className="badge bg-danger font-size-10">
                  {props.t("Plannified")}
                </span>
              ) : (
                <span></span>
              )}
            </p>
            <p className="mb-2">
              {data.status === 1 ? (
                <span className="badge bg-danger font-size-10">
                  {props.t("Priority 1")}
                </span>
              ) : data.status === 2 ? (
                <span className="badge bg-warning font-size-10">
                  {props.t("Priority 2")}
                </span>
              ) : data.status === 3 ? (
                <span className="badge bg-info font-size-10">
                  {props.t("Priority 3")}
                </span>
              ) : data.status === 4 ? (
                <span className="badge bg-secondary font-size-10">
                  {props.t("Information")}
                </span>
              ) : data.status === 5 ? (
                <span className="badge bg-success font-size-10">
                  {props.t("Plannable")}
                </span>
              ) : data.status === 6 ? (
                <span className="badge bg-danger font-size-10">
                  {props.t("To qualify")}
                </span>
              ) : data.status === 7 ? (
                <span className="badge bg-secondary font-size-10">
                  {props.t("Out of bounds")}
                </span>
              ) : data.status === 8 ? (
                <span className="badge bg-success font-size-10">
                  {props.t("Installation")}
                </span>
              ) : (
                <span></span>
              )}
            </p>
            <p className="mb-2">
              {props.t("Title")}{" "}
              <span className="text-primary">{data.title}</span>
            </p>
            <p className="mb-2">
              {props.t("Creation date")}{" "}
              <span className="text-primary">{data.createDate}</span>
            </p>
            <p className="mb-4">
              {props.t("completedDate")}{" "}
              <span className="text-primary">{data.completedDate}</span>
            </p>
            <p className="mb-4">
              {props.t("Description")}{" "}
              <span
                className="text-primary"
                dangerouslySetInnerHTML={{ __html: data.description }}
              ></span>
            </p>
            {data.resolution ? (
              <p className="mb-4">
                {props.t("Resolution")}{" "}
                <span
                  className="text-primary"
                  dangerouslySetInnerHTML={{ __html: data.resolution }}
                ></span>
              </p>
            ) : (
              <span></span>
            )}
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="secondary" onClick={toggle}>
              {props.t("Close")}
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    )
  }

  return selectedModal(children)
}

DataTableModalTicket.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  t: PropTypes.any,
  data: PropTypes.any,
  styleType: PropTypes.any,
  children: PropTypes.any,
  companies: PropTypes.any,
  users: PropTypes.any,
  history: PropTypes.any,
}

export default withTranslation()(DataTableModalTicket)
