import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_PRIMARY } from "./actionTypes"
import { getPrimarySuccess, getPrimaryFail } from "./actions"

//Include Both Helper File with needed methods
import { getPrimary } from "helpers/activity_helper"

import jwt from "jsonwebtoken"

function* fetchPrimary() {
  try {

    const token = localStorage.getItem("auth")
    const decoded = jwt.decode(token)

    const response = yield call(
      getPrimary,
      `${process.env.REACT_APP_OPERATION}/company/primary`,
      {
        id: decoded.companyId,
      }
    )
    yield put(getPrimarySuccess(response))
  } catch (error) {
    yield put(getPrimaryFail(error))
  }
}

function* primarySaga() {
  yield takeEvery(GET_PRIMARY, fetchPrimary)
}

export default primarySaga
