import axios from "axios"

const postLogin = async (url, data) => {
  try {
    const response = await axios.post(url, data, {
      "Access-Control-Allow-Origin": "*",
    })
    return response.data
  } catch (err) {
    let message
    if (err.response && err.response.status) {
      switch (err.response.status) {
        case 401:
          message = "Mauvaise combinaison identifiant / mot de passe"
          break
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team"
          break
        default:
          message = err.response.data.error
          break
      }
    }
    throw message
  }
}

const postForgotPassword = async (url, data) => {
  try {
    const response = await axios.post(url, data, {
      "Access-Control-Allow-Origin": "*",
    })
    return response.data
  } catch (err) {
    let message
    if (err.response && err.response.status) {
      switch (err.response.status) {
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team"
          break
        default:
          message = err.response.data.error
          break
      }
    }
    throw message
  }
}

const postMfa = async (url, data) => {
  try {
    const token = localStorage.getItem("auth")

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }

    const response = await axios.post(url, data, config)
    return response.data
  } catch (err) {
    let message
    if (err.response && err.response.status) {
      switch (err.response.status) {
        case 401:
          message =
            "Sorry! something went wrong, please contact our support team"
          break
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team"
          break
        default:
          message = err.response.data.error
          break
      }
    }
    throw message
  }
}

const getLogout = async url => {
  try {
    const response = await axios.get(url, {
      "Access-Control-Allow-Origin": "*",
    })
    return response.data
  } catch (err) {
    let message
    if (err.response && err.response.status) {
      switch (err.response.status) {
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team"
          break
        default:
          message = err.response.data.error
          break
      }
    }
    throw message
  }
}

const postRegister = async (url, data) => {
  try {
    const response = await axios.post(url, data)
    return response.data
  } catch (err) {
    if (err.response && err.response.status) {
      let message
      switch (err.response.status) {
        case 403:
          message =
            "Cet utilisateur est déjà enregistré. Merci de vous connecter."
          break
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team"
          break
        default:
          message = err.response.data.error
          break
      }
      throw message
    }
  }
}

const putResetPassword = async (url, data) => {
  try {
    const response = await axios.put(url, data)
    return response.data
  } catch (err) {
    if (err.response && err.response.status) {
      let message
      switch (err.response.status) {
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team"
          break
        default:
          message = err.response.data.error
          break
      }
      throw message
    }
  }
}

const getNewUsers = async url => {
  try {
    const token = localStorage.getItem("auth")

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await axios.get(url, config)
    return response.data
  } catch (err) {
    if (err.response && err.response.status) {
      let message
      switch (err.response.status) {
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team"
          break
        default:
          message = err.response.data.error
          break
      }
      throw message
    }
  }
}

export {
  postLogin,
  getLogout,
  postMfa,
  postRegister,
  getNewUsers,
  postForgotPassword,
  putResetPassword,
}
