import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_CONTACT } from "./actionTypes"
import { getContactSuccess, getContactFail } from "./actions"

//Include Both Helper File with needed methods
import { getContact } from "helpers/activity_helper"

function* fetchContact({ payload: { contactID } }) {
  try {
    const response = yield call(
      getContact,
      `${process.env.REACT_APP_OPERATION}/contact/${contactID}`
    )
    yield put(getContactSuccess(response))
  } catch (error) {
    yield put(getContactFail(error))
  }
}

function* contactSaga() {
  yield takeEvery(GET_CONTACT, fetchContact)
}

export default contactSaga
