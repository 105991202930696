import {
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
  REGISTER_MFA_USER,
  REGISTER_MFA_USER_SUCCESSFUL,
  REGISTER_MFA_USER_FAILED,
} from "./actionTypes"

export const registerUser = (user, history) => {
  return {
    type: REGISTER_USER,
    payload: { user, history },
  }
}

export const registerUserSuccessful = user => {
  return {
    type: REGISTER_USER_SUCCESSFUL,
    payload: user,
  }
}

export const registerUserFailed = user => {
  return {
    type: REGISTER_USER_FAILED,
    payload: user,
  }
}

export const registerMfaUser = (mfa, history) => {
  return {
    type: REGISTER_MFA_USER,
    payload: { mfa, history },
  }
}

export const registerMfaUserSuccessful = mfa => {
  return {
    type: REGISTER_MFA_USER_SUCCESSFUL,
    payload: mfa,
  }
}

export const registerMfaUserFailed = mfa => {
  return {
    type: REGISTER_MFA_USER_FAILED,
    payload: mfa,
  }
}
