import {
  GET_TICKET,
  GET_TICKET_FAIL,
  GET_TICKET_SUCCESS,
  GET_TICKET_IN_PROGRESS,
  GET_TICKET_IN_PROGRESS_SUCCESS,
  GET_TICKET_IN_PROGRESS_FAIL,
  GET_TICKET_DONE,
  GET_TICKET_DONE_SUCCESS,
  GET_TICKET_DONE_FAIL,
  GET_TICKET_DONE_TODAY,
  GET_TICKET_DONE_TODAY_SUCCESS,
  GET_TICKET_DONE_TODAY_FAIL,
} from "./actionTypes"

export const getTicket = () => ({
  type: GET_TICKET,
})

export const getTicketSuccess = tickets => ({
  type: GET_TICKET_SUCCESS,
  payload: tickets,
})

export const getTicketFail = error => ({
  type: GET_TICKET_FAIL,
  payload: error,
})

export const getTicketInProgress = () => ({
  type: GET_TICKET_IN_PROGRESS,
})

export const getTicketInProgressSuccess = tickets => ({
  type: GET_TICKET_IN_PROGRESS_SUCCESS,
  payload: tickets,
})

export const getTicketInProgressFail = error => ({
  type: GET_TICKET_IN_PROGRESS_FAIL,
  payload: error,
})

export const getTicketDone = () => ({
  type: GET_TICKET_DONE,
})

export const getTicketDoneSuccess = tickets => ({
  type: GET_TICKET_DONE_SUCCESS,
  payload: tickets,
})

export const getTicketDoneFail = error => ({
  type: GET_TICKET_DONE_FAIL,
  payload: error,
})

export const getTicketDoneToday = () => ({
  type: GET_TICKET_DONE_TODAY,
})

export const getTicketDoneTodaySuccess = tickets => ({
  type: GET_TICKET_DONE_TODAY_SUCCESS,
  payload: tickets,
})

export const getTicketDoneTodayFail = error => ({
  type: GET_TICKET_DONE_TODAY_FAIL,
  payload: error,
})
