import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"

import {
  Container,
  Spinner,
  CardBody,
  Card,
  CardTitle,
  Row,
  Col,
  NavLink,
  NavItem,
  TabContent,
  TabPane,
} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import DataTable from "components/DataTable"

import classnames from "classnames"

import { getInstances } from "store/actions"
import { useSelector, useDispatch } from "react-redux"

//i18n
import { withTranslation } from "react-i18next"

const AllUsers = props => {
  const [activeTab, setActiveTab] = useState("1")
  const dispatch = useDispatch()

  const { instances } = useSelector(state => ({
    instances: state.Instances?.instances?.data,
  }))

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  useEffect(() => {
    dispatch(getInstances())
  }, [dispatch])

  const filteredUsers = instances?.filter(instance => {
    var supportAccessField = instance.userDefinedFields.find(
      x => x.name == "Support Access"
    )
    return supportAccessField && supportAccessField.value === "Oui"
  })

  const filteredContacts = instances?.filter(instance => {
    var supportAccessField = instance.userDefinedFields.find(
      x => x.name == "Support Access"
    )
    return (
      (supportAccessField && supportAccessField.value === "Non") ||
      (supportAccessField && supportAccessField.value === null)
    )
  })

  /* const users = instances?.map(element => {
    return {
      ...element,
      Email: element.CustomAttributes.filter(
        subElement => subElement.CodeAttribut === "Email"
      )[0].Value,
    }
  }) */

  const columns = [
    {
      dataField: "lastName",
      text: props.t("Name"),
      sort: true,
    },
    {
      dataField: "firstName",
      text: props.t("Firstname"),
      sort: true,
    },
    {
      dataField: `emailAddress`,
      text: props.t("Email"),
      sort: true,
    },
    {
      dataField: `phone`,
      text: props.t("Phone"),
      sort: true,
    },
    {
      dataField: `mobilePhone`,
      text: props.t("CellPhone"),
      sort: true,
    },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("All users")} | izencia - Espace Client</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title={props.t("Users")}
            breadcrumbItem={props.t("All users")}
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">{props.t("All users")}</CardTitle>
                  <p className="card-title-desc">
                    {props.t("All users Description")}
                  </p>

                  <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggleTab("1")
                        }}
                      >
                        {props.t("All users")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggleTab("2")
                        }}
                      >
                        {props.t("All contacts")}
                      </NavLink>
                    </NavItem>
                  </ul>

                  <TabContent activeTab={activeTab} className="p-3">
                    <TabPane tabId="1" id="all">
                      <p className="card-title-desc">
                        {props.t("AllUser SubTitle")}
                      </p>
                      {filteredUsers ? (
                        <DataTable
                          sizePerPage={10}
                          order="asc"
                          defaultSorted="id"
                          columns={columns}
                          data={filteredUsers}
                          keyField={"IdUtilisateur"}
                        />
                      ) : (
                        <Spinner className="ms-2" color="primary" />
                      )}
                    </TabPane>
                    <TabPane tabId="2" id="progress">
                      {filteredContacts ? (
                        <DataTable
                          sizePerPage={10}
                          order="asc"
                          defaultSorted="id"
                          columns={columns}
                          data={filteredContacts}
                          keyField={"IdUtilisateur"}
                        />
                      ) : (
                        <Spinner className="ms-2" color="primary" />
                      )}
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

AllUsers.propTypes = {
  t: PropTypes.any,
  allUsers: PropTypes.array,
}

export default withTranslation()(AllUsers)
