import {
  GET_INSTANCES,
  GET_INSTANCES_SUCCESS,
  GET_INSTANCES_FAILED,
} from "./actionTypes"

export const getInstances = instances => {
  return {
    type: GET_INSTANCES,
    payload: { instances },
  }
}

export const getInstancesSuccess = instances => {
  return {
    type: GET_INSTANCES_SUCCESS,
    payload: instances,
  }
}

export const getInstancesFailed = instances => {
  return {
    type: GET_INSTANCES_FAILED,
    payload: instances,
  }
}
