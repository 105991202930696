import PropTypes from "prop-types"
import React from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, CardFooter, Col, Button } from "reactstrap"

const InventoryCard = props => {
  const devicesType = type => {
    let resultStats = []

    let result = props?.data?.value?.map(a => a.deviceType?.category)

    result = result?.map(v => (v === undefined ? "Autres" : v))

    /*     let unique = [...new Set(result.map(item => item))]
    unique = unique.map(v => (v === undefined ? "Autres" : v)) */

    let unique = []

    switch (type) {
      case "Postes":
        unique = ["Desktop", "Laptop"]
        break
      case "Serveurs":
        unique = ["Server", "Main System Chassis"]
        break
      case "Switchs":
        unique = ["Datto Switch"]
        break
      case "Bornes":
        unique = ["Datto Access Point"]
        break
      case "Autres":
        unique = ["Datto Continuity", "Autres", "Router", "Network Device (Router)"]
        break
      default:
        unique = [
          "Desktop",
          "Laptop",
          "Server",
          "Datto Switch",
          "Datto Access Point",
          "Datto Continuity",
          "",
          undefined,
          null,
        ]
    }
    unique.forEach(currItem => {
      const numItems = result?.filter(item => item === currItem)

      resultStats.push(...numItems)
    })

    return resultStats.length
  }

  return (
    <React.Fragment>
      <Col xl="3" sm="6">
        <Card className="text-center">
          <CardBody>
            <h5 className="font-size-15 mb-1">
              <Link to="#" className="text-dark">
                {props?.type}
              </Link>
            </h5>
            <p className="text-muted">{devicesType(props?.type)} appareils</p>
          </CardBody>
          <CardFooter className="bg-transparent border-top">
            <Link
              className="btn btn-primary btn-sm"
              to={`/usages/inventory-details/${props?.uid}/${props?.type}`}
            >
              Voir
            </Link>
          </CardFooter>
        </Card>
      </Col>
    </React.Fragment>
  )
}

InventoryCard.propTypes = {
  inventory: PropTypes.object,
  data: PropTypes.any,
  type: PropTypes.any,
  uid: PropTypes.any,
}

export default InventoryCard
