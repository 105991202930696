import { GET_CONTACT, GET_CONTACT_FAIL, GET_CONTACT_SUCCESS } from "./actionTypes"

export const getContact = contactID => ({
  type: GET_CONTACT,
  payload: { contactID },
})

export const getContactSuccess = devices => ({
  type: GET_CONTACT_SUCCESS,
  payload: devices,
})

export const getContactFail = error => ({
  type: GET_CONTACT_FAIL,
  payload: error,
})
