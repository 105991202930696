import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"

import {
  Container,
  Spinner,
  CardBody,
  Card,
  CardTitle,
  Row,
  Col,
  NavLink,
  NavItem,
  TabContent,
  TabPane,
} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import DataTable from "components/DataTable"

import classnames from "classnames"

//redux
import { getTicket, getTicketInProgress, getTicketDone } from "store/actions"
import { useSelector, useDispatch } from "react-redux"

import { withTranslation } from "react-i18next"

import DataTableModalTicket from "../../components/DataTable/Modal/ticket"

const Ticket = props => {
  const [activeTab, setActiveTab] = useState("1")
  const [modal1, setModal1] = useState(false)
  const [data, setData] = useState("")
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getTicket())
  }, [dispatch])

  useEffect(() => {
    dispatch(getTicketInProgress())
  }, [dispatch])

  useEffect(() => {
    dispatch(getTicketDone())
  }, [dispatch])

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const { ticket } = useSelector(state => ({
    ticket: state.getTicket?.ticket?.tickets,
  }))

  const { ticketInProgress } = useSelector(state => ({
    ticketInProgress: state.getTicket?.ticketInProgress?.tickets,
  }))

  const { ticketDone } = useSelector(state => ({
    ticketDone: state.getTicket?.ticketDone?.tickets,
  }))

  const toggleViewModal = cell => {
    setModal1(!modal1), setData(cell)
  }

  function detailValue(row, cell, rowindex) {
    return (
      <button
        type="button"
        onClick={() => toggleViewModal(cell)}
        target="blank_"
        className="btn btn-primary "
      >
        {props.t("View Details")}
      </button>
    )
  }

  let newArray = ticket?.map(({ createDate, completedDate, ...rest }) => {
    if (createDate !== undefined && createDate !== null) {
      let date = new Date(createDate)
      let stringDate = date.toLocaleString("fr-FR", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      })
      createDate = stringDate
    }
    if (completedDate !== undefined && completedDate !== null) {
      let dateComplete = new Date(completedDate)
      let stringDateComplete = dateComplete.toLocaleString("fr-FR", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      })
      completedDate = stringDateComplete
    }
    return { ...rest, createDate, completedDate }
  })

  let newArrayProgress = ticketInProgress?.map(
    ({ createDate, completedDate, ...rest }) => {
      if (createDate !== undefined && createDate !== null) {
        let date = new Date(createDate)
        let stringDate = date.toLocaleString("fr-FR", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        })
        createDate = stringDate
      }
      if (completedDate !== undefined && completedDate !== null) {
        let dateComplete = new Date(completedDate)
        let stringDateComplete = dateComplete.toLocaleString("fr-FR", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        })
        completedDate = stringDateComplete
      }
      return { ...rest, createDate, completedDate }
    }
  )


  let newArrayDone = ticketDone?.map(
    ({ createDate, completedDate, ...rest }) => {
      if (createDate !== undefined && createDate !== null) {
        let date = new Date(createDate)
        let stringDate = date.toLocaleString("fr-FR", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        })
        createDate = stringDate
      }
      if (completedDate !== undefined && completedDate !== null) {
        let dateComplete = new Date(completedDate)
        let stringDateComplete = dateComplete.toLocaleString("fr-FR", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        })
        completedDate = stringDateComplete
      }
      return { ...rest, createDate, completedDate }
    }
  )

  const columns = [
    {
      dataField: "ticketNumber",
      text: props.t("TicketNumber"),
      sort: true,
    },
    {
      dataField: "title",
      text: props.t("Name"),
      sort: true,
    },

    {
      dataField: "df1",
      isDummyField: true,
      text: props.t("Status"),
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => {
        if (row.status === 1) {
          return (
            <span className="badge bg-info font-size-10">{props.t("New")}</span>
          )
        } else if (row.status === 29 || row.status === 8) {
          return (
            <span className="badge bg-warning font-size-10">
              {props.t("In progress")}
            </span>
          )
        } else if (row.status === 5) {
          return (
            <span className="badge bg-success font-size-10">
              {props.t("Done")}
            </span>
          )
        } else if (row.status === 7 || row.status === 22) {
          return (
            <span className="badge bg-danger font-size-10">
              {props.t("Awaiting Customer Feedback")}
            </span>
          )
        } else if (row.status === 42 || row.status === 43) {
          return (
            <span className="badge bg-danger font-size-10">
              {props.t("No return")}
            </span>
          )
        } else if (row.status === 19 || row.status === 31) {
          return (
            <span className="badge bg-danger font-size-10">
              {props.t("Customer Response")}
            </span>
          )
        } else if (row.status === 10 || row.status === 24) {
          return (
            <span className="badge bg-danger font-size-10">
              {props.t("Assigned")}
            </span>
          )
        } else if (row.status === 25 || row.status === 11) {
          return (
            <span className="badge bg-danger font-size-10">
              {props.t("Escaladed")}
            </span>
          )
        } else if (row.status === 26 || row.status === 9) {
          return (
            <span className="badge bg-danger font-size-10">
              {props.t("Wainting Material")}
            </span>
          )
        } else if (row.status === 13 || row.status === 27) {
          return (
            <span className="badge bg-danger font-size-10">
              {props.t("Waiting Approval")}
            </span>
          )
        } else if (row.status === 12 || row.status === 28) {
          return (
            <span className="badge bg-danger font-size-10">
              {props.t("Waiting Other Provider")}
            </span>
          )
        } else if (row.status === 30 || row.status === 20) {
          return (
            <span className="badge bg-danger font-size-10">
              {props.t("Plannified")}
            </span>
          )
        }

        return (
          <h5>
            <i className="mdi mdi-file font-size-16 align-middle text-secondary me-2"></i>
          </h5>
        )
      },
    },
    {
      dataField: "createDate",
      text: props.t("Creation date"),
      sort: false,
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        if (order === "asc") {
          let removeTimeA = a.split(" ")
          let removeTimeB = b.split(" ")
          let dateSplitA = removeTimeA[0].split("/").reverse().join("/")
          let dateSplitB = removeTimeB[0].split("/").reverse().join("/")
          return Date.parse(dateSplitA) - Date.parse(dateSplitB)
        } else if (order === "desc") {
          let removeTimeA = a.split(" ")
          let removeTimeB = b.split(" ")
          let dateSplitA = removeTimeA[0].split("/").reverse().join("/")
          let dateSplitB = removeTimeB[0].split("/").reverse().join("/")

          return Date.parse(dateSplitB) - Date.parse(dateSplitA)
        }
      },
    },
    {
      dataField: "completedDate",
      text: props.t("completedDate"),
      sort: false,
    },
    {
      isDummyField: true,
      text: props.t("View Details"),
      dataField: "view",
      formatter: detailValue,
    },
  ]

  return (
    <React.Fragment>
      {data ? (
        <DataTableModalTicket
          isOpen={modal1}
          data={data}
          styleType="secondary"
          toggle={toggleViewModal}
        >
          {props.t("Ticket")}
        </DataTableModalTicket>
      ) : (
        <Spinner className="ms-2" color="primary" />
      )}

      <div className="page-content">
        <MetaTags>
          <title>{props.t("Ticket")} | izencia - Espace Client</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title={props.t("Activity")}
            breadcrumbItem={props.t("Ticket")}
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">{props.t("Ticket")}</CardTitle>
                  <p className="card-title-desc">
                    {props.t("Ticket Description")}
                  </p>

                  <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggleTab("1")
                        }}
                      >
                        {props.t("All ticket")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggleTab("2")
                        }}
                      >
                        {props.t("In progress")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "3",
                        })}
                        onClick={() => {
                          toggleTab("3")
                        }}
                      >
                        {props.t("Done")}
                      </NavLink>
                    </NavItem>
                  </ul>

                  <TabContent activeTab={activeTab} className="p-3">
                    <TabPane tabId="1" id="all">
                      <p className="card-title-desc">
                        {props.t("Ticket SubTitle")}
                      </p>
                      {newArray ? (
                        <DataTable
                          sizePerPage={10}
                          order="desc"
                          defaultSorted="createDate"
                          columns={columns}
                          data={newArray}
                          keyField={"id"}
                        />
                      ) : (
                        <Spinner className="ms-2" color="primary" />
                      )}
                    </TabPane>
                    <TabPane tabId="2" id="progress">
                      {newArrayProgress ? (
                        <DataTable
                          sizePerPage={10}
                          defaultSorted="createDate"
                          order="desc"
                          columns={columns}
                          data={newArrayProgress}
                          keyField={"id"}
                        />
                      ) : (
                        <Spinner className="ms-2" color="primary" />
                      )}
                    </TabPane>
                    <TabPane tabId="3" id="done">
                      <p className="card-title-desc">
                        {props.t("Ticket SubTitle")}
                      </p>
                      {newArrayDone ? (
                        <DataTable
                          sizePerPage={10}
                          defaultSorted="createDate"
                          order="desc"
                          columns={columns}
                          data={newArrayDone}
                          keyField={"id"}
                        />
                      ) : (
                        <Spinner className="ms-2" color="primary" />
                      )}
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(Ticket)

Ticket.propTypes = {
  history: PropTypes.object,
  t: PropTypes.any,
}
