import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_ACRONIS_TENANT } from "./actionTypes"
import { getAcronisTenantSuccess, getAcronisTenantFail } from "./actions"

//Include Both Helper File with needed methods
import { getAcronisTenant } from "helpers/acronis_helper"

import jwt from "jsonwebtoken"

function* fetchAcronisTenant({ payload: { companyName } }) {
  try {

    const token = localStorage.getItem("auth")
    const decoded = jwt.decode(token)

    const response = yield call(
      getAcronisTenant,
      `${process.env.REACT_APP_OPERATION}/acronis/tenant/${companyName}`
    )
    yield put(getAcronisTenantSuccess(response))
  } catch (error) {
    yield put(getAcronisTenantFail(error))
  }
}

function* acronisTenantSaga() {
  yield takeEvery(GET_ACRONIS_TENANT, fetchAcronisTenant)
}

export default acronisTenantSaga
