import {
  GET_TRAINING,
  GET_TRAINING_FAIL,
  GET_TRAINING_SUCCESS,
} from "./actionTypes"

export const getTraining = () => ({
  type: GET_TRAINING,
})

export const getTrainingSuccess = trainings => ({
  type: GET_TRAINING_SUCCESS,
  payload: trainings,
})

export const getTrainingFail = error => ({
  type: GET_TRAINING_FAIL,
  payload: error,
})