import { call, put, takeEvery } from "redux-saga/effects"


// Crypto Redux States
import {
  GET_TICKET,
  GET_TICKET_IN_PROGRESS,
  GET_TICKET_DONE,
  GET_TICKET_DONE_TODAY,
} from "./actionTypes"
import {
  getTicketSuccess,
  getTicketFail,
  getTicketInProgressFail,
  getTicketInProgressSuccess,
  getTicketDoneFail,
  getTicketDoneSuccess,
  getTicketDoneTodaySuccess,
  getTicketDoneTodayFail,
} from "./actions"

import { useSelector } from "react-redux"

//Include Both Helper File with needed methods
import { getTicket } from "helpers/activity_helper"

import jwt from "jsonwebtoken"

function* fetchTicket() {
  try {
    const nowYear = new Date().getFullYear()

    const token = localStorage.getItem("auth")
    const decoded = jwt.decode(token)

    const response = yield call(
      getTicket,
      `${process.env.REACT_APP_OPERATION}/ticket/${decoded.companyId}`
    )
    yield put(getTicketSuccess(response))
  } catch (error) {
    yield put(getTicketFail(error))
  }
}

function* fetchTicketInProgress() {
  try {
    const nowYear = new Date().getFullYear()

    const token = localStorage.getItem("auth")
    const decoded = jwt.decode(token)

    const response = yield call(
      getTicket,
      `${process.env.REACT_APP_OPERATION}/ticket/inProgress/${decoded.companyId}`
    )
    yield put(getTicketInProgressSuccess(response))
  } catch (error) {
    yield put(getTicketInProgressFail(error))
  }
}

function* fetchTicketDone() {
  try {
    const nowYear = new Date().getFullYear()

    const token = localStorage.getItem("auth")
    const decoded = jwt.decode(token)

    const response = yield call(
      getTicket,
      `${process.env.REACT_APP_OPERATION}/ticket/done/${decoded.companyId}`
    )
    yield put(getTicketDoneSuccess(response))
  } catch (error) {
    yield put(getTicketDoneFail(error))
  }
}

function* fetchTicketDoneToday() {
  try {
    const token = localStorage.getItem("auth")
    const decoded = jwt.decode(token)

    const response = yield call(
      getTicket,
      `${process.env.REACT_APP_OPERATION}/ticket/done/today/${decoded.companyId}`
    )
    yield put(getTicketDoneTodaySuccess(response))
  } catch (error) {
    yield put(getTicketDoneTodayFail(error))
  }
}

function* ticketSaga() {
  yield takeEvery(GET_TICKET, fetchTicket)
  yield takeEvery(GET_TICKET_IN_PROGRESS, fetchTicketInProgress)
  yield takeEvery(GET_TICKET_DONE, fetchTicketDone)
  yield takeEvery(GET_TICKET_DONE_TODAY, fetchTicketDoneToday)
}

export default ticketSaga
