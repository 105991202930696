import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { UPDATE_COMPANY } from "./actionTypes"
import { updateCompanySuccess, updateCompanyFailed } from "./actions"

import { putCompany } from "helpers/admin_helpers"
import { toast } from "react-toastify"

function* updateCompany({ payload: { company } }) {
  try {
    const companyObject = company.company
    const companyValue = companyObject.split(",")

    const response = yield call(
      putCompany,
      `${process.env.REACT_APP_USER}/api/v1/users/${company._id}`,
      {
        company: companyValue[1],
        companyId: companyValue[0],
        status: true,
      }
    )
    toast.success("Demande enregistré", {
      position: toast.POSITION.BOTTOM_RIGHT,
    })
    yield put(updateCompanySuccess(response))
  } catch (error) {
    toast.error("Erreur d'enregistrement", {
      position: toast.POSITION.BOTTOM_RIGHT,
    })
    yield put(updateCompanyFailed(error))
  }
}

export function* watchUpdateCompany() {
  yield takeEvery(UPDATE_COMPANY, updateCompany)
}

function* accountSaga() {
  yield all([fork(watchUpdateCompany)])
}

export default accountSaga
