import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"

import {
  Spinner,
  Container,
  CardBody,
  Card,
  CardTitle,
  Row,
  Col,
} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import DataTable from "components/DataTable"

//redux
import { allUsers } from "store/actions"
import { useSelector, useDispatch } from "react-redux"
import { getCompanies } from "store/actions"

import DataTableModal from "../../components/DataTable/Modal"

//i18n
import { withTranslation } from "react-i18next"

const AllUsers = props => {
  const [modal1, setModal1] = useState(false)
  const [modal2, setModal2] = useState(false)
  const [data, setdata] = useState("")
  const [data2, setdata2] = useState("")
  const dispatch = useDispatch()

  let { allusers } = useSelector(state => ({
    allusers: state.allUsers?.users?.data,
  }))

  let { companies } = useSelector(state => ({
    companies: state.getCompanies?.companies?.data,
  }))

  companies = companies?.sort((a, b) =>
    a.lastName > b.lastName ? 1 : b.lastName > a.lastName ? -1 : 0
  )

  useEffect(() => {
    dispatch(allUsers())
  }, [dispatch])

  useEffect(() => {
    dispatch(getCompanies())
  }, [dispatch])

  const toggleViewModal = cell => {
    setModal1(!modal1), setdata(cell)
  }
  const toggleViewModal2 = cell => {
    setModal2(!modal2), setdata2(cell)
  }

  allusers = allusers?.sort((a, b) =>
    a.lastName > b.lastName ? 1 : b.lastName > a.lastName ? -1 : 0
  )

  function detailValue(row, cell, rowindex) {
    return (
      <button
        type="button"
        onClick={() => toggleViewModal(cell)}
        target="blank_"
        className="btn btn-primary"
      >
        {props.t("View Details")}
      </button>
    )
  }

  function detailValue2(row, cell, rowindex) {
    return (
      <button
        type="button"
        onClick={() => toggleViewModal2(cell)}
        target="blank_"
        className="btn btn-primary"
      >
        {props.t("Update User")}
      </button>
    )
  }

  const columns = [
    {
      dataField: "name",
      text: props.t("Name"),
      sort: true,
    },
    {
      dataField: "email",
      text: props.t("Email"),
      sort: true,
    },
    {
      dataField: "company",
      text: props.t("Company"),
      sort: true,
    },
    {
      dataField: "mfa_status",
      text: props.t("MFA status"),
      sort: true,
    },
    {
      dataField: "createdAt",
      text: props.t("Creation date"),
      sort: true,
    },
    {
      text: props.t("Role"),
      dataField: "role",
      sort: true,
    },
    {
      isDummyField: true,
      text: props.t("View Details"),
      dataField: "view",
      formatter: detailValue,
    },
    {
      isDummyField: true,
      text: props.t("Update User"),
      dataField: "view2",
      formatter: detailValue2,
    },
  ]

  return (
    <React.Fragment>
      <DataTableModal
        isOpen={modal1}
        data={data}
        styleType="allusers"
        toggle={toggleViewModal}
      >
        {props.t("Users")}
      </DataTableModal>

      <DataTableModal
        isOpen={modal2}
        data={data2}
        styleType="allusersUpdate"
        toggle={toggleViewModal2}
        companies={companies}
      >
        {props.t("Company")}
      </DataTableModal>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("All users")} | izencia - Espace Client</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title={props.t("Administration")}
            breadcrumbItem={props.t("All users")}
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">{props.t("All users")} </CardTitle>
                  <p className="card-title-desc">
                    {props.t("All users Description")}
                  </p>

                  {allusers ? (
                    <DataTable
                      sizePerPage={10}
                      order="asc"
                      defaultSorted="id"
                      columns={columns}
                      data={allusers}
                      keyField={"_id"}
                    />
                  ) : (
                    <Spinner className="ms-2" color="primary" />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(AllUsers)

AllUsers.propTypes = {
  history: PropTypes.object,
  t: PropTypes.any,
}
