import {
  CREATE_COMPANY_USERS,
  CREATE_COMPANY_USERS_SUCCESS,
  CREATE_COMPANY_USERS_FAILED,
} from "./actionTypes"

const initialState = {
  createCompanyUsersError: null,
  message: null,
  loading: false,
  createCompanyUsers: null,
}

const createCompanyUsers = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_COMPANY_USERS:
      state = {
        ...state,
        loading: true,
        createCompanyUsersError: null,
      }
      break
    case CREATE_COMPANY_USERS_SUCCESS:
      state = {
        ...state,
        loading: false,
        createCompanyUsers: action.payload,
        createCompanyUsersError: null,
      }
      break
    case CREATE_COMPANY_USERS_FAILED:
      state = {
        ...state,
        createCompanyUsers: null,
        loading: false,
        createCompanyUsersError: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default createCompanyUsers
