import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { ALL_USERS } from "./actionTypes"
import { allUsersSuccess, allUsersFailed } from "./actions"

import { getNewUsers } from "helpers/auth_helper"

// Is user getusers successfull then direct plot user in redux.
function* allUsers() {
  try {
    const response = yield call(
      getNewUsers,
      `${process.env.REACT_APP_USER}/api/v1/users`
    )
    yield put(allUsersSuccess(response))
  } catch (error) {
    yield put(allUsersFailed(error))
  }
}

export function* watchAllUsers() {
  yield takeEvery(ALL_USERS, allUsers)
}

function* accountSaga() {
  yield all([fork(watchAllUsers)])
}

export default accountSaga
