import {
  REMOVE_YOUZER,
  REMOVE_YOUZER_SUCCESS,
  REMOVE_YOUZER_FAILED,
} from "./actionTypes"

export const removeYouzer = (youzer, history) => {
  return {
    type: REMOVE_YOUZER,
    payload: { youzer, history },
  }
}

export const removeYouzerSuccess = youzer => {
  return {
    type: REMOVE_YOUZER_SUCCESS,
    payload: youzer,
  }
}

export const removeYouzerFailed = youzer => {
  return {
    type: REMOVE_YOUZER_FAILED,
    payload: youzer,
  }
}
