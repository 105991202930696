import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  FormFeedback,
  Alert,
  Form,
  Input,
  Label,
  Spinner,
  Row,
  Col,
  FormGroup,
} from "reactstrap"

// actions
import { updateYouzer } from "../../../../store/actions"

import { useSelector, useDispatch } from "react-redux"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

//i18n
import { withTranslation } from "react-i18next"

const FormActivateUsers = props => {
  let { user, users, history } = props

  users = users?.sort((a, b) =>
    a.lastName > b.lastName ? 1 : b.lastName > a.lastName ? -1 : 0
  )

  const dispatch = useDispatch()
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      lastName: user?.lastName,
      firstName: user?.firstName,
      title: "",
      phone: user?.phone,
      cellphone: user?.mobilePhone,
      employeeType: "",
      manager: "",
      computerAssignation: "",
      message: "",
      support: user?.userDefinedFields?.[3]?.value,
      vip: user?.userDefinedFields?.[5]?.value,
      allocations: "",
    },
    validationSchema: Yup.object({
      lastName: Yup.string().required(props.t("Enter lastName")),
      firstName: Yup.string().required(props.t("Enter firstName")),
      title: Yup.string().required(props.t("Enter function")),
      phone: Yup.string(),
      cellphone: Yup.string(),
      employeeType: Yup.string().required(props.t("Enter employeeType")),
      manager: Yup.string(),
      computerAssignation: Yup.string().required(
        props.t("Enter Computer Assignation")
      ),
      message: Yup.string(),
      support: Yup.string(),
      vip: Yup.string(),
      allocations: Yup.string(),
    }),
    onSubmit: values => {
      dispatch(updateYouzer(values, history))
    },
  })

  const { error } = useSelector(state => ({
    error: state.updateYouzer.error,
  }))

  return user ? (
    <Form
      className="form-horizontal"
      onSubmit={e => {
        e.preventDefault()
        validation.handleSubmit()
        return false
      }}
    >
      {error ? <Alert color="danger">{error}</Alert> : null}

      <Row>
        <Col lg="6">
          <FormGroup className="mb-3">
            <Label className="form-label">{props.t("Name")}</Label>
            <Input
              name="lastName"
              className="form-control"
              placeholder={props.t("Enter lastName placeholder")}
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.lastName || ""}
              invalid={
                validation.touched.lastName && validation.errors.lastName
                  ? true
                  : false
              }
            />
            {validation.touched.lastName && validation.errors.lastName ? (
              <FormFeedback type="invalid">
                {validation.errors.lastName}
              </FormFeedback>
            ) : null}
          </FormGroup>
        </Col>

        <Col lg="6">
          <FormGroup className="mb-3">
            <Label className="form-label">{props.t("Firstname")}</Label>
            <Input
              name="firstName"
              className="form-control"
              placeholder={props.t("Enter firstName placeholder")}
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.firstName || ""}
              invalid={
                validation.touched.firstName && validation.errors.firstName
                  ? true
                  : false
              }
            />
            {validation.touched.firstName && validation.errors.firstName ? (
              <FormFeedback type="invalid">
                {validation.errors.firstName}
              </FormFeedback>
            ) : null}
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col lg="6">
          <FormGroup className="mb-3">
            <Label className="form-label">{props.t("User Title")} *</Label>
            <Input
              name="title"
              className="form-control"
              placeholder={props.t("Enter title placeholder")}
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.title || ""}
              invalid={
                validation.touched.title && validation.errors.title
                  ? true
                  : false
              }
            />
            {validation.touched.title && validation.errors.title ? (
              <FormFeedback type="invalid">
                {validation.errors.title}
              </FormFeedback>
            ) : null}
          </FormGroup>
        </Col>

        <Col lg="6">
          <FormGroup className="mb-3">
            <Label className="form-label">{props.t("Phone")}</Label>
            <Input
              name="phone"
              className="form-control"
              placeholder={props.t("Enter phone placeholder")}
              type="tel"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.phone || ""}
              invalid={
                validation.touched.phone && validation.errors.phone
                  ? true
                  : false
              }
            />
            {validation.touched.phone && validation.errors.phone ? (
              <FormFeedback type="invalid">
                {validation.errors.phone}
              </FormFeedback>
            ) : null}
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col lg="6">
          <FormGroup className="mb-3">
            <Label className="form-label">{props.t("CellPhone")}</Label>
            <Input
              name="cellphone"
              className="form-control"
              placeholder={props.t("Enter cellphone placeholder")}
              type="tel"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.cellphone || ""}
              invalid={
                validation.touched.cellphone && validation.errors.cellphone
                  ? true
                  : false
              }
            />
            {validation.touched.cellphone && validation.errors.cellphone ? (
              <FormFeedback type="invalid">
                {validation.errors.cellphone}
              </FormFeedback>
            ) : null}
          </FormGroup>
        </Col>

        <Col lg="6">
          <FormGroup className="mb-3">
            <Label className="form-label">{props.t("Manager")}</Label>
            <Input
              name="manager"
              className="form-control"
              type="select"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.manager || ""}
              invalid={
                validation.touched.manager && validation.errors.manager
                  ? true
                  : false
              }
            >
              <option key="1-manager"></option>
              {users.map(users => (
                <option
                  value={`${users?.lastName} ${users?.firstName} | ${users?.emailAddress}`}
                  key={`${users?.id}-manager`}
                >
                  {users?.lastName} {users?.firstName} | {users?.emailAddress}
                </option>
              ))}
            </Input>

            {validation.touched.manager && validation.errors.manager ? (
              <FormFeedback type="invalid">
                {validation.errors.manager}
              </FormFeedback>
            ) : null}
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col lg="6">
          <FormGroup className="mb-3">
            <Label className="form-label">{props.t("Type of employee")}</Label>
            <Input
              name="employeeType"
              className="form-control"
              type="select"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.employeeType || ""}
              invalid={
                validation.touched.employeeType &&
                validation.errors.employeeType
                  ? true
                  : false
              }
            >
              <option key="1-employeeType"></option>
              <option key="2-employeeType">{props.t("Volunteer")}</option>
              <option key="3-employeeType">
                {props.t("Company employee")}
              </option>
              <option key="4-employeeType">{props.t("Service account")}</option>
              <option key="5-employeeType">{props.t("Temp worker")}</option>
              <option key="6-employeeType">{props.t("Undefined")}</option>
              <option key="7-employeeType">{props.t("Provider")}</option>
              <option key="8-employeeType">{props.t("Intern")}</option>
            </Input>

            {validation.touched.employeeType &&
            validation.errors.employeeType ? (
              <FormFeedback type="invalid">
                {validation.errors.employeeType}
              </FormFeedback>
            ) : null}
          </FormGroup>
        </Col>

        <Col lg="6">
          <FormGroup className="mb-3">
            <Label className="form-label">
              {props.t("Computer Assignation")} *
            </Label>
            <Input
              name="computerAssignation"
              className="form-control"
              type="select"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.computerAssignation || ""}
              invalid={
                validation.touched.computerAssignation &&
                validation.errors.computerAssignation
                  ? true
                  : false
              }
            >
              <option key="1-computerAssignation"></option>
              <option key="2-computerAssignation">
                {props.t("No change")}
              </option>
              <option key="3-computerAssignation">
                {props.t("New computer")}
              </option>
              <option key="4-computerAssignation">
                {props.t("Existing computer")}
              </option>
            </Input>

            {validation.touched.computerAssignation &&
            validation.errors.computerAssignation ? (
              <FormFeedback type="invalid">
                {validation.errors.computerAssignation}
              </FormFeedback>
            ) : null}
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col lg="12">
          <FormGroup className="mb-3">
            <Label className="form-label">{props.t("Support Access")} *</Label>
            <Input
              name="support"
              className="form-control"
              type="select"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.support || ""}
              invalid={
                validation.touched.support && validation.errors.support
                  ? true
                  : false
              }
            >
              <option key="1-support"></option>
              <option key="2-support">{props.t("Yes")}</option>
              <option key="3-support">{props.t("No")}</option>
            </Input>

            {validation.touched.support && validation.errors.support ? (
              <FormFeedback type="invalid">
                {validation.errors.support}
              </FormFeedback>
            ) : null}
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col lg="6">
          <FormGroup className="mb-3">
            <Label className="form-label">{props.t("VIP")}</Label>
            <Input
              name="vip"
              className="form-control"
              type="select"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.vip || ""}
              invalid={
                validation.touched.vip && validation.errors.vip ? true : false
              }
            >
              <option key="1-vip"></option>
              <option key="2-vip">{props.t("Yes")}</option>
              <option key="3-vip">{props.t("No")}</option>
            </Input>

            {validation.touched.vip && validation.errors.vip ? (
              <FormFeedback type="invalid">
                {validation.errors.vip}
              </FormFeedback>
            ) : null}
          </FormGroup>
        </Col>

        <Col lg="6">
          <FormGroup className="mb-3">
            <Label className="form-label">
              {props.t("Allocation of rights")}
            </Label>
            <Input
              name="allocations"
              className="form-control"
              type="select"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.allocations || ""}
              invalid={
                validation.touched.allocations && validation.errors.allocations
                  ? true
                  : false
              }
            >
              <option key="1-allocations"></option>
              {users.map(users => (
                <option
                  value={`${users?.lastName} ${users?.firstName} | ${users?.emailAddress}`}
                  key={`${users?.id}-allocations`}
                >
                  {users?.lastName} {users?.firstName} | {users?.emailAddress}
                </option>
              ))}
            </Input>

            {validation.touched.allocations && validation.errors.allocations ? (
              <FormFeedback type="invalid">
                {validation.errors.allocations}
              </FormFeedback>
            ) : null}
          </FormGroup>
        </Col>
      </Row>

      <FormGroup className="mb-3">
        <Label className="form-label">{props.t("Remarks")}</Label>
        <Input
          name="message"
          className="form-control"
          placeholder={props.t("Enter remarks placeholder")}
          type="textarea"
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values.message || ""}
          invalid={
            validation.touched.message && validation.errors.message
              ? true
              : false
          }
        />
        {validation.touched.message && validation.errors.message ? (
          <FormFeedback type="invalid">
            {validation.errors.message}
          </FormFeedback>
        ) : null}
      </FormGroup>

      <div className="mt-3 d-grid">
        <button className="btn btn-primary btn-block" type="submit">
          {props.t("Send")}
        </button>
      </div>
    </Form>
  ) : (
    <Spinner className="ms-2" color="primary" />
  )
}

FormActivateUsers.propTypes = {
  user: PropTypes.any,
  users: PropTypes.any,
  t: PropTypes.any,
  history: PropTypes.any,
}

export default withTranslation()(FormActivateUsers)
