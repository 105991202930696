import PropTypes from "prop-types"

import MetaTags from "react-meta-tags"
import React, { useEffect } from "react"
import { NavLink } from "react-router-dom"

import CardStats from "components/Common/CardStats"

import { Row, Col, CardBody, Card, Alert, Container, Spinner } from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"

// Pages Components
import WelcomeComp from "./WelcomeComp"

import { useSelector, useDispatch } from "react-redux"

import {
  getInstances,
  getTicketInProgress,
  getSite,
  getCompany,
  getPrimary,
} from "store/actions"
import { getDevice } from "store/actions"

import Stats from "./Stats"

import jwt from "jsonwebtoken"

//redux
//i18n
import { withTranslation } from "react-i18next"
import PieChart from "./PieChart"
import Notifications from "./notifications"

const Dashboard = props => {
  const dispatch = useDispatch()

  const { instances } = useSelector(state => ({
    instances: state.Instances?.instances?.data,
  }))

  const { inventory } = useSelector(state => ({
    inventory: state?.getSite?.site?.data?.sites,
  }))

  const { myCompany } = useSelector(state => ({
    myCompany: state?.getOneCompany?.company?.data,
  }))

  const { primary } = useSelector(state => ({
    primary: state?.getPrimary?.primary?.data,
  }))

  useEffect(() => {
    dispatch(getInstances())
  }, [dispatch])

  useEffect(() => {
    dispatch(getSite())
  }, [dispatch])

  useEffect(() => {
    dispatch(getCompany())
  }, [dispatch])

  useEffect(() => {
    dispatch(getPrimary())
  }, [dispatch])

  const token = localStorage.getItem("auth")
  const decoded = jwt.decode(token)

  let filteredData = inventory?.filter(
    obj => obj["autotaskCompanyName"] === decoded.company
  )

  /*   const { devices } = useSelector(state => ({
    devices: state?.getDevice?.device?.data?.devices,
  })) */

  /*   useEffect(() => {
    dispatch(getDevice(filteredData[0]?.uid))
  }, [dispatch]) */

  const filteredUsers = instances?.filter(instance => {
    var supportAccessField = instance.userDefinedFields.find(
      x => x.name == "Support Access"
    )
    return supportAccessField && supportAccessField.value === "Oui"
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | izencia - Espace Client</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Dashboard" breadcrumbItem="Dashboard" />

          <Col xl="12">
            <Row>
              <Col xl="5">
                {filteredUsers && filteredData && (
                  <WelcomeComp
                    instances={filteredUsers.length}
                    inventory={filteredData[0]?.devicesStatus?.numberOfDevices}
                  />
                )}
              </Col>

              {myCompany && primary ? (
                <Col xl="7">
                  <Notifications
                    company={myCompany[0]}
                    primary={primary}
                  ></Notifications>
                </Col>
              ) : (
                <Spinner className="ms-2" color="primary" />
              )}
            </Row>
          </Col>

          <Col xl="12">
            <Row>
              <Col md="4">
                <NavLink to="activity/ticket">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1 text-center">
                          <i className="bx bx-file d-block icon-dashboard mt-4 mb-2" />
                          <p className="text-muted fw-medium">
                            {props.t("Tickets")}
                          </p>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </NavLink>
              </Col>

              <Col md="4">
                <NavLink to="activity/statistics">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1 text-center">
                          <i className="bx bx-line-chart d-block icon-dashboard mt-4 mb-2" />
                          <p className="text-muted fw-medium">
                            {props.t("Statistics")}
                          </p>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </NavLink>
              </Col>

              <Col md="4">
                <NavLink to="usages/inventory">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1 text-center">
                          <i className="bx bx-chalkboard d-block icon-dashboard mt-4 mb-2" />
                          <p className="text-muted fw-medium">
                            {props.t("Inventory")}
                          </p>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </NavLink>
              </Col>

              <Col md="4">
                <NavLink to="user/in">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1 text-center">
                          <i className="bx bx-add-to-queue d-block icon-dashboard mt-4 mb-2" />
                          <p className="text-muted fw-medium">
                            {props.t("New users")}
                          </p>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </NavLink>
              </Col>

              <Col md="4">
                <NavLink to="user/update">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1 text-center">
                          <i className="bx bx-reset d-block icon-dashboard mt-4 mb-2" />
                          <p className="text-muted fw-medium">
                            {props.t("Update User")}
                          </p>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </NavLink>
              </Col>

              <Col md="4">
                <NavLink to="user/out">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1 text-center">
                          <i className="bx bx-log-out d-block icon-dashboard mt-4 mb-2" />
                          <p className="text-muted fw-medium">
                            {props.t("Remove User")}
                          </p>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </NavLink>
              </Col>
            </Row>
          </Col>

          {/* <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">Utilisateurs</p>
                          {instances ? (
                            <h4 className="mb-0">{instances?.length}</h4>
                          ) : (
                            <Spinner className="ms-2" color="primary" />
                          )}
                        </div>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i className={"bx bx-user font-size-24"}></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col> */}

          {/* <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                            Tous les appareils
                          </p>
                          {inventory ? (
                            <Stats
                              label={"numberOfDevices"}
                              inventory={inventory}
                            />
                          ) : (
                            <Spinner className="ms-2" color="primary" />
                          )}
                        </div>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i className={"bx bx-devices font-size-24"}></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col> */}

          {/* <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                            Appreils en ligne
                          </p>

                          {inventory ? (
                            <Stats
                              label={"numberOfOnlineDevices"}
                              inventory={inventory}
                            />
                          ) : (
                            <Spinner className="ms-2" color="primary" />
                          )}
                        </div>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i className={"bx bx-trending-up font-size-24"}></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col> */}

          {/* <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                            Appreils hors ligne
                          </p>

                          {inventory ? (
                            <Stats
                              label={"numberOfOfflineDevices"}
                              inventory={inventory}
                            />
                          ) : (
                            <Spinner className="ms-2" color="primary" />
                          )}
                        </div>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i
                              className={"bx bx-trending-down font-size-24"}
                            ></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col> */}

          {/* <Col md="4">
                  {ticketInProgress ? (
                    <CardStats
                      data={ticketInProgress}
                      title="Tickets en cours"
                      version="tertiary"
                    />
                  ) : (
                    <Spinner className="ms-2" color="primary" />
                  )}
                </Col> */}

          {/*  <Col xl="12">
              {inventory ? (
                <PieChart inventory={inventory} />
              ) : (
                <Spinner className="ms-2" color="primary" />
              )}
            </Col> */}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(Dashboard)

Dashboard.propTypes = {
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
  history: PropTypes.object,
  t: PropTypes.any,
}
