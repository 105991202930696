import {
  GET_COMMITTEE_FAIL,
  GET_COMMITTEE_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  committee: [],
  error: {},
}

const Committee = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COMMITTEE_SUCCESS:
      return {
        ...state,
        committee: action.payload,
      }

    case GET_COMMITTEE_FAIL:
      return {
        ...state,
        error: action.payload,
      }


    default:
      return state
  }
}

export default Committee
