import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_DEVICE } from "./actionTypes"
import { getDeviceSuccess, getDeviceFail } from "./actions"

//Include Both Helper File with needed methods
import { getDevice } from "helpers/rmm_helper"

import jwt from "jsonwebtoken"

function* fetchDevice({ payload: { uid } }) {
  try {

    const nowYear = new Date().getFullYear()

    const token = localStorage.getItem("auth")
    const decoded = jwt.decode(token)

    const response = yield call(
      getDevice,
      `${process.env.REACT_APP_OPERATION}/device/${uid}`
    )
    yield put(getDeviceSuccess(response))
  } catch (error) {
    yield put(getDeviceFail(error))
  }
}

function* deviceSaga() {
  yield takeEvery(GET_DEVICE, fetchDevice)
}

export default deviceSaga
