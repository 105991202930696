import {
  UPDATE_YOUZER,
  UPDATE_YOUZER_SUCCESS,
  UPDATE_YOUZER_FAILED,
} from "./actionTypes"

export const updateYouzer = (youzer, history) => {
  return {
    type: UPDATE_YOUZER,
    payload: { youzer, history },
  }
}

export const updateYouzerSuccess = youzer => {
  return {
    type: UPDATE_YOUZER_SUCCESS,
    payload: youzer,
  }
}

export const updateYouzerFailed = youzer => {
  return {
    type: UPDATE_YOUZER_FAILED,
    payload: youzer,
  }
}
