import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import jwt from "jsonwebtoken"

/* import { map } from "lodash"
 */

import { Link } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import { Row, Spinner, Container } from "reactstrap"

//Import Card
import InventoryCard from "./Inventory_card"

//i18n
import { withTranslation } from "react-i18next"
import { getSite, getDevice } from "store/actions"

const InventoryGrid = props => {
  const dispatch = useDispatch()

  const { inventory } = useSelector(state => ({
    inventory: state?.getSite?.site?.data?.sites,
  }))

  useEffect(() => {
    dispatch(getSite())
  }, [dispatch])

  const token = useSelector(state => state?.Login?.user?.token)
  const decoded = jwt.decode(token)

  let filteredData = inventory?.filter(
    obj => obj["autotaskCompanyName"] === decoded.company
  )

  useEffect(() => {
    if (filteredData) {
      dispatch(getDevice(filteredData[0]?.uid))
    }
  }, [dispatch])

  const devices = useSelector(state => ({
    value: state?.getDevice?.device?.data?.devices,
  }))

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("Inventory")} | izencia - Espace Client</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title={props.t("Usages")}
            breadcrumbItem={props.t("Inventory")}
          />

          <Row>
            {devices.value !== undefined ? (
              <InventoryCard
                data={devices}
                type="Postes"
                uid={filteredData[0]?.uid}
              />
            ) : (
              <Spinner className="ms-2" color="primary" />
            )}
            {devices.value !== undefined ? (
              <InventoryCard
                data={devices}
                type="Serveurs"
                uid={filteredData[0]?.uid}
              />
            ) : (
              <Spinner className="ms-2" color="primary" />
            )}
            {devices.value !== undefined ? (
              <InventoryCard
                data={devices}
                type="Switchs"
                uid={filteredData[0]?.uid}
              />
            ) : (
              <Spinner className="ms-2" color="primary" />
            )}
            {devices.value !== undefined ? (
              <InventoryCard
                data={devices}
                type="Bornes"
                uid={filteredData[0]?.uid}
              />
            ) : (
              <Spinner className="ms-2" color="primary" />
            )}
            {devices.value !== undefined ? (
              <InventoryCard
                data={devices}
                type="Autres"
                uid={filteredData[0]?.uid}
              />
            ) : (
              <Spinner className="ms-2" color="primary" />
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(InventoryGrid)

InventoryGrid.propTypes = {
  history: PropTypes.object,
  t: PropTypes.any,
}
