import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { RESET_PASSWORD } from "./actionTypes"
import { userResetPasswordSuccess, userResetPasswordError } from "./actions"

//Include Both Helper File with needed methods
import { putResetPassword } from "helpers/auth_helper"

import { toast } from "react-toastify"

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* userResetPassword({ payload: { user, history, resetToken } }) {
  try {
    const response = yield call(
      putResetPassword,
      `${process.env.REACT_APP_USER}/api/v1/auth/resetpassword/${resetToken}`,
      {
        password: user.password,
      }
    )
    toast.success("Mot de passe modifié", {
      position: toast.POSITION.BOTTOM_RIGHT,
    })
    yield put(userResetPasswordSuccess(response))
    history.push("/login")
  } catch (error) {
    toast.error("Erreur de modification", {
      position: toast.POSITION.BOTTOM_RIGHT,
    })
    yield put(userResetPasswordError(error))
  }
}

export function* watchUserPasswordReset() {
  yield takeEvery(RESET_PASSWORD, userResetPassword)
}

function* resetPasswordSaga() {
  yield all([fork(watchUserPasswordReset)])
}

export default resetPasswordSaga
