import PropTypes from "prop-types"
import React from "react"

import { CardBody, Card, Spinner } from "reactstrap"

import { withTranslation } from "react-i18next"

const CardStats = props => {
  const { data, title, version } = props

  switch (version) {
    case "primary":
      return (
        <Card className="mini-stats-wid">
          <CardBody>
            <div className="d-flex">
              <div className="flex-grow-1">
                <p className="text-muted fw-medium">{title}</p>
                {data ? (
                  <h2 className="mb-0 ">{data?.length}</h2>
                ) : (
                  <Spinner className="ms-2" color="primary" />
                )}
              </div>
              <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                <span className="avatar-title rounded-circle bg-primary">
                  <i className={"bx bx-paperclip font-size-24"}></i>
                </span>
              </div>
            </div>
          </CardBody>
        </Card>
      )

    case "secondary":
      return (
        <Card className="mini-stats-wid">
          <CardBody>
            <div className="d-flex">
              <div className="flex-grow-1">
                <p className="text-muted fw-medium">{title}</p>
                {data ? (
                  <h2 className="mb-0 ">{data?.length}</h2>
                ) : (
                  <Spinner className="ms-2" color="primary" />
                )}
              </div>
              <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                <span className="avatar-title rounded-circle bg-primary">
                  <i className={"bx bx-book-open font-size-24"}></i>
                </span>
              </div>
            </div>
          </CardBody>
        </Card>
      )

    case "tertiary":
      return (
        <Card className="mini-stats-wid">
          <CardBody>
            <div className="d-flex">
              <div className="flex-grow-1">
                <p className="text-muted fw-medium">{title}</p>
                {data ? (
                  <h4 className="mb-0 ">{data?.length}</h4>
                ) : (
                  <Spinner className="ms-2" color="primary" />
                )}
              </div>
              <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                <span className="avatar-title rounded-circle bg-primary">
                  <i className={"bx bx-paperclip font-size-24"}></i>
                </span>
              </div>
            </div>
          </CardBody>
        </Card>
      )

    case "fourth":
      return (
        <Card className="mini-stats-wid">
          <CardBody>
            <div className="d-flex">
              <div className="flex-grow-1">
                <p className="text-muted fw-medium">{title}</p>
                {data ? (
                  <h2 className="mb-0 ">{data?.length}</h2>
                ) : (
                  <Spinner className="ms-2" color="primary" />
                )}
              </div>
              <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                <span className="avatar-title rounded-circle bg-primary">
                  <i className={"bx bx-lock font-size-24"}></i>
                </span>
              </div>
            </div>
          </CardBody>
        </Card>
      )

      case "fifth":
      return (
        <Card className="mini-stats-wid">
          <CardBody>
            <div className="d-flex">
              <div className="flex-grow-1">
                <p className="text-muted fw-medium">{title}</p>
                {data ? (
                  <h2 className="mb-0 ">{data?.value}</h2>
                ) : (
                  <Spinner className="ms-2" color="primary" />
                )}
              </div>
              <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                <span className="avatar-title rounded-circle bg-primary">
                  <i className={"bx bx-paperclip font-size-24"}></i>
                </span>
              </div>
            </div>
          </CardBody>
        </Card>
      )

    default:
      return (
        <Card className="mini-stats-wid">
          <CardBody>
            <div className="d-flex">
              <div className="flex-grow-1">
                <p className="text-muted fw-medium">{title}</p>
                {data ? (
                  <h2 className="mb-0 ">{data?.length}</h2>
                ) : (
                  <Spinner className="ms-2" color="primary" />
                )}
              </div>
              <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                <span className="avatar-title rounded-circle bg-primary">
                  <i className={"bx bx-user font-size-24"}></i>
                </span>
              </div>
            </div>
          </CardBody>
        </Card>
      )
  }
}

export default withTranslation()(CardStats)

CardStats.propTypes = {
  history: PropTypes.object,
  t: PropTypes.any,
  data: PropTypes.any,
  title: PropTypes.any,
  version: PropTypes.string,
}
