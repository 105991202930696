import {
  NEW_USERS,
  NEW_USERS_SUCCESS,
  NEW_USERS_FAILED,

} from "./actionTypes"

export const newUsers = (users, history) => {
  return {
    type: NEW_USERS,
    payload: { users, history },
  }
}

export const newUsersSuccess = users => {
  return {
    type: NEW_USERS_SUCCESS,
    payload: users,
  }
}

export const newUsersFailed = users => {
  return {
    type: NEW_USERS_FAILED,
    payload: users,
  }
}
