import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  FormFeedback,
  Alert,
  Form,
  Input,
  Label,
  Spinner,
  FormGroup,
  Col,
  Row,
} from "reactstrap"

// actions
import { replaceYouzer } from "../../../../store/actions"

import { useSelector, useDispatch } from "react-redux"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

//i18n
import { withTranslation } from "react-i18next"

const FormActivateUsers = props => {
  let { users, history } = props

  users = users?.sort((a, b) =>
    a.lastName > b.lastName ? 1 : b.lastName > a.lastName ? -1 : 0
  )

  const dispatch = useDispatch()

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      emailaccess: "",
      redirectemail: "",
      save: "",
      computer: "",
      message: "",
      endDate: "",
      dataAccess: false,

      startDateNew: "",
      endDateNew: "",
      lastNameNew: "",
      firstNameNew: "",
      titleNew: "",
      phoneNew: "",
      cellphoneNew: "",
      employeeTypeNew: "",
      managerNew: "",
      computerAssignationNew: "",
      messageNew: "",
      supportNew: "",
      emailNew: "",
      vipNew: "",
      allocationsNew: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required(props.t("Enter email")),
      emailaccess: Yup.string(),
      redirectemail: Yup.string(),
      save: Yup.string().required(props.t("Choose save")),
      computer: Yup.string().required(props.t("Choose computer")),
      message: Yup.string(),
      endDate: Yup.date().required(props.t("Enter endDate")),
      dataAccess: Yup.boolean(),

      startDateNew: Yup.date().required(props.t("Enter startDate")),
      endDateNew: Yup.date(),
      lastNameNew: Yup.string().required(props.t("Enter lastName")),
      firstNameNew: Yup.string().required(props.t("Enter firstName")),
      titleNew: Yup.string(),
      phoneNew: Yup.string(),
      cellphoneNew: Yup.string(),
      employeeTypeNew: Yup.string().required(props.t("Enter employeeType")),
      managerNew: Yup.string(),
      computerAssignationNew: Yup.string().required(
        props.t("Enter Computer Assignation")
      ),
      messageNew: Yup.string(),
      supportNew: Yup.string(),
      emailNew: Yup.string(),
      vipNew: Yup.string(),
      allocationsNew: Yup.string(),
    }),
    onSubmit: values => {
      dispatch(replaceYouzer(values, history))
    },
  })

  const { error } = useSelector(state => ({
    error: state.replaceYouzer.error,
  }))

  return users ? (
    <Form
      className="form-horizontal"
      onSubmit={e => {
        e.preventDefault()
        validation.handleSubmit()
        return false
      }}
    >
      {error ? <Alert color="danger">{error}</Alert> : null}

      <p className="card-title-desc h4">{props.t("Old User")}</p>
      <Row>
        <Col lg="6">
          <FormGroup className="mb-3">
            <Label className="form-label">{props.t("Username")} *</Label>
            <Input
              name="email"
              className="form-control"
              type="select"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.email || ""}
              invalid={
                validation.touched.email && validation.errors.email
                  ? true
                  : false
              }
            >
              <option key="1-email"></option>
              {users.map(users => (
                <option
                  value={`${users?.lastName} ${users?.firstName} | ${users?.emailAddress}`}
                  key={`${users?.id}-email`}
                >
                  {users?.lastName} {users?.firstName} | {users?.emailAddress}
                </option>
              ))}
            </Input>

            {validation.touched.email && validation.errors.email ? (
              <FormFeedback type="invalid">
                {validation.errors.email}
              </FormFeedback>
            ) : null}
          </FormGroup>
        </Col>

        <Col lg="6">
          <FormGroup className="mb-3">
            <Label className="form-label">{props.t("End date")} *</Label>
            <Input
              name="endDate"
              className="form-control"
              type="date"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.endDate || ""}
              invalid={
                validation.touched.endDate && validation.errors.endDate
                  ? true
                  : false
              }
            />
            {validation.touched.endDate && validation.errors.endDate ? (
              <FormFeedback type="invalid">
                {validation.errors.endDate}
              </FormFeedback>
            ) : null}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup className="mb-3">
            <Label className="form-label">{props.t("Save")} *</Label>
            <Input
              name="save"
              className="form-control"
              type="select"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.save || ""}
              invalid={
                validation.touched.save && validation.errors.save ? true : false
              }
            >
              <option key="1-save"></option>
              <option key="2-save">{props.t("Deletion")}</option>
              <option key="3-save">{props.t("Archiving")}</option>
              <option key="4-save">{props.t("Idk")}</option>
            </Input>

            {validation.touched.save && validation.errors.save ? (
              <FormFeedback type="invalid">
                {validation.errors.save}
              </FormFeedback>
            ) : null}
          </FormGroup>
        </Col>

        <Col lg="6">
          <FormGroup className="mb-3">
            <Label className="form-label">{props.t("Computer")} *</Label>
            <Input
              name="computer"
              className="form-control"
              type="select"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.computer || ""}
              invalid={
                validation.touched.computer && validation.errors.computer
                  ? true
                  : false
              }
            >
              <option key="1-computer"></option>
              <option key="2-computer">{props.t("Park exit")}</option>
              <option key="3-computer">{props.t("Reassignment")}</option>
              <option key="4-computer">{props.t("Idk")}</option>
            </Input>

            {validation.touched.computer && validation.errors.computer ? (
              <FormFeedback type="invalid">
                {validation.errors.computer}
              </FormFeedback>
            ) : null}
          </FormGroup>
        </Col>
      </Row>

      <FormGroup className="mb-3">
        <div className="form-check">
          <Input
            name="dataAccess"
            className="form-check-Input"
            type="checkbox"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.dataAccess || ""}
            invalid={
              validation.touched.dataAccess && validation.errors.dataAccess
                ? true
                : false
            }
          />
          <Label
            className="form-check-Label custom-label"
            htmlFor="formrow-customCheck"
          >
            {props.t("dataAccess")} |{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://email1.blob.core.windows.net/espace-client/Attestation d'autorisation d'accès aux données à caractère non personnelles d'uin salarié lors de son départ.pdf"
            >
              {props.t("Link to the document")}
            </a>
          </Label>
          {validation.touched.dataAccess && validation.errors.dataAccess ? (
            <FormFeedback type="invalid">
              {validation.errors.dataAccess}
            </FormFeedback>
          ) : null}
        </div>
      </FormGroup>

      <FormGroup className="mb-3">
        <Label className="form-label">{props.t("Email Access")}</Label>
        <Input
          name="emailaccess"
          className="form-control"
          type="select"
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values.emailaccess || ""}
          invalid={
            validation.touched.emailaccess && validation.errors.emailaccess
              ? true
              : false
          }
        >
          <option key="1-emailaccess"></option>
          {users.map(users => (
            <option
              value={`${users?.lastName} ${users?.firstName} | ${users?.emailAddress}`}
              key={`${users?.id}-emailaccess`}
            >
              {users?.lastName} {users?.firstName} | {users?.emailAddress}
            </option>
          ))}
        </Input>

        {validation.touched.emailaccess && validation.errors.emailaccess ? (
          <FormFeedback type="invalid">
            {validation.errors.emailaccess}
          </FormFeedback>
        ) : null}
      </FormGroup>

      <FormGroup className="mb-3">
        <Label className="form-label">{props.t("Redirect Email")}</Label>
        <Input
          name="redirectemail"
          className="form-control"
          type="select"
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values.redirectemail || ""}
          invalid={
            validation.touched.redirectemail && validation.errors.redirectemail
              ? true
              : false
          }
        >
          <option key="1-redirectemail"></option>
          {users.map(users => (
            <option
              value={`${users?.lastName} ${users?.firstName} | ${users?.emailAddress}`}
              key={`${users?.id}-redirectemail`}
            >
              {users?.lastName} {users?.firstName} | {users?.emailAddress}
            </option>
          ))}
        </Input>

        {validation.touched.redirectemail && validation.errors.redirectemail ? (
          <FormFeedback type="invalid">
            {validation.errors.redirectemail}
          </FormFeedback>
        ) : null}
      </FormGroup>

      <FormGroup className="mb-3">
        <Label className="form-label">{props.t("Remarks")}</Label>
        <Input
          name="message"
          className="form-control"
          placeholder={props.t("Enter remarks placeholder")}
          type="textarea"
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values.message || ""}
          invalid={
            validation.touched.message && validation.errors.message
              ? true
              : false
          }
        />
        {validation.touched.message && validation.errors.message ? (
          <FormFeedback type="invalid">
            {validation.errors.message}
          </FormFeedback>
        ) : null}
      </FormGroup>

      <hr className="mt-8 mb-8"></hr>

      <p className="card-title-desc h4">{props.t("New User")}</p>

      <Row>
        <Col lg="6">
          <FormGroup className="mb-3">
            <Label className="form-label">{props.t("Start date")} *</Label>
            <Input
              name="startDateNew"
              className="form-control"
              type="date"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.startDateNew || ""}
              invalid={
                validation.touched.startDateNew &&
                validation.errors.startDateNew
                  ? true
                  : false
              }
            />
            {validation.touched.startDateNew &&
            validation.errors.startDateNew ? (
              <FormFeedback type="invalid">
                {validation.errors.startDateNew}
              </FormFeedback>
            ) : null}
          </FormGroup>
        </Col>

        <Col lg="6">
          <FormGroup className="mb-3">
            <Label className="form-label">{props.t("End date if known")}</Label>
            <Input
              name="endDateNew"
              className="form-control"
              type="date"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.endDateNew || ""}
              invalid={
                validation.touched.endDateNew && validation.errors.endDateNew
                  ? true
                  : false
              }
            />
            {validation.touched.endDateNew && validation.errors.endDateNew ? (
              <FormFeedback type="invalid">
                {validation.errors.endDateNew}
              </FormFeedback>
            ) : null}
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col lg="6">
          <FormGroup className="mb-3">
            <Label className="form-label">{props.t("Name")} *</Label>
            <Input
              name="lastNameNew"
              className="form-control"
              placeholder={props.t("Enter lastName placeholder")}
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.lastNameNew || ""}
              invalid={
                validation.touched.lastNameNew && validation.errors.lastNameNew
                  ? true
                  : false
              }
            />
            {validation.touched.lastNameNew && validation.errors.lastNameNew ? (
              <FormFeedback type="invalid">
                {validation.errors.lastNameNew}
              </FormFeedback>
            ) : null}
          </FormGroup>
        </Col>

        <Col lg="6">
          <FormGroup className="mb-3">
            <Label className="form-label">{props.t("Firstname")} *</Label>
            <Input
              name="firstNameNew"
              className="form-control"
              placeholder={props.t("Enter firstName placeholder")}
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.firstNameNew || ""}
              invalid={
                validation.touched.firstNameNew &&
                validation.errors.firstNameNew
                  ? true
                  : false
              }
            />
            {validation.touched.firstNameNew &&
            validation.errors.firstNameNew ? (
              <FormFeedback type="invalid">
                {validation.errors.firstNameNew}
              </FormFeedback>
            ) : null}
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col lg="6">
          <FormGroup className="mb-3">
            <Label className="form-label">{props.t("User Title")}</Label>
            <Input
              name="titleNew"
              className="form-control"
              placeholder={props.t("Enter title placeholder")}
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.titleNew || ""}
              invalid={
                validation.touched.titleNew && validation.errors.titleNew
                  ? true
                  : false
              }
            />
            {validation.touched.titleNew && validation.errors.titleNew ? (
              <FormFeedback type="invalid">
                {validation.errors.titleNew}
              </FormFeedback>
            ) : null}
          </FormGroup>
        </Col>

        <Col lg="6">
          <FormGroup className="mb-3">
            <Label className="form-label">{props.t("Phone")}</Label>
            <Input
              name="phoneNew"
              className="form-control"
              placeholder={props.t("Enter phone placeholder")}
              type="tel"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.phoneNew || ""}
              invalid={
                validation.touched.phoneNew && validation.errors.phoneNew
                  ? true
                  : false
              }
            />
            {validation.touched.phoneNew && validation.errors.phoneNew ? (
              <FormFeedback type="invalid">
                {validation.errors.phoneNew}
              </FormFeedback>
            ) : null}
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col lg="6">
          <FormGroup className="mb-3">
            <Label className="form-label">{props.t("CellPhone")}</Label>
            <Input
              name="cellphoneNew"
              className="form-control"
              placeholder={props.t("Enter cellphone placeholder")}
              type="tel"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.cellphoneNew || ""}
              invalid={
                validation.touched.cellphoneNew &&
                validation.errors.cellphoneNew
                  ? true
                  : false
              }
            />
            {validation.touched.cellphoneNew &&
            validation.errors.cellphoneNew ? (
              <FormFeedback type="invalid">
                {validation.errors.cellphoneNew}
              </FormFeedback>
            ) : null}
          </FormGroup>
        </Col>

        <Col lg="6">
          <FormGroup className="mb-3">
            <Label className="form-label">{props.t("Manager")}</Label>
            <Input
              name="managerNew"
              className="form-control"
              type="select"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.managerNew || ""}
              invalid={
                validation.touched.managerNew && validation.errors.managerNew
                  ? true
                  : false
              }
            >
              <option key="1-Manager"></option>
              {users.map(users => (
                <option
                  value={`${users?.lastName} ${users?.firstName} | ${users?.emailAddress}`}
                  key={`${users?.id}-Manager`}
                >
                  {users?.lastName} {users?.firstName} | {users?.emailAddress}
                </option>
              ))}
            </Input>

            {validation.touched.managerNew && validation.errors.managerNew ? (
              <FormFeedback type="invalid">
                {validation.errors.managerNew}
              </FormFeedback>
            ) : null}
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col lg="6">
          <FormGroup className="mb-3">
            <Label className="form-label">
              {props.t("Type of employee")} *
            </Label>
            <Input
              name="employeeTypeNew"
              className="form-control"
              type="select"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.employeeTypeNew || ""}
              invalid={
                validation.touched.employeeTypeNew &&
                validation.errors.employeeTypeNew
                  ? true
                  : false
              }
            >
              <option key="1-employeeType"></option>
              <option key="2-employeeType">{props.t("Volunteer")}</option>
              <option key="3-employeeType">
                {props.t("Company employee")}
              </option>
              <option key="4-employeeType">{props.t("Service account")}</option>
              <option key="5-employeeType">{props.t("Temp worker")}</option>
              <option key="6-employeeType">{props.t("Undefined")}</option>
              <option key="7-employeeType">{props.t("Provider")}</option>
              <option key="8-employeeType">{props.t("Intern")}</option>
            </Input>

            {validation.touched.employeeTypeNew &&
            validation.errors.employeeTypeNew ? (
              <FormFeedback type="invalid">
                {validation.errors.employeeTypeNew}
              </FormFeedback>
            ) : null}
          </FormGroup>
        </Col>

        <Col lg="6">
          <FormGroup className="mb-3">
            <Label className="form-label">
              {props.t("Computer Assignation")} *
            </Label>
            <Input
              name="computerAssignationNew"
              className="form-control"
              type="select"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.computerAssignationNew || ""}
              invalid={
                validation.touched.computerAssignationNew &&
                validation.errors.computerAssignationNew
                  ? true
                  : false
              }
            >
              <option key="1-computerAssignation"></option>
              <option key="2-computerAssignation">
                {props.t("No assignation")}
              </option>
              <option key="3-computerAssignation">
                {props.t("New computer")}
              </option>
              <option key="4-computerAssignation">
                {props.t("Existing computer")}
              </option>
            </Input>

            {validation.touched.computerAssignationNew &&
            validation.errors.computerAssignationNew ? (
              <FormFeedback type="invalid">
                {validation.errors.computerAssignationNew}
              </FormFeedback>
            ) : null}
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col lg="6">
          <FormGroup className="mb-3">
            <Label className="form-label">{props.t("Support Access")} *</Label>
            <Input
              name="supportNew"
              className="form-control"
              type="select"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.supportNew || ""}
              invalid={
                validation.touched.supportNew && validation.errors.supportNew
                  ? true
                  : false
              }
            >
              <option key="1-support"></option>
              <option key="2-support">{props.t("Yes")}</option>
              <option key="3-support">{props.t("No")}</option>
            </Input>

            {validation.touched.supportNew && validation.errors.supportNew ? (
              <FormFeedback type="invalid">
                {validation.errors.supportNew}
              </FormFeedback>
            ) : null}
          </FormGroup>
        </Col>

        <Col lg="6">
          <FormGroup className="mb-3">
            <Label className="form-label">
              {props.t("Microsoft 365 Account")} *
            </Label>
            <Input
              name="emailNew"
              className="form-control"
              type="select"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.emailNew || ""}
              invalid={
                validation.touched.emailNew && validation.errors.emailNew
                  ? true
                  : false
              }
            >
              <option key="1-email"></option>
              <option key="2-email">{props.t("Yes")}</option>
              <option key="3-email">{props.t("No")}</option>
            </Input>

            {validation.touched.emailNew && validation.errors.emailNew ? (
              <FormFeedback type="invalid">
                {validation.errors.emailNew}
              </FormFeedback>
            ) : null}
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col lg="6">
          <FormGroup className="mb-3">
            <Label className="form-label">{props.t("VIP")}</Label>
            <Input
              name="vipNew"
              className="form-control"
              type="select"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.vip || ""}
              invalid={
                validation.touched.vipNew && validation.errors.vipNew
                  ? true
                  : false
              }
            >
              <option key="1-vip"></option>
              <option key="2-vip">{props.t("Yes")}</option>
              <option key="3-vip">{props.t("No")}</option>
            </Input>

            {validation.touched.vipNew && validation.errors.vipNew ? (
              <FormFeedback type="invalid">
                {validation.errors.vipNew}
              </FormFeedback>
            ) : null}
          </FormGroup>
        </Col>

        <Col lg="6">
          <FormGroup className="mb-3">
            <Label className="form-label">
              {props.t("Allocation of rights")}
            </Label>
            <Input
              name="allocationsNew"
              className="form-control"
              type="select"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.allocationsNew || ""}
              invalid={
                validation.touched.allocationsNew &&
                validation.errors.allocationsNew
                  ? true
                  : false
              }
            >
              <option key="1-allocations"></option>
              {users.map(users => (
                <option
                  value={`${users?.lastName} ${users?.firstName} | ${users?.emailAddress}`}
                  key={`${users?.id}-allocations`}
                >
                  {users?.lastName} {users?.firstName} | {users?.emailAddress}
                </option>
              ))}
            </Input>

            {validation.touched.allocationsNew &&
            validation.errors.allocationsNew ? (
              <FormFeedback type="invalid">
                {validation.errors.allocationsNew}
              </FormFeedback>
            ) : null}
          </FormGroup>
        </Col>
      </Row>

      <FormGroup className="mb-3">
        <Label className="form-label">{props.t("Remarks")}</Label>
        <Input
          name="messageNew"
          className="form-control"
          placeholder={props.t("Enter remarks placeholder")}
          type="textarea"
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values.messageNew || ""}
          invalid={
            validation.touched.messageNew && validation.errors.messageNew
              ? true
              : false
          }
        />
        {validation.touched.messageNew && validation.errors.messageNew ? (
          <FormFeedback type="invalid">
            {validation.errors.messageNew}
          </FormFeedback>
        ) : null}
      </FormGroup>

      <div className="mt-3 d-grid">
        <button className="btn btn-danger btn-block" type="submit">
          {props.t("Replace")}
        </button>
      </div>
    </Form>
  ) : (
    <Spinner className="ms-2" color="primary" />
  )
}

FormActivateUsers.propTypes = {
  users: PropTypes.any,
  t: PropTypes.any,
  history: PropTypes.any,
}

export default withTranslation()(FormActivateUsers)
