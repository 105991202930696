import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_PROJECT,
  GET_PROJECT_IN_PROGRESS,
  GET_PROJECT_DONE,
} from "./actionTypes"
import {
  getProjectSuccess,
  getProjectFail,
  getProjectInProgressFail,
  getProjectInProgressSuccess,
  getProjectDoneFail,
  getProjectDoneSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import { getProject } from "helpers/activity_helper"

import jwt from "jsonwebtoken"

function* fetchProject() {
  try {
    const nowYear = new Date().getFullYear()

    const token = localStorage.getItem("auth")
    const decoded = jwt.decode(token)

    const response = yield call(
      getProject,
      `${process.env.REACT_APP_OPERATION}/project/${decoded.companyId}?date=2021`
    )
    yield put(getProjectSuccess(response))
  } catch (error) {
    yield put(getProjectFail(error))
  }
}

function* fetchProjectInProgress() {
  try {
    const nowYear = new Date().getFullYear()

    const token = localStorage.getItem("auth")
    const decoded = jwt.decode(token)

    const response = yield call(
      getProject,
      `${process.env.REACT_APP_OPERATION}/project/inProgress/${decoded.companyId}?date=2021`
    )
    yield put(getProjectInProgressSuccess(response))
  } catch (error) {
    yield put(getProjectInProgressFail(error))
  }
}

function* fetchProjectDone() {
  try {
    const nowYear = new Date().getFullYear()

    const token = localStorage.getItem("auth")
    const decoded = jwt.decode(token)

    const response = yield call(
      getProject,
      `${process.env.REACT_APP_OPERATION}/project/done/${decoded.companyId}?date=2021`
    )
    yield put(getProjectDoneSuccess(response))
  } catch (error) {
    yield put(getProjectDoneFail(error))
  }
}

function* projectSaga() {
  yield takeEvery(GET_PROJECT, fetchProject)
  yield takeEvery(GET_PROJECT_IN_PROGRESS, fetchProjectInProgress)
  yield takeEvery(GET_PROJECT_DONE, fetchProjectDone)
}

export default projectSaga
