import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_INVOICE } from "./actionTypes"
import { getInvoiceSuccess, getInvoiceFail } from "./actions"

//Include Both Helper File with needed methods
import { postDocument } from "helpers/documents_helper"

import jwt from "jsonwebtoken"

function* fetchInvoice() {
  try {
    const token = localStorage.getItem("auth")
    const decoded = jwt.decode(token)

    const response = yield call(
      postDocument,
      `${process.env.REACT_APP_DOCUMENT}/documents/invoice`,
      {
        clientName: "Exemple",
      }
    )
    yield put(getInvoiceSuccess(response))
  } catch (error) {
    yield put(getInvoiceFail(error))
  }
}

function* invoiceSaga() {
  yield takeEvery(GET_INVOICE, fetchInvoice)
}

export default invoiceSaga
