import { GET_COMPANY, GET_COMPANY_FAIL,GET_COMPANY_SUCCESS } from "./actionTypes"

export const getCompany = () => ({
  type: GET_COMPANY,
})

export const getCompanySuccess = company => ({
  type: GET_COMPANY_SUCCESS,
  payload: company,
})

export const getCompanyFail = error => ({
  type: GET_COMPANY_FAIL,
  payload: error,
})
