import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_TRAINING } from "./actionTypes"
import { getTrainingSuccess, getTrainingFail } from "./actions"

//Include Both Helper File with needed methods
import { postDocument } from "helpers/documents_helper"

import jwt from "jsonwebtoken"

function* fetchTraining() {
  try {
    const token = localStorage.getItem("auth")
    const decoded = jwt.decode(token)

    const response = yield call(
      postDocument,
      `${process.env.REACT_APP_DOCUMENT}/documents/training`,
      {
        clientName: "Exemple",
      }
    )
    yield put(getTrainingSuccess(response))
  } catch (error) {
    yield put(getTrainingFail(error))
  }
}

function* trainingSaga() {
  yield takeEvery(GET_TRAINING, fetchTraining)
}

export default trainingSaga
