import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { REPLACE_YOUZER } from "./actionTypes"
import { replaceYouzerSuccess, replaceYouzerFailed } from "./actions"

import { Youzer } from "helpers/youzer_helper"

import jwt from "jsonwebtoken"

import { toast } from "react-toastify"

function* replaceYouzer({ payload: { youzer, history } }) {
  try {
    const token = localStorage.getItem("auth")
    const decoded = jwt.decode(token)

    const response = yield call(
      Youzer,
      `${process.env.REACT_APP_OPERATION}/ticket/replace-user`,
      {
        companyName: decoded.company,
        companyID: decoded.companyId,

        email: youzer.email,
        save: youzer.save,
        computer: youzer.computer,
        emailaccess: youzer.emailaccess,
        redirectemail: youzer.redirectemail,
        message: youzer.message,
        endDate: youzer.endDate,
        creator: `${decoded.username} | ${decoded?.email}`,
        dataAccess: youzer.dataAccess,

        startDateNew: youzer.startDate,
        endDateNew: youzer.endDate,
        lastNameNew: youzer.lastName,
        firstNameNew: youzer.firstName,
        titleNew: youzer.title,
        phoneNew: youzer.phone,
        cellphoneNew: youzer.cellphone,
        employeeTypeNew: youzer.employeeType,
        managerNew: youzer.manager,
        siteNew: youzer.site,
        computerAssignationNew: youzer.computerAssignation,
        messageNew: youzer.message,
        supportNew: youzer.support,
        emailNew: youzer.email,
        vipNew: youzer.vip,
        allocationsNew: youzer.allocations,
      }
    )
    toast.success("Demande enregistré", {
      position: toast.POSITION.BOTTOM_RIGHT,
    })
    yield put(replaceYouzerSuccess(response))
  } catch (error) {
    toast.error("Erreur d'enregistrement", {
      position: toast.POSITION.BOTTOM_RIGHT,
    })
    yield put(replaceYouzerFailed(error))
  }
}

export function* watchReplaceYouzer() {
  yield takeEvery(REPLACE_YOUZER, replaceYouzer)
}

function* accountSaga() {
  yield all([fork(watchReplaceYouzer)])
}

export default accountSaga
