import React from "react"
import { Col, Card, CardBody, Row } from "reactstrap"

const Notifications = (company, primary) => {
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <h4 className="card-title mb-4">Vos informations</h4>
          <ul className="list-group">
            <Row>
              <Col xl="4">
                <li className="list-group-item border-0">
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <h5 className="font-size-14">Primary contact</h5>
                      <p className="text-muted">
                        {primary[0]
                          ? `${primary[0]?.firstName} ${primary[0]?.lastName}`
                          : "Pas de primary contact défini"}
                      </p>
                    </div>
                  </div>
                </li>
              </Col>
              <Col xl="4">
                <li className="list-group-item border-0">
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <h5 className="font-size-14">Adresse de facturation</h5>
                      <p className="text-muted">
                        {company?.company?.billingAddress1}
                      </p>
                    </div>
                  </div>
                </li>
              </Col>
              <Col xl="4">
                <li className="list-group-item border-0">
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <h5 className="font-size-14">Ville</h5>
                      <p className="text-muted">
                        {company?.company?.billToCity}
                      </p>
                    </div>
                  </div>
                </li>
              </Col>
              <Col xl="4">
                <li className="list-group-item border-0">
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <h5 className="font-size-14">Departement</h5>
                      <p className="text-muted">
                        {company?.company?.billToState}
                      </p>
                    </div>
                  </div>
                </li>
              </Col>
              <Col xl="4">
                <li className="list-group-item border-0">
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <h5 className="font-size-14">Code postal</h5>
                      <p className="text-muted">
                        {company?.company?.billToZipCode}
                      </p>
                    </div>
                  </div>
                </li>
              </Col>
              {company?.company?.billToCountryID == 77 && (
                <Col xl="4">
                  <li className="list-group-item border-0">
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <h5 className="font-size-14">Pays</h5>
                        <p className="text-muted">France</p>
                      </div>
                    </div>
                  </li>
                </Col>
              )}
            </Row>
          </ul>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default Notifications
