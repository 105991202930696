import {
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILED,
} from "./actionTypes"

export const deleteUser = user => {
  return {
    type: DELETE_USER,
    payload: { user },
  }
}

export const deleteUserSuccess = user => {
  return {
    type: DELETE_USER_SUCCESS,
    payload: user,
  }
}

export const deleteUserFailed = user => {
  return {
    type: DELETE_USER_FAILED,
    payload: user,
  }
}
