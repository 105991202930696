import {
  GET_CONTRACT,
  GET_CONTRACT_FAIL,
  GET_CONTRACT_SUCCESS,
} from "./actionTypes"

export const getContract = () => ({
  type: GET_CONTRACT,
})

export const getContractSuccess = contracts => ({
  type: GET_CONTRACT_SUCCESS,
  payload: contracts,
})

export const getContractFail = error => ({
  type: GET_CONTRACT_FAIL,
  payload: error,
})