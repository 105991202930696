import {
  GET_TICKET_FAIL,
  GET_TICKET_SUCCESS,
  GET_TICKET_IN_PROGRESS_SUCCESS,
  GET_TICKET_IN_PROGRESS_FAIL,
  GET_TICKET_DONE_SUCCESS,
  GET_TICKET_DONE_FAIL,
  GET_TICKET_DONE_TODAY_SUCCESS,
  GET_TICKET_DONE_TODAY_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  ticket: [],
  error: {},
}

const Ticket = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TICKET_SUCCESS:
      return {
        ...state,
        ticket: action.payload,
      }

    case GET_TICKET_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_TICKET_IN_PROGRESS_SUCCESS:
      return {
        ...state,
        ticketInProgress: action.payload,
      }

    case GET_TICKET_IN_PROGRESS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_TICKET_DONE_SUCCESS:
      return {
        ...state,
        ticketDone: action.payload,
      }

    case GET_TICKET_DONE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_TICKET_DONE_TODAY_SUCCESS:
      return {
        ...state,
        ticketDoneToday: action.payload,
      }

    case GET_TICKET_DONE_TODAY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default Ticket
