import axios from "axios"

const getSite = async url => {
  try {
    const token = localStorage.getItem("auth")

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }

    const response = await axios.get(url, config)
    return response.data
  } catch (err) {
    let message
    if (err.response && err.response.status) {
      switch (err.response.status) {
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team"
          break
        default:
          message = err.response.data.error
          break
      }
    }
    throw message
  }
}

const getDevice = async url => {
  try {
    const token = localStorage.getItem("auth")

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }

    const response = await axios.get(url, config)
    return response.data
  } catch (err) {
    let message
    if (err.response && err.response.status) {
      switch (err.response.status) {
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team"
          break
        default:
          message = err.response.data.error
          break
      }
    }
    throw message
  }
}

export { getSite, getDevice }
