import { GET_ACRONIS_INFO, GET_ACRONIS_INFO_FAIL,GET_ACRONIS_INFO_SUCCESS } from "./actionTypes"

export const getAcronisInfo = tenantId => ({
  type: GET_ACRONIS_INFO,
  payload: { tenantId },
})

export const getAcronisInfoSuccess = acronisInfo => ({
  type: GET_ACRONIS_INFO_SUCCESS,
  payload: acronisInfo,
})

export const getAcronisInfoFail = error => ({
  type: GET_ACRONIS_INFO_FAIL,
  payload: error,
})


