import {
  ALL_USERS,
  ALL_USERS_SUCCESS,
  ALL_USERS_FAILED,
} from "./actionTypes"

const initialState = {
  allUsersError: null,
  message: null,
  loading: false,
  users: null,
}

const allUsers = (state = initialState, action) => {
  switch (action.type) {
    case ALL_USERS:
      state = {
        ...state,
        loading: true,
        allUsersError: null,
      }
      break
    case ALL_USERS_SUCCESS:
      state = {
        ...state,
        loading: false,
        users: action.payload,
        allUsersError: null,
      }
      break
    case ALL_USERS_FAILED:
      state = {
        ...state,
        users: null,
        loading: false,
        allUsersError: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default allUsers
