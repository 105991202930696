import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Row, Col, Collapse } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import classname from "classnames"
import jwt from "jsonwebtoken"

//i18n
import { withTranslation } from "react-i18next"

import { connect, useSelector } from "react-redux"

const Navbar = props => {
  const [users, setusers] = useState(false)
  const [activity, setactivity] = useState(false)
  const [administration, setadministration] = useState(false)
  const [services, setservices] = useState(false)
  const [park, setpark] = useState(false)
  const [admin, setadmin] = useState(false)
  const [save, setsave] = useState(false)

  const token = useSelector(state => state?.Login?.user?.token)

  const decoded = jwt.decode(token)

  useEffect(() => {
    var matchingMenuItem = null
    var ul = document.getElementById("navigation")
    var items = ul.getElementsByTagName("a")
    for (var i = 0; i < items.length; ++i) {
      if (props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  })
  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active") // li
      const parent2 = parent.parentElement
      parent2.classList.add("active") // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add("active") // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add("active") // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add("active") // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add("active") // li
            }
          }
        }
      }
    }
    return false
  }

  return decoded.role == "admin" ? (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    to="/dashboard"
                  >
                    <i className="bx bxs-home me-2"></i>
                    {props.t("Dashboard")} {props.menuOpen}
                  </Link>
                </li>

                {/* <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    onClick={e => {
                      e.preventDefault()
                      setadministration(!administration)
                    }}
                    to="/#"
                  >
                    <i className="bx bx-home-circle me-2"></i>
                    {props.t("Administration")} {props.menuOpen}
                    <div className="arrow-down"></div>
                  </Link>
                  <div
                    className={classname("dropdown-menu", {
                      show: administration,
                    })}
                  >
                    <Link to="/administration/committee" className="dropdown-item">
                      {props.t("Steering Committee")}
                    </Link>
                    <Link to="/administration/contract" className="dropdown-item">
                      {props.t("Contract Quote")}
                    </Link>
                    <Link to="/administration/quoting" className="dropdown-item">
                      {props.t("Quoting")}
                    </Link>
                    <Link to="/administration/invoice" className="dropdown-item">
                      {props.t("Invoice")}
                    </Link>
                    <Link to="/administration/training" className="dropdown-item">
                      {props.t("Training")}
                    </Link>
                    <Link to="/administration/others" className="dropdown-item">
                      {props.t("Others documents")}
                    </Link>
                  </div>
                </li> */}

                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    onClick={e => {
                      e.preventDefault()
                      setusers(!users)
                    }}
                    to="/#"
                  >
                    <i className="bx bxs-receipt me-2"></i>
                    {props.t("Users")} {props.menuOpen}
                    <div className="arrow-down"></div>
                  </Link>
                  <div className={classname("dropdown-menu", { show: users })}>
                    <Link to="/user/all-users" className="dropdown-item">
                      {props.t("User List")}
                    </Link>
                    <Link to="/user/in" className="dropdown-item">
                      {props.t("Form in")}
                    </Link>
                    {/* <Link to="/user/youzer" className="dropdown-item">
                      {props.t("Form in Youzer")}
                    </Link> */}
                    <Link to="/user/update" className="dropdown-item">
                      {props.t("Form update")}
                    </Link>
                    <Link to="/user/replace" className="dropdown-item">
                      {props.t("Form replace")}
                    </Link>
                    <Link to="/user/out" className="dropdown-item">
                      {props.t("Form out")}
                    </Link>
                  </div>
                </li>

                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    onClick={e => {
                      e.preventDefault()
                      setactivity(!activity)
                    }}
                    to="/#"
                  >
                    <i className="bx bxs-factory me-2"></i>
                    {props.t("Activity")} {props.menuOpen}
                    <div className="arrow-down"></div>
                  </Link>
                  <div
                    className={classname("dropdown-menu", { show: activity })}
                  >
                    <Link to="/activity/ticket" className="dropdown-item">
                      {props.t("Ticket")}
                    </Link>
                    {/* <Link to="/activity/project" className="dropdown-item">
                      {props.t("Project")}
                    </Link> */}
                    <Link to="/activity/statistics" className="dropdown-item">
                      {props.t("Statistics")}
                    </Link>
                  </div>
                </li>

                {/* <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    onClick={e => {
                      e.preventDefault()
                      setservices(!services)
                    }}
                    to="/#"
                  >
                    <i className="bx bxs-bar-chart-alt-2 me-2"></i>
                    {props.t("Services")} {props.menuOpen}
                    <div className="arrow-down"></div>
                  </Link>
                  <div
                    className={classname("dropdown-menu", { show: services })}
                  >
                    <Link to="/services/subscribed" className="dropdown-item">
                      {props.t("Subscribed")}
                    </Link>
                    <a
                      target="_blank"
                      className="dropdown-item"
                      href="https://www.coaching365.fr/#formules"
                      rel="noreferrer"
                    >
                      {props.t("Trainings available")}
                    </a>
                  </div>
                </li> */}

                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    onClick={e => {
                      e.preventDefault()
                      setpark(!park)
                    }}
                    to="/#"
                  >
                    <i className="bx bx-window-alt me-2"></i>
                    {props.t("Your uses")} {props.menuOpen}
                    <div className="arrow-down"></div>
                  </Link>
                  <div className={classname("dropdown-menu", { show: park })}>
                    <Link to="/usages/inventory" className="dropdown-item">
                      {props.t("Equipment inventory")}
                    </Link>
                  </div>
                </li>

                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    onClick={e => {
                      e.preventDefault()
                      setsave(!save)
                    }}
                    to="/#"
                  >
                    <i className="bx bxs-save me-2"></i>
                    {props.t("Save")} {props.menuOpen}
                    <div className="arrow-down"></div>
                  </Link>
                  <div className={classname("dropdown-menu", { show: save })}>
                    <Link to="/save" className="dropdown-item">
                      {props.t("Save M365")}
                    </Link>
                  </div>
                </li>

                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    onClick={e => {
                      e.preventDefault()
                      setadmin(!admin)
                    }}
                    to="/#"
                  >
                    <i className="bx bxs-factory me-2"></i>
                    {props.t("Admin")} {props.menuOpen}
                    <div className="arrow-down"></div>
                  </Link>
                  <div
                    className={classname("dropdown-menu", {
                      show: admin,
                    })}
                  >
                    <Link to="/new-users" className="dropdown-item">
                      {props.t("New users")}
                    </Link>
                    <Link to="/all-users" className="dropdown-item">
                      {props.t("All users")}
                    </Link>
                  </div>
                </li>
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    to="/dashboard"
                  >
                    <i className="bx bxs-home me-2"></i>
                    {props.t("Dashboard")} {props.menuOpen}
                  </Link>
                </li>

                {/* <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    onClick={e => {
                      e.preventDefault()
                      setadministration(!administration)
                    }}
                    to="/#"
                  >
                    <i className="bx bx-home-circle me-2"></i>
                    {props.t("Administration")} {props.menuOpen}
                    <div className="arrow-down"></div>
                  </Link>
                  <div
                    className={classname("dropdown-menu", {
                      show: administration,
                    })}
                  >
                    <Link to="/administration/committee" className="dropdown-item">
                      {props.t("Steering Committee")}
                    </Link>
                    <Link to="/administration/contract" className="dropdown-item">
                      {props.t("Contract Quote")}
                    </Link>
                    <Link to="/administration/quoting" className="dropdown-item">
                      {props.t("Quoting")}
                    </Link>
                    <Link to="/administration/invoice" className="dropdown-item">
                      {props.t("Invoice")}
                    </Link>
                    <Link to="/administration/training" className="dropdown-item">
                      {props.t("Training")}
                    </Link>
                    <Link to="/administration/others" className="dropdown-item">
                      {props.t("Others documents")}
                    </Link>
                  </div>
                </li> */}

                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    onClick={e => {
                      e.preventDefault()
                      setusers(!users)
                    }}
                    to="/#"
                  >
                    <i className="bx bxs-receipt me-2"></i>
                    {props.t("Users")} {props.menuOpen}
                    <div className="arrow-down"></div>
                  </Link>
                  <div className={classname("dropdown-menu", { show: users })}>
                    <Link to="/user/all-users" className="dropdown-item">
                      {props.t("User List")}
                    </Link>
                    <Link to="/user/in" className="dropdown-item">
                      {props.t("Form in")}
                    </Link>
                    {/* <Link to="/user/youzer" className="dropdown-item">
                      {props.t("Form in Youzer")}
                    </Link> */}
                    <Link to="/user/update" className="dropdown-item">
                      {props.t("Form update")}
                    </Link>
                    <Link to="/user/replace" className="dropdown-item">
                      {props.t("Form replace")}
                    </Link>
                    <Link to="/user/out" className="dropdown-item">
                      {props.t("Form out")}
                    </Link>
                  </div>
                </li>

                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    onClick={e => {
                      e.preventDefault()
                      setactivity(!activity)
                    }}
                    to="/#"
                  >
                    <i className="bx bxs-factory me-2"></i>
                    {props.t("Activity")} {props.menuOpen}
                    <div className="arrow-down"></div>
                  </Link>
                  <div
                    className={classname("dropdown-menu", { show: activity })}
                  >
                    <Link to="/activity/ticket" className="dropdown-item">
                      {props.t("Ticket")}
                    </Link>
                    {/* <Link to="/activity/project" className="dropdown-item">
                      {props.t("Project")}
                    </Link> */}
                    <Link to="/activity/statistics" className="dropdown-item">
                      {props.t("Statistics")}
                    </Link>
                  </div>
                </li>

                {/* <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    onClick={e => {
                      e.preventDefault()
                      setservices(!services)
                    }}
                    to="/#"
                  >
                    <i className="bx bxs-bar-chart-alt-2 me-2"></i>
                    {props.t("Services")} {props.menuOpen}
                    <div className="arrow-down"></div>
                  </Link>
                  <div
                    className={classname("dropdown-menu", { show: services })}
                  >
                    <Link to="/services/subscribed" className="dropdown-item">
                      {props.t("Subscribed")}
                    </Link>
                    <a
                      target="_blank"
                      className="dropdown-item"
                      href="https://www.coaching365.fr/#formules"
                      rel="noreferrer"
                    >
                      {props.t("Trainings available")}
                    </a>
                  </div>
                </li> */}

                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    onClick={e => {
                      e.preventDefault()
                      setpark(!park)
                    }}
                    to="/#"
                  >
                    <i className="bx bx-window-alt me-2"></i>
                    {props.t("Your uses")} {props.menuOpen}
                    <div className="arrow-down"></div>
                  </Link>
                  <div className={classname("dropdown-menu", { show: park })}>
                    <Link to="/usages/inventory" className="dropdown-item">
                      {props.t("Equipment inventory")}
                    </Link>
                  </div>
                </li>

                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    to="/save"
                  >
                    <i className="bx bxs-floppy me-2"></i>
                    {props.t("Save")} {props.menuOpen}
                  </Link>
                </li>

                {/* <li className="nav-item dropdown">
                  <a
                    target="_blank"
                    className="nav-link dropdown-toggle arrow-none"
                    href="http://boutique.izencia.com"
                    rel="noreferrer"
                  >
                    <i className="bx bxs-shopping-bag me-2"></i>
                    {props.t("Marketplace")} {props.menuOpen}
                  </a>
                </li> */}
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  )
}

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout
  return { leftMenu }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
)
