import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React from "react"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

//redux

import { withRouter } from "react-router-dom"

const Subscribed = props => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Subscribed | izencia - Espace Client</title>
        </MetaTags>
        <Container fluid>
          <h4>Subscribed</h4>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Subscribed)

Subscribed.propTypes = {
  history: PropTypes.object,
}
