import {
  UPDATE_COMPANY_USERS,
  UPDATE_COMPANY_USERS_SUCCESS,
  UPDATE_COMPANY_USERS_FAILED,
} from "./actionTypes"

const initialState = {
  updateCompanyUsersError: null,
  message: null,
  loading: false,
  companyUsers: null,
}

const updateCompanyUsers = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_COMPANY_USERS:
      state = {
        ...state,
        loading: true,
        updateCompanyUsersError: null,
      }
      break
    case UPDATE_COMPANY_USERS_SUCCESS:
      state = {
        ...state,
        loading: false,
        companyUsers: action.payload,
        updateCompanyUsersError: null,
      }
      break
    case UPDATE_COMPANY_USERS_FAILED:
      state = {
        ...state,
        companyUsers: null,
        loading: false,
        updateCompanyUsersError: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default updateCompanyUsers
