import { GET_ACRONIS_INFO_FAIL, GET_ACRONIS_INFO_SUCCESS } from "./actionTypes"

const INIT_STATE = {
  acronisInfo: [],
  error: {},
}

const AcronisInfo = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ACRONIS_INFO_SUCCESS:
      return {
        ...state,
        acronisInfo: action.payload,
        error: null,
      }

    case GET_ACRONIS_INFO_FAIL:
      return {
        ...state,
        acronisInfo: null,
        error: action.payload,
      }

    default:
      return state
  }
}

export default AcronisInfo
