import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { ADD_YOUZER } from "./actionTypes"
import { addYouzerSuccess, addYouzerFailed } from "./actions"

import { Youzer } from "helpers/youzer_helper"

import jwt from "jsonwebtoken"
import { toast } from "react-toastify"

function* addYouzer({ payload: { youzer, history } }) {
  try {
    const token = localStorage.getItem("auth")
    const decoded = jwt.decode(token)

    const response = yield call(
      Youzer,
      `${process.env.REACT_APP_OPERATION}/ticket/`,
      {
        companyName: decoded.company,
        companyID: decoded.companyId,
        startDate: youzer.startDate,
        endDate: youzer.endDate,
        lastName: youzer.lastName,
        firstName: youzer.firstName,
        title: youzer.title,
        phone: youzer.phone,
        cellphone: youzer.cellphone,
        employeeType: youzer.employeeType,
        manager: youzer.manager,
        site: youzer.site,
        computerAssignation: youzer.computerAssignation,
        message: youzer.message,
        support: youzer.support,
        email: youzer.email,
        vip: youzer.vip,
        allocations: youzer.allocations,
        creator: `${decoded.username} | ${decoded?.email}`,
      }
    )
    toast.success("Demande enregistré", {
      position: toast.POSITION.BOTTOM_RIGHT,
    })

    yield put(addYouzerSuccess(response))
  } catch (error) {
    toast.error("Erreur d'enregistrement", {
      position: toast.POSITION.BOTTOM_RIGHT,
    })
    yield put(addYouzerFailed(error))
  }
}

export function* watchAddYouzer() {
  yield takeEvery(ADD_YOUZER, addYouzer)
}

function* accountSaga() {
  yield all([fork(watchAddYouzer)])
}

export default accountSaga
