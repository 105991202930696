import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React from "react"

import { Row, Col } from "reactstrap"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
  PaginationTotalStandalone,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

import "./datatable.scss"


//i18n
import { withTranslation } from "react-i18next"

const DataTable = props => {
  const defaultSorted = [
    {
      dataField: props.defaultSorted,
      order: props.order,
    },
  ]

  const pageOptions = {
    sizePerPage: props.sizePerPage,
    // eslint-disable-next-line react/prop-types
    totalSize: props.data?.length,
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "15", value: 15 },
    { text: "20", value: 20 },
    { text: "25", value: 25 },
  ]

  const { SearchBar } = Search

  return (
    <React.Fragment>
      <PaginationProvider
        pagination={paginationFactory(pageOptions)}
        keyField={props.keyField}
        columns={props.columns}
        data={props.data}
      >
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField={props.keyField}
            columns={props.columns}
            data={props.data}
            search
          >
            {toolkitProps => (
              <React.Fragment>
                <Row className="mb-2">
                  <Col md="4">
                    <div className="search-box me-2 mb-2 d-inline-block">
                      <div className="position-relative">
                        <SearchBar placeholder={props.t("Search")} {...toolkitProps.searchProps} />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xl="12">
                    <div className="table-responsive">
                      <BootstrapTable
                        keyField={props.keyField}
                        responsive
                        bordered={false}
                        striped={false}
                        defaultSorted={defaultSorted}
                        classes={"table align-middle table-nowrap"}
                        headerWrapperClasses={"thead-light"}
                        {...toolkitProps.baseProps}
                        {...paginationTableProps}
                      />
                    </div>
                  </Col>
                </Row>

                <Row className="align-items-md-center mt-30">
                  <Col className="inner-custom-pagination d-flex">
                    <div className="d-inline">
                      <PaginationTotalStandalone {...paginationProps} />
                    </div>
                    <div className="text-md-right ms-auto">
                      <PaginationListStandalone {...paginationProps} />
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>
    </React.Fragment>
  )
}

export default withTranslation()(DataTable)

DataTable.propTypes = {
  history: PropTypes.object,
  data: PropTypes.array,
  columns: PropTypes.array,
  defaultSorted: PropTypes.string,
  order: PropTypes.string,
  sizePerPage: PropTypes.number,
  keyField: PropTypes.string,
  t: PropTypes.any,
}
