import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"

import {
  Container,
  Spinner,
  CardBody,
  Card,
  CardTitle,
  Row,
  Col,
} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import DataTable from "components/DataTable"

import { useSelector, useDispatch } from "react-redux"

//i18n
import { withTranslation } from "react-i18next"
import { getTraining } from "store/actions"

const Training = props => {
  const dispatch = useDispatch()

  const { training } = useSelector(state => ({
    training: state.getTraining?.training.trainings,
  }))

  useEffect(() => {
    dispatch(getTraining())
  }, [dispatch])

  const redirect = value => {
    window.location.href = training[value]?.downloadUrl
  }

  function dowloadLink(cell, row, rowIndex, training) {
    return (
      <button
        type="button"
        onClick={() => redirect(rowIndex)}
        target="blank_"
        className="btn btn-primary "
      >
        <i className="bx bx-download font-size-16 align-middle"></i>
      </button>
    )
  }

  const columns = [
    {
      dataField: "df1",
      isDummyField: true,
      text: "Icone",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => {
        if (row.name.includes("pdf")) {
          return (
            <i className="mdi mdi-file-pdf-outline font-size-16 align-middle text-danger me-2"></i>
          )
        } else if (row.name.includes("docx")) {
          return (
            <i className="mdi mdi-file-word font-size-16 align-middle text-info me-2"></i>
          )
        }
        return (
          <h5>
            <i className="mdi mdi-file font-size-16 align-middle text-secondary me-2"></i>
          </h5>
        )
      },
    },
    {
      dataField: "name",
      text: props.t("Name"),
      sort: true,
    },
    {
      dataField: "createdDateTime",
      text: props.t("Creation date"),
      sort: true,
    },
    {
      dataField: "downloadUrl",
      text: props.t("Download"),
      formatter: dowloadLink,
    },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("Training")} | izencia - Espace Client</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title={props.t("Documents")}
            breadcrumbItem={props.t("Training")}
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">{props.t("Training")}</CardTitle>
                  <p className="card-title-desc">
                    {props.t("Training Description")}
                  </p>

                  {training ? (
                    <DataTable
                      sizePerPage={10}
                      order="asc"
                      defaultSorted="id"
                      columns={columns}
                      data={training}
                      keyField={"id"}
                    />
                  ) : (
                    <Spinner className="ms-2" color="primary" />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Training.propTypes = {
  t: PropTypes.any,
  training: PropTypes.array,
}

export default withTranslation()(Training)
