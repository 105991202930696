import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_COMPANY } from "./actionTypes"
import { getCompanySuccess, getCompanyFail } from "./actions"

//Include Both Helper File with needed methods
import { getOneCompany } from "helpers/activity_helper"

import jwt from "jsonwebtoken"

function* fetchCompany() {
  try {

    const token = localStorage.getItem("auth")
    const decoded = jwt.decode(token)

    const response = yield call(
      getOneCompany,
      `${process.env.REACT_APP_OPERATION}/company/getOne`,
      {
        id: decoded.companyId,
      }
    )
    yield put(getCompanySuccess(response))
  } catch (error) {
    yield put(getCompanyFail(error))
  }
}

function* companySaga() {
  yield takeEvery(GET_COMPANY, fetchCompany)
}

export default companySaga
