import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  FormFeedback,
  Alert,
  Form,
  Input,
  Label,
  Spinner,
  FormGroup,
  Col,
  Row,
} from "reactstrap"

// actions
import { removeYouzer } from "../../../../store/actions"

import { useSelector, useDispatch } from "react-redux"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

//i18n
import { withTranslation } from "react-i18next"

const FormActivateUsers = props => {
  let { users, history } = props

  users = users?.sort((a, b) =>
    a.lastName > b.lastName ? 1 : b.lastName > a.lastName ? -1 : 0
  )

  const dispatch = useDispatch()

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      emailaccess: "",
      redirectemail: "",
      save: "",
      computer: "",
      message: "",
      endDate: "",
      dataAccess: false,
    },
    validationSchema: Yup.object({
      email: Yup.string().required(props.t("Enter email")),
      emailaccess: Yup.string(),
      redirectemail: Yup.string(),
      save: Yup.string().required(props.t("Choose save")),
      computer: Yup.string().required(props.t("Choose computer")),
      message: Yup.string(),
      endDate: Yup.date().required(props.t("Enter endDate")),
      dataAccess: Yup.boolean(),
    }),
    onSubmit: values => {
      dispatch(removeYouzer(values, history))
    },
  })

  const { error } = useSelector(state => ({
    error: state.removeYouzer.error,
  }))

  return users ? (
    <Form
      className="form-horizontal"
      onSubmit={e => {
        e.preventDefault()
        validation.handleSubmit()
        return false
      }}
    >
      {error ? <Alert color="danger">{error}</Alert> : null}

      <Row>
        <Col lg="6">
          <FormGroup className="mb-3">
            <Label className="form-label">{props.t("Username")} *</Label>
            <Input
              name="email"
              className="form-control"
              type="select"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.email || ""}
              invalid={
                validation.touched.email && validation.errors.email
                  ? true
                  : false
              }
            >
              <option key="1-email"></option>
              {users.map(users => (
                <option
                  value={`${users?.lastName} ${users?.firstName} | ${users?.emailAddress}`}
                  key={`${users?.id}-email`}
                >
                  {users?.lastName} {users?.firstName} | {users?.emailAddress}
                </option>
              ))}
            </Input>

            {validation.touched.email && validation.errors.email ? (
              <FormFeedback type="invalid">
                {validation.errors.email}
              </FormFeedback>
            ) : null}
          </FormGroup>
        </Col>

        <Col lg="6">
          <FormGroup className="mb-3">
            <Label className="form-label">{props.t("End date")} *</Label>
            <Input
              name="endDate"
              className="form-control"
              type="date"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.endDate || ""}
              invalid={
                validation.touched.endDate && validation.errors.endDate
                  ? true
                  : false
              }
            />
            {validation.touched.endDate && validation.errors.endDate ? (
              <FormFeedback type="invalid">
                {validation.errors.endDate}
              </FormFeedback>
            ) : null}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup className="mb-3">
            <Label className="form-label">{props.t("Save")} *</Label>
            <Input
              name="save"
              className="form-control"
              type="select"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.save || ""}
              invalid={
                validation.touched.save && validation.errors.save ? true : false
              }
            >
              <option key="1-save"></option>
              <option key="2-save">{props.t("Deletion")}</option>
              <option key="3-save">{props.t("Archiving")}</option>
              <option key="4-save">{props.t("Idk")}</option>
            </Input>

            {validation.touched.save && validation.errors.save ? (
              <FormFeedback type="invalid">
                {validation.errors.save}
              </FormFeedback>
            ) : null}
          </FormGroup>
        </Col>

        <Col lg="6">
          <FormGroup className="mb-3">
            <Label className="form-label">{props.t("Computer")} *</Label>
            <Input
              name="computer"
              className="form-control"
              type="select"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.computer || ""}
              invalid={
                validation.touched.computer && validation.errors.computer
                  ? true
                  : false
              }
            >
              <option key="1-computer"></option>
              <option key="2-computer">{props.t("Park exit")}</option>
              <option key="3-computer">{props.t("Reassignment")}</option>
              <option key="4-computer">{props.t("Idk")}</option>
            </Input>

            {validation.touched.computer && validation.errors.computer ? (
              <FormFeedback type="invalid">
                {validation.errors.computer}
              </FormFeedback>
            ) : null}
          </FormGroup>
        </Col>
      </Row>

      <FormGroup className="mb-3">
        <div className="form-check">
          <Input
            name="dataAccess"
            className="form-check-Input"
            type="checkbox"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.dataAccess || ""}
            invalid={
              validation.touched.dataAccess && validation.errors.dataAccess
                ? true
                : false
            }
          />
          <Label
            className="form-check-Label custom-label"
            htmlFor="formrow-customCheck"
          >
            {props.t("dataAccess")} |{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://email1.blob.core.windows.net/espace-client/Attestation d'autorisation d'accès aux données à caractère non personnelles d'uin salarié lors de son départ.pdf"
            >
             {props.t("Link to the document")}
            </a>
          </Label>
          {validation.touched.dataAccess && validation.errors.dataAccess ? (
            <FormFeedback type="invalid">
              {validation.errors.dataAccess}
            </FormFeedback>
          ) : null}
        </div>
      </FormGroup>

      <FormGroup className="mb-3">
        <Label className="form-label">{props.t("Email Access")}</Label>
        <Input
          name="emailaccess"
          className="form-control"
          type="select"
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values.emailaccess || ""}
          invalid={
            validation.touched.emailaccess && validation.errors.emailaccess
              ? true
              : false
          }
        >
          <option key="1-emailaccess"></option>
          {users.map(users => (
            <option
              value={`${users?.lastName} ${users?.firstName} | ${users?.emailAddress}`}
              key={`${users?.id}-emailaccess`}
            >
              {users?.lastName} {users?.firstName} | {users?.emailAddress}
            </option>
          ))}
        </Input>

        {validation.touched.emailaccess && validation.errors.emailaccess ? (
          <FormFeedback type="invalid">
            {validation.errors.emailaccess}
          </FormFeedback>
        ) : null}
      </FormGroup>

      <FormGroup className="mb-3">
        <Label className="form-label">{props.t("Redirect Email")}</Label>
        <Input
          name="redirectemail"
          className="form-control"
          type="select"
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values.redirectemail || ""}
          invalid={
            validation.touched.redirectemail && validation.errors.redirectemail
              ? true
              : false
          }
        >
          <option key="1-redirectemail"></option>
          {users.map(users => (
            <option
              value={`${users?.lastName} ${users?.firstName} | ${users?.emailAddress}`}
              key={`${users?.id}-redirectemail`}
            >
              {users?.lastName} {users?.firstName} | {users?.emailAddress}
            </option>
          ))}
        </Input>

        {validation.touched.redirectemail && validation.errors.redirectemail ? (
          <FormFeedback type="invalid">
            {validation.errors.redirectemail}
          </FormFeedback>
        ) : null}
      </FormGroup>

      <FormGroup className="mb-3">
        <Label className="form-label">{props.t("Remarks")}</Label>
        <Input
          name="message"
          className="form-control"
          placeholder={props.t("Enter remarks placeholder")}
          type="textarea"
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values.message || ""}
          invalid={
            validation.touched.message && validation.errors.message
              ? true
              : false
          }
        />
        {validation.touched.message && validation.errors.message ? (
          <FormFeedback type="invalid">
            {validation.errors.message}
          </FormFeedback>
        ) : null}
      </FormGroup>

      <div className="mt-3 d-grid">
        <button className="btn btn-danger btn-block" type="submit">
          {props.t("Exit")}
        </button>
      </div>
    </Form>
  ) : (
    <Spinner className="ms-2" color="primary" />
  )
}

FormActivateUsers.propTypes = {
  users: PropTypes.any,
  t: PropTypes.any,
  history: PropTypes.any,
}

export default withTranslation()(FormActivateUsers)
