import React from "react"
import PropTypes from "prop-types"

import { Row, Col, Card, CardBody, Spinner } from "reactstrap"

import { NavLink } from "react-router-dom"

import avatar1 from "../../assets/images/logo.svg"
import profileImg from "../../assets/images/profile-img.png"

//i18n
import { withTranslation } from "react-i18next"

import jwt from "jsonwebtoken"

const WelcomeComp = props => {
  const token = localStorage.getItem("auth")
  const decoded = jwt.decode(token)

  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-primary bg-soft">
          <Row>
            <Col xs="7">
              <div className="text-primary p-3">
                <h5 className="text-primary">{props.t("Welcome")}</h5>
                <p>Espace Client IZENCIA</p>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              <img src={profileImg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="4">
              <div className="avatar-md profile-user-wid mb-4">
                <img
                  src={avatar1}
                  alt=""
                  className="img-thumbnail rounded-circle p-2O"
                />
              </div>

              <h5 className="font-size-15 text-truncate">{decoded.username}</h5>
              <p className="text-muted mb-0 text-truncate">{decoded.company}</p>
            </Col>

            <Col sm="8">
              <div className="pt-4">
                <Row>
                  <Col xs="6">
                    <NavLink to="user/all-users">
                      <h5 className="font-size-15">{props.instances}</h5>
                      <p className="text-muted mb-0">{props.t("Users")}</p>
                    </NavLink>
                  </Col>
                  <Col xs="6">
                    <NavLink to="usages/inventory">
                      <h5 className="font-size-15">{props.inventory}</h5>
                      <p className="text-muted mb-0">{props.t("Equipment")}</p>
                    </NavLink>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default withTranslation()(WelcomeComp)

WelcomeComp.propTypes = {
  t: PropTypes.any,
  inventory: PropTypes.number,
  instances: PropTypes.number,
}
