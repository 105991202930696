import {
  GET_COMPANIES,
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES_FAILED,
} from "./actionTypes"

const initialState = {
  getCompaniesError: null,
  message: null,
  loading: false,
  companies: null,
}

const getCompanies = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANIES:
      state = {
        ...state,
        loading: true,
        getCompaniesError: null,
      }
      break
    case GET_COMPANIES_SUCCESS:
      state = {
        ...state,
        loading: false,
        companies: action.payload,
        getCompaniesError: null,
      }
      break
    case GET_COMPANIES_FAILED:
      state = {
        ...state,
        companies: null,
        loading: false,
        getCompaniesError: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default getCompanies
