import {
  ALL_USERS,
  ALL_USERS_SUCCESS,
  ALL_USERS_FAILED,

} from "./actionTypes"

export const allUsers = (users, history) => {
  return {
    type: ALL_USERS,
    payload: { users, history },
  }
}

export const allUsersSuccess = users => {
  return {
    type: ALL_USERS_SUCCESS,
    payload: users,
  }
}

export const allUsersFailed = users => {
  return {
    type: ALL_USERS_FAILED,
    payload: users,
  }
}
