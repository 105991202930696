import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { FormFeedback, Alert, Form, Input, Label, Spinner } from "reactstrap"

// actions
import { updateCompany } from "../../../store/actions"

import { useSelector, useDispatch } from "react-redux"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

const FormActivateUsers = props => {
  const { data, companies } = props

  const dispatch = useDispatch()

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      company: "",
      _id: data._id,
    },
    validationSchema: Yup.object({
      company: Yup.string().required("Veuillez Entrer Votre Entreprise"),
    }),
    onSubmit: values => {
      dispatch(updateCompany(values))
    },
  })

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }))

  return companies ? (
    <Form
      className="form-horizontal"
      onSubmit={e => {
        e.preventDefault()
        validation.handleSubmit()
        return false
      }}
    >
      {error ? <Alert color="danger">{error}</Alert> : null}

      <div className="mb-3">
        <Label className="form-label">Entreprise</Label>
        <Input
          name="company"
          className="form-control"
          placeholder="Entrer l'entreprise"
          type="select"
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values.company || ""}
          invalid={
            validation.touched.company && validation.errors.company
              ? true
              : false
          }
        >
          {companies.map(companies => (
            <option
              value={`${companies.id},${companies.companyName}`}
              key={companies.id}
            >
              {companies.companyName}
            </option>
          ))}
        </Input>

        {validation.touched.company && validation.errors.company ? (
          <FormFeedback type="invalid">
            {validation.errors.company}
          </FormFeedback>
        ) : null}
      </div>

      <div className="mt-3 d-grid">
        <button className="btn btn-primary btn-block" type="submit">
          Ajouter
        </button>
      </div>
    </Form>
  ) : (
    <Spinner className="ms-2" color="primary" />
  )
}

FormActivateUsers.propTypes = {
  data: PropTypes.any,
  companies: PropTypes.any,
}

export default FormActivateUsers
