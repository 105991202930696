/* PROJECT */
export const GET_PROJECT = "GET_PROJECT"
export const GET_PROJECT_SUCCESS = "GET_PROJECT_SUCCESS"
export const GET_PROJECT_FAIL = "GET_PROJECT_FAIL"

export const GET_PROJECT_IN_PROGRESS = "GET_PROJECT_IN_PROGRESS"
export const GET_PROJECT_IN_PROGRESS_SUCCESS = "GET_PROJECT_IN_PROGRESS_SUCCESS"
export const GET_PROJECT_IN_PROGRESS_FAIL = "GET_PROJECT_IN_PROGRESS_FAIL"

export const GET_PROJECT_DONE = "GET_PROJECT_DONE"
export const GET_PROJECT_DONE_SUCCESS = "GET_PROJECT_DONE_SUCCESS"
export const GET_PROJECT_DONE_FAIL = "GET_PROJECT_DONE_FAIL"

