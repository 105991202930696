import {
  ADD_YOUZER,
  ADD_YOUZER_SUCCESS,
  ADD_YOUZER_FAILED,
} from "./actionTypes"

const initialState = {
  addYouzerError: null,
  message: null,
  loading: false,
  youzer: null,
}

const addYouzer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_YOUZER:
      state = {
        ...state,
        loading: true,
        addYouzerError: null,
      }
      break
    case ADD_YOUZER_SUCCESS:
      state = {
        ...state,
        loading: false,
        youzer: action.payload,
        addYouzerError: null,
      }
      break
    case ADD_YOUZER_FAILED:
      state = {
        ...state,
        youzer: null,
        loading: false,
        addYouzerError: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default addYouzer
