import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, LOGIN_MFA_USER } from "./actionTypes"
import {
  apiError,
  loginSuccess,
  logoutUserSuccess,
  loginMfaSuccess,
} from "./actions"
import { getLogout, postLogin, postMfa } from "helpers/auth_helper"
import jwt from "jsonwebtoken"
import { toast } from "react-toastify"

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(
      postLogin,
      `${process.env.REACT_APP_USER}/api/v1/auth/login`,
      {
        email: user.email,
        password: user.password,
      }
    )
    localStorage.setItem("auth", response.token)

    yield put(loginSuccess(response))
    const decoded = jwt.decode(response.token)

    if (decoded.mfa_status == true) {
      history.push("/validate")
    } else {
      history.push("/verify")
    }
  } catch (error) {
    yield put(apiError(error))
  }
}

function* loginMfaUser({ payload: { user, history } }) {
  const token = localStorage.getItem("auth")
  const decoded = jwt.decode(token)

  try {
    const response = yield call(
      postMfa,
      `${process.env.REACT_APP_USER}/api/v1/auth/validate`,
      {
        mfaToken: user.code,
        userId: decoded.id,
      }
    )
    localStorage.setItem("auth", response.token)

    yield put(loginMfaSuccess(response))
    const decodedresponse = jwt.decode(response.token)

    if (decodedresponse.status === false) {
      toast.error(
        "Votre compte doit être approuvé par un administrateur izencia",
        {
          position: toast.POSITION.BOTTOM_RIGHT,
        }
      )
    }

    history.push("/dashboard")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("auth")

    const response = yield call(
      getLogout,
      `${process.env.REACT_APP_USER}/api/v1/auth/logout`
    )
    yield put(logoutUserSuccess(response))

    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGIN_MFA_USER, loginMfaUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
