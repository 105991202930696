import { GET_ACRONIS_TENANT_FAIL, GET_ACRONIS_TENANT_SUCCESS } from "./actionTypes"

const INIT_STATE = {
  acronisTenant: [],
  error: {},
}

const AcronisTenant = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ACRONIS_TENANT_SUCCESS:
      return {
        ...state,
        acronisTenant: action.payload,
        error: null,
      }

    case GET_ACRONIS_TENANT_FAIL:
      return {
        ...state,
        acronisTenant: null,
        error: action.payload,
      }

    default:
      return state
  }
}

export default AcronisTenant
