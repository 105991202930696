import {
  GET_OTHER,
  GET_OTHER_FAIL,
  GET_OTHER_SUCCESS,
} from "./actionTypes"

export const getOther = () => ({
  type: GET_OTHER,
})

export const getOtherSuccess = others => ({
  type: GET_OTHER_SUCCESS,
  payload: others,
})

export const getOtherFail = error => ({
  type: GET_OTHER_FAIL,
  payload: error,
})