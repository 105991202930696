import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"

import { Row, Col, CardBody, Card, Container, Spinner } from "reactstrap"

import { withTranslation } from "react-i18next"

//redux
import {
  getTicketInProgress,
  getTicketDoneToday,
  getCompany,
} from "store/actions"
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link } from "react-router-dom"
import CardStats from "components/Common/CardStats"

import DataTableModal from "../../components/DataTable/Modal/datatable"

const Statistics = props => {
  const [modal1, setModal1] = useState(false)
  const [modal2, setModal2] = useState(false)
  const [modal3, setModal3] = useState(false)

  const dispatch = useDispatch()

  const toggleViewModal = value => {
    if (value === 1) {
      setModal1(!modal1)
    } else if (value === 2) {
      setModal2(!modal2)
    } else if (value === 3) {
      setModal3(!modal3)
    }
  }

  useEffect(() => {
    dispatch(getCompany())
  }, [dispatch])

  useEffect(() => {
    dispatch(getTicketInProgress())
  }, [dispatch])

  useEffect(() => {
    dispatch(getTicketDoneToday())
  }, [dispatch])

  const { myCompany } = useSelector(state => ({
    myCompany: state.getOneCompany?.company?.data,
  }))


  const { ticketInProgress } = useSelector(state => ({
    ticketInProgress: state.getTicket?.ticketInProgress?.tickets,
  }))

  const { ticketDoneToday } = useSelector(state => ({
    ticketDoneToday: state.getTicket?.ticketDoneToday?.tickets,
  }))

  function checkDate(ticketInProgress) {
    let result = ticketInProgress?.map(a => a.createDate)
    let arrayToday = []

    const today = new Date()

    result?.forEach(element => {
      let value = new Date(element)

      if (today.toUTCString() === value.toUTCString()) {
        arrayToday.push(element)
      }
    })

    return arrayToday
  }

  const todayDate = checkDate(ticketInProgress)

  /*   function checkDate(ticketInProgress) {
    let result = ticketInProgress?.map(a => a.createDate)
    let arrayToday = []

    const today = new Date()

    result.forEach(element => {
      let value = new Date(element)

      if (today.toUTCString() === value.toUTCString()) {
        arrayToday.push(element)
      }
    })

    return arrayToday
  }

  const todayDate = checkDate(ticketInProgress) */

  const columns1 = [
    {
      dataField: "ticketNumber",
      text: props.t("TicketNumber"),
      sort: true,
    },
    {
      dataField: "title",
      text: props.t("Name"),
      sort: true,
    },
    {
      dataField: "createDate",
      text: props.t("Creation date"),
    },
  ]

  const columns2 = [
    {
      dataField: "ticketNumber",
      text: props.t("TicketNumber"),
      sort: true,
    },
    {
      dataField: "title",
      text: props.t("Name"),
      sort: true,
    },
    {
      dataField: "createDate",
      text: props.t("Creation date"),
    },
  ]

  const columns3 = [
    {
      dataField: "ticketNumber",
      text: props.t("TicketNumber"),
      sort: true,
    },
    {
      dataField: "title",
      text: props.t("Name"),
      sort: true,
    },
    {
      dataField: "createDate",
      text: props.t("Creation date"),
    },
    {
      dataField: "completedDate",
      text: props.t("completedDate"),
      sort: false,
    },
  ]

  return (
    <React.Fragment>
      {ticketInProgress ? (
        <DataTableModal
          isOpen={modal1}
          data={ticketInProgress}
          columns={columns1}
          toggle={() => toggleViewModal(1)}
        >
          {props.t("Ticket in progress")}
        </DataTableModal>
      ) : (
        <Spinner className="ms-2" color="primary" />
      )}
      {todayDate ? (
        <DataTableModal
          isOpen={modal2}
          data={todayDate}
          columns={columns2}
          toggle={() => toggleViewModal(2)}
        >
          {props.t("Ticket open today")}
        </DataTableModal>
      ) : (
        <Spinner className="ms-2" color="primary" />
      )}
      {ticketInProgress ? (
        <DataTableModal
          isOpen={modal3}
          data={ticketDoneToday}
          columns={columns3}
          toggle={() => toggleViewModal(3)}
        >
          {props.t("Ticket closed today")}
        </DataTableModal>
      ) : (
        <Spinner className="ms-2" color="primary" />
      )}
      <div className="page-content">
        <MetaTags>
          <title>{props.t("Statistics")} | izencia - Espace Client</title>
        </MetaTags>
        <Container fluid>
          <h4>{props.t("Statistics")}</h4>

          <Row>
            <Col md="4">
              {ticketInProgress ? (
                <Link to="#" onClick={() => toggleViewModal(1)}>
                  <CardStats
                    data={ticketInProgress}
                    title={props.t("Ticket in progress")}
                    version="primary"
                  />
                </Link>
              ) : (
                <Spinner className="ms-2" color="primary" />
              )}
            </Col>
            <Col md="4">
              {todayDate ? (
                <Link to="#" onClick={() => toggleViewModal(2)}>
                  <CardStats
                    data={todayDate}
                    title={props.t("Ticket open today")}
                    version="secondary"
                  />
                </Link>
              ) : (
                <Spinner className="ms-2" color="primary" />
              )}
            </Col>
            <Col md="4">
              {ticketDoneToday ? (
                <Link to="#" onClick={() => toggleViewModal(3)}>
                  <CardStats
                    data={ticketDoneToday}
                    title={props.t("Ticket closed today")}
                    version="fourth"
                  />
                </Link>
              ) : (
                <Spinner className="ms-2" color="primary" />
              )}
            </Col>

            {myCompany[0] ? (
              <Col xl="6">
                <Card>
                  <CardBody>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `<iframe src=\'${myCompany[0]?.userDefinedFields[2]?.value}\' style="border: none !important;" width="100%" height="300"/>`,
                      }}
                    />
                  </CardBody>
                </Card>
              </Col>
            ) : (
              <Spinner className="ms-2" color="primary" />
            )}

            {myCompany[0] ? (
              <Col xl="6">
                <Card>
                  <CardBody>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `<iframe src=\'${myCompany[0]?.userDefinedFields[3]?.value}\' style="border: none !important;" width="100%" height="300"/>`,
                      }}
                    />
                  </CardBody>
                </Card>
              </Col>
            ) : (
              <Spinner className="ms-2" color="primary" />
            )}

            {myCompany[0] ? (
              <Col xl="6">
                <Card>
                  <CardBody>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `<iframe src=\'${myCompany[0]?.userDefinedFields[4]?.value}\' style="border: none !important;" width="100%" height="300"/>`,
                      }}
                    />
                  </CardBody>
                </Card>
              </Col>
            ) : (
              <Spinner className="ms-2" color="primary" />
            )}

            {myCompany[0] ? (
              <Col xl="6">
                <Card>
                  <CardBody>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `<iframe src=\'${myCompany[0]?.userDefinedFields[5]?.value}\' style="border: none !important;" width="100%" height="300"/>`,
                      }}
                    />
                  </CardBody>
                </Card>
              </Col>
            ) : (
              <Spinner className="ms-2" color="primary" />
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(Statistics)

Statistics.propTypes = {
  history: PropTypes.object,
  t: PropTypes.any,
}
