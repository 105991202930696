import PropTypes, { element } from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import {
  Spinner,
  Container,
  CardBody,
  Card,
  CardTitle,
  Row,
  Col,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DataTable from "components/DataTable"

//redux
import { getDevice } from "store/actions"
import { useSelector, useDispatch } from "react-redux"

//i18n
import { withTranslation } from "react-i18next"

const InventoryDetails = props => {
  const [modal1, setModal1] = useState(false)
  /*   const [data, setdata] = useState("") */
  const dispatch = useDispatch()

  const devices = useSelector(state => ({
    value: state?.getDevice?.device?.data?.devices,
  }))

  const { uid, type } = useParams()

  useEffect(() => {
    dispatch(getDevice(uid))
  }, [dispatch])

  const toggleViewModal = cell => {
    setModal1(!modal1), setdata(cell)
  }

  function detailValue(row, cell, rowindex) {
    return (
      <button
        type="button"
        onClick={() => toggleViewModal(cell)}
        target="blank_"
        className="btn btn-primary"
      >
        {props.t("View Details")}
      </button>
    )
  }

  const columns = [
    {
      dataField: "deviceType.category",
      text: props.t("Type"),
      sort: true,
    },
    {
      dataField: "hostname",
      text: props.t("Hostname"),
      sort: true,
    },
    {
      dataField: "intIpAddress",
      text: props.t("Internal IP"),
      sort: true,
    },
    {
      dataField: "description",
      text: props.t("Description"),
      sort: true,
    },
    {
      dataField: "operatingSystem",
      text: props.t("OperatingSystem"),
      sort: true,
    },
    {
      dataField: "lastLoggedInUser",
      text: props.t("lastLoggedInUser"),
      sort: true,
    },
    {
      dataField: "lastSeen",
      text: props.t("LastSeen"),
      sort: true,
    },
    /* {
      isDummyField: true,
      text: props.t("View Details"),
      dataField: "view",
      formatter: detailValue,
    }, */
  ]

  let unique = []
  let results = []

  switch (type) {
    case "Postes":
      unique = ["Desktop", "Laptop"]
      break
    case "Serveurs":
      unique = ["Server", "Main System Chassis"]
      break
    case "Switchs":
      unique = ["Datto Switch"]
      break
    case "Bornes":
      unique = ["Datto Access Point"]
      break
    case "Autres":
      unique = [
        "Datto Continuity",
        "Autres",
        "Router",
        "Network Device (Router)",
      ]
      break
    default:
      unique = [
        "Desktop",
        "Laptop",
        "Server",
        "Datto Switch",
        "Datto Access Point",
        "Datto Continuity",
        "",
        undefined,
        null,
      ]
  }

  let newArray = devices.value.map(({ deviceType, lastSeen, ...rest }) => {
    if (deviceType === undefined || deviceType === null) {
      deviceType = { category: "Autres", type: "Unknown" }
    }
    if (lastSeen !== undefined || lastSeen !== null) {
      let date = new Date(lastSeen)
      let stringDate = date.toLocaleString("fr-FR", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      })
      lastSeen = stringDate
    }
    return { ...rest, deviceType, lastSeen }
  })

  unique.forEach(currItem => {
    const elementFiltered = newArray.filter(
      item =>
        item.deviceType != null &&
        item.deviceClass != undefined &&
        item.deviceType.category === currItem
    )

    results.push(...elementFiltered)
  })

  return (
    <React.Fragment>
      {/*       <DataTableModal
        isOpen={modal1}
        data={data}
        styleType="primary"
        toggle={toggleViewModal}
        companies={companies}
      >
        {props.t("Inventory Details")}
      </DataTableModal> */}
      <div className="page-content">
        <MetaTags>
          <title>
            {props.t("Inventory Details")} | izencia - Espace Client
          </title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title={props.t("Usages")}
            breadcrumbItem={props.t("Inventory Details")}
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">
                    {props.t("Inventory Details")}{" "}
                  </CardTitle>
                  <p className="card-title-desc">
                    {props.t("Inventory Details Description")}
                  </p>

                  {results ? (
                    <DataTable
                      sizePerPage={10}
                      order="asc"
                      defaultSorted="id"
                      columns={columns}
                      data={results}
                      keyField={"id"}
                    />
                  ) : (
                    <Spinner className="ms-2" color="primary" />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(InventoryDetails)

InventoryDetails.propTypes = {
  history: PropTypes.object,
  t: PropTypes.any,
  uid: PropTypes.any,
  type: PropTypes.any,
}
