import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { GET_COMPANIES } from "./actionTypes"
import { getCompaniesSuccess, getCompaniesFailed } from "./actions"

import { getCompany } from "helpers/admin_helpers"

function* getCompanies() {
  try {
    const response = yield call(
      getCompany,
      `${process.env.REACT_APP_OPERATION}/company`
    )
    yield put(getCompaniesSuccess(response))
  } catch (error) {
    yield put(getCompaniesFailed(error))
  }
}

export function* watchGetCompanies() {
  yield takeEvery(GET_COMPANIES, getCompanies)
}

function* accountSaga() {
  yield all([fork(watchGetCompanies)])
}

export default accountSaga
