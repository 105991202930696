import {
  GET_PROJECT,
  GET_PROJECT_FAIL,
  GET_PROJECT_SUCCESS,
  GET_PROJECT_IN_PROGRESS,
  GET_PROJECT_IN_PROGRESS_SUCCESS,
  GET_PROJECT_IN_PROGRESS_FAIL,
  GET_PROJECT_DONE,
  GET_PROJECT_DONE_SUCCESS,
  GET_PROJECT_DONE_FAIL
} from "./actionTypes"

export const getProject = () => ({
  type: GET_PROJECT,
})

export const getProjectSuccess = projects => ({
  type: GET_PROJECT_SUCCESS,
  payload: projects,
})

export const getProjectFail = error => ({
  type: GET_PROJECT_FAIL,
  payload: error,
})


export const getProjectInProgress = () => ({
  type: GET_PROJECT_IN_PROGRESS,
})

export const getProjectInProgressSuccess = projects => ({
  type: GET_PROJECT_IN_PROGRESS_SUCCESS,
  payload: projects,
})

export const getProjectInProgressFail = error => ({
  type: GET_PROJECT_IN_PROGRESS_FAIL,
  payload: error,
})


export const getProjectDone = () => ({
  type: GET_PROJECT_DONE,
})

export const getProjectDoneSuccess = projects => ({
  type: GET_PROJECT_DONE_SUCCESS,
  payload: projects,
})

export const getProjectDoneFail = error => ({
  type: GET_PROJECT_DONE_FAIL,
  payload: error,
})