import {
  GET_QUOTING,
  GET_QUOTING_FAIL,
  GET_QUOTING_SUCCESS,
} from "./actionTypes"

export const getQuoting = () => ({
  type: GET_QUOTING,
})

export const getQuotingSuccess = quotings => ({
  type: GET_QUOTING_SUCCESS,
  payload: quotings,
})

export const getQuotingFail = error => ({
  type: GET_QUOTING_FAIL,
  payload: error,
})