import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap"

import { withTranslation } from "react-i18next"

import { useDispatch } from "react-redux"
import DataTable from "components/DataTable"

const DataTableModal = props => {
  const { isOpen, toggle, children } = props

  const dispatch = useDispatch()

  const selectedModal = children => {
    return (
      <Modal
        isOpen={isOpen}
        size="xl"
        role="dialog"
        autoFocus={true}
        centered={true}
        className="modal-dialog"
        tabIndex="-1"
        toggle={toggle}
      >
        <div className="modal-content">
          <ModalHeader toggle={toggle}>{children}</ModalHeader>
          <ModalBody>
            <div className="mb-2">
              {props.data ? (
                <DataTable
                  sizePerPage={10}
                  order="desc"
                  defaultSorted="id"
                  columns={props.columns}
                  data={props.data}
                  keyField={"id"}
                />
              ) : (
                <Spinner className="ms-2" color="primary" />
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="secondary" onClick={toggle}>
              {props.t("Close")}
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    )
  }

  return selectedModal(children)
}

DataTableModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  t: PropTypes.any,
  data: PropTypes.any,
  children: PropTypes.any,
  companies: PropTypes.any,
}

export default withTranslation()(DataTableModal)
