import {
  CREATE_COMPANY_USERS,
  CREATE_COMPANY_USERS_SUCCESS,
  CREATE_COMPANY_USERS_FAILED,
} from "./actionTypes"

export const createCompanyUsers = createCompany => {
  return {
    type: CREATE_COMPANY_USERS,
    payload: { createCompany },
  }
}

export const createCompanySuccess = createCompany => {
  return {
    type: CREATE_COMPANY_USERS_SUCCESS,
    payload: createCompany,
  }
}

export const createCompanyFailed = createCompany => {
  return {
    type: CREATE_COMPANY_USERS_FAILED,
    payload: createCompany,
  }
}
