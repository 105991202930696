import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import resetPasswordSaga from "./auth/resetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import chatSaga from "./chat/saga"
import invoiceSaga from "./invoices/saga"
import projectsSaga from "./projects/saga"
import tasksSaga from "./tasks/saga"
import mailsSaga from "./mails/saga"
import contactsSaga from "./contacts/saga"
import dashboardSaga from "./dashboard/saga"
import dashboardSaasSaga from "./dashboard-saas/saga"
import newUsers from "./admin/newUsers/saga"
import updateCompanySaga from "./admin/updateCompany/saga"
import deleteUser from "./admin/deleteUser/saga"
import getCompanies from "./admin/getCompany/saga"
import createCompanyUsers from "./admin/company/createCompany/saga"
import getProject from "./activity/project/saga"
import getTicket from "./activity/ticket/saga"
import getCommittee from "./documents/committee/saga"
import getContract from "./documents/contract/saga"
import getInvoice from "./documents/invoice/saga"
import getOther from "./documents/other/saga"
import getQuoting from "./documents/quoting/saga"
import getTraining from "./documents/training/saga"
import instances from "./youzer/users/saga"
import removeYouzer from "./youzer/remove/saga"
import addYouzer from "./youzer/add/saga"
import updateYouzer from "./youzer/update/saga"
import replaceYouzer from "./youzer/replace/saga"
import getDevice from "./activity/rmm/device/saga"
import getSite from "./activity/rmm/site/saga"
import contactSaga from "./activity/contact/saga"
import companySaga from "./activity/company/saga"
import primarySaga from "./activity/primary/saga"
import allUsers from "./admin/allUsers/saga"
import acronisInfoSaga from "./acronis/info/saga"
import acronisTenantSaga from "./acronis/tenant/saga"


export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(chatSaga),
    fork(mailsSaga),
    fork(invoiceSaga),
    fork(projectsSaga),
    fork(tasksSaga),
    fork(contactsSaga),
    fork(dashboardSaga),
    fork(dashboardSaasSaga),
    fork(newUsers),
    fork(updateCompanySaga),
    fork(deleteUser),
    fork(getCompanies),
    fork(getProject),
    fork(getTicket),
    fork(getCommittee),
    fork(getContract),
    fork(getInvoice),
    fork(getOther),
    fork(getQuoting),
    fork(getTraining),
    fork(instances),
    fork(removeYouzer),
    fork(addYouzer),
    fork(updateYouzer),
    fork(getDevice),
    fork(getSite),
    fork(createCompanyUsers),
    fork(contactSaga),
    fork(resetPasswordSaga),
    fork(allUsers),
    fork(companySaga),
    fork(primarySaga),
    fork(replaceYouzer),
    fork(acronisInfoSaga),
    fork(acronisTenantSaga)
  ])
}
