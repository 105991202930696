import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { GET_INSTANCES } from "./actionTypes"
import { getInstancesSuccess, getInstancesFailed } from "./actions"

import { getContact } from "helpers/activity_helper"

import jwt from "jsonwebtoken"

function* getInstances() {
  try {
    const token = localStorage.getItem("auth")
    const decoded = jwt.decode(token)

    const response = yield call(
      getContact,
      `${process.env.REACT_APP_OPERATION}/contact/company/${decoded.companyId}`,
    )

    yield put(getInstancesSuccess(response))
  } catch (error) {
    yield put(getInstancesFailed(error))
  }
}

export function* watchGetInstances() {
  yield takeEvery(GET_INSTANCES, getInstances)
}

function* accountSaga() {
  yield all([fork(watchGetInstances)])
}

export default accountSaga
