import { GET_PRIMARY, GET_PRIMARY_FAIL,GET_PRIMARY_SUCCESS } from "./actionTypes"

export const getPrimary = () => ({
  type: GET_PRIMARY,
})

export const getPrimarySuccess = primary => ({
  type: GET_PRIMARY_SUCCESS,
  payload: primary,
})

export const getPrimaryFail = error => ({
  type: GET_PRIMARY_FAIL,
  payload: error,
})
