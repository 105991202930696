import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect } from "react"
import { withRouter } from "react-router-dom"

import {
  Container,
  Spinner,
  CardBody,
  Card,
  CardTitle,
  Row,
  Col,
} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import FormAddYouzer from "../../components/Form/Youzer/Add"

//redux
import { getInstances } from "store/actions"
import { useSelector, useDispatch } from "react-redux"

//i18n
import { withTranslation } from "react-i18next"

const Youzer = props => {
  const dispatch = useDispatch()

  const { users } = useSelector(state => ({
    users: state.Instances?.instances?.data,
  }))

  useEffect(() => {
    dispatch(getInstances())
  }, [dispatch])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("Add User")} | izencia - Espace Client</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title={props.t("User")}
            breadcrumbItem={props.t("Add User")}
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">{props.t("Add User")}</CardTitle>
                  <p className="card-title-desc">
                    {props.t("Add User Description")}
                  </p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        '<iframe src=\'https://app.youzer.net/public/utilisateur?token=5A813CC4-133F-4411-AF67-3B94A0A93F2E\' scrolling="no" width="100%" height="1000"/>',
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(withRouter(Youzer))

Youzer.propTypes = {
  history: PropTypes.object,
  users: PropTypes.object,
  t: PropTypes.any,
  history: PropTypes.any,
}
