import { GET_SITE, GET_SITE_FAIL, GET_SITE_SUCCESS } from "./actionTypes"

export const getSite = () => ({
  type: GET_SITE,
})

export const getSiteSuccess = sites => ({
  type: GET_SITE_SUCCESS,
  payload: sites,
})

export const getSiteFail = error => ({
  type: GET_SITE_FAIL,
  payload: error,
})
