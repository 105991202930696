import React from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"
import { useSelector } from "react-redux"

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  isAdminProtected,
  token,
  decoded,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if (
        (isAuthProtected && !token) ||
        (isAuthProtected && !decoded?.isMfa === true) ||
        (isAuthProtected && !decoded.companyId)
      ) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      } else if (isAdminProtected && decoded?.role != "admin") {
        return (
          <Redirect
            to={{ pathname: "/dashboard", state: { from: props.location } }}
          />
        )
      } else {
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        )
      }
    }}
  />
)

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  isAdminProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
  token: PropTypes.string,
  decoded: PropTypes.any,
}

export default Authmiddleware
